import { Flex, Image, Text } from "@chakra-ui/react";
import { NavLink } from "react-router-dom";

import moment from "moment";
import Tag from "./Tag";

const NewsItem = (props) => {
  return (
    <NavLink to="/newsfeed">
      <Flex flexDir="row" alignItems="center" width="100%" mt="10px" mb="10px">
        <Image
          src={props.thb}
          width="150px"
          height="81px"
          mr="20px"
          objectFit="cover"
          borderRadius="5px"
        />

        <Flex flexDir="column">
          <Tag text={props.tagText} />
          <Text
            fontSize="18px"
            lineHeight="1.1"
            color="#EBB435"
            fontWeight="700"
            mt="5px"
          >
            {props.title}
          </Text>
          <Text
            fontSize="16px"
            lineHeight="1.1"
            color="#718096"
            fontWeight="700"
            mt="5px"
            mb="5px"
          >
            {props.agency} | {moment(props.date).format("MMMM Do 'YY")}
          </Text>
        </Flex>
      </Flex>
    </NavLink>
  );
};

export default NewsItem;
