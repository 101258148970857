import { Text } from "@chakra-ui/react";

const Tag = (props) => {
  return (
    <Text
      bg="#FFF2D2"
      color="#E7BD5B"
      height="25px"
      borderRadius="5px"
      pt="2px"
      pb="2px"
      pr="7px"
      pl="7px"
      mr="10px"
      mt="5px"
      fontSize="14px"
      width="fit-content"
      fontWeight="700"
      textTransform="uppercase"
    >
      {props.text}
    </Text>
  );
};

export default Tag;
