import {
  Box,
  Flex,
  Input,
  Text,
  InputGroup,
  InputLeftElement,
  Checkbox,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Spinner,
  CheckboxGroup,
} from "@chakra-ui/react";
import { useState, useContext, useEffect } from "react";
import swal from "sweetalert";

import { Search2Icon } from "@chakra-ui/icons";

import CompanyCard from "../components/CompanyCard";
import Sidebar from "../components/Sidebar";

import AuthContext from "../store/auth";
import { convertFiltersToRequest } from "../store/functions";

const Portfolio = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [portfolioData, setPortfolioData] = useState(null);
  const [offset, setOffset] = useState(0);
  const [count, setCount] = useState(null);
  const [regionFilter, setRegionFilter] = useState();
  const [industryFilter, setIndustryFilter] = useState();

  useEffect(() => {
    updateFilters();
    // eslint-disable-next-line
  }, [regionFilter, industryFilter]);

  const [search, setSearch] = useState("");
  const searchFor = (event) => setSearch(event.target.value);

  const nextPage = () => {
    if (offset + 30 <= count) setOffset(offset + 30);
  };

  const prevPage = () => {
    if (offset !== 0) setOffset(offset - 30);
  };

  const authCtx = useContext(AuthContext);

  const getPortfolioList = () => {
    fetch(
      "https://beenext.orangeyak.xyz/api/resources/portfolio?limit=30&offset=" +
        offset,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Token " + authCtx.token,
        },
      }
    ).then((response) =>
      response
        .json()
        .then((data) => ({
          data: data,
          status: response.status,
        }))
        .then((res) => {
          if (res.status < 300) {
            setPortfolioData(res.data.results);
            setCount(res.data.count);
          } else {
            swal("Oops, something went wrong!", res.data.detail, "error");
          }
        })
    );
  };

  const updateFilters = () => {
    fetch(
      "https://beenext.orangeyak.xyz/api/resources/portfolio/?" +
        convertFiltersToRequest("industry", industryFilter) +
        "&" +
        convertFiltersToRequest("region", regionFilter),
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Token " + authCtx.token,
        },
      }
    ).then((response) =>
      response
        .json()
        .then((data) => ({
          data: data,
          status: response.status,
        }))
        .then((res) => {
          if (res.status < 300) {
            setPortfolioData(res.data.results);
          } else {
            swal("Oops, something went wrong!", res.data.detail, "error");
          }
        })
    );
  };

  const searchFilters = () => {
    fetch(
      "https://beenext.orangeyak.xyz/api/resources/portfolio/?search=" + search,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Token " + authCtx.token,
        },
      }
    ).then((response) =>
      response
        .json()
        .then((data) => ({
          data: data,
          status: response.status,
        }))
        .then((res) => {
          if (res.status < 300) {
            setPortfolioData(res.data.results);
          } else {
            swal("Oops, something went wrong!", res.data.detail, "error");
          }
        })
    );
  };

  useEffect(() => {
    searchFilters();
    // eslint-disable-next-line
  }, [search]);

  useEffect(() => {
    getPortfolioList();
    // eslint-disable-next-line
  }, [offset]);

  return (
    <Box
      bg="#EFEFEF"
      height="100vh"
      width="100vw"
      display="flex"
      flexDir="row"
      overflow="hidden"
    >
      {/* SIDEBAR SECTION */}
      <Sidebar />
      {/* END OF SIDEBAR */}

      {/* MASTER FEED WRAPPER SECTION */}
      <Flex
        width={["99%", "99%", "100%", "100%", "100%"]}
        flexDir="column"
        alignItems="center"
        height="95%"
        ml={["10px", "10px", "320px", "320px", "320px"]}
        mr="10px"
        mt={["60px", "60px", "20px", "20px", "20px"]}
        overflowX="hidden"
        overflowY="scroll"
      >
        {/* TOPBAR SECTION */}
        <Flex
          bg="white"
          width="99%"
          borderRadius="20px"
          ml="-10px"
          pt="20px"
          pb="20px"
          flexDir="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Flex maxWidth="600px">
            <Flex flexDir="column" ml="30px">
              <Text fontSize="22px" fontWeight="700" color="#505058">
                BEENEXT PORTFOLIO
              </Text>
              <Text
                fontSize="16px"
                lineHeight="120%"
                fontWeight="500"
                color="#A0AEC0"
              >
                All of our amazing Portfolio companies listed by Geography,
                Sectors in a simple, searchable format.
              </Text>
            </Flex>
          </Flex>

          {/* <GlobalSearchBar /> */}
        </Flex>
        {/* END OF TOPBAR SECTION */}

        <Flex bg="white" width="99%" borderRadius="200px" mt="10px">
          <InputGroup width="100%">
            <InputLeftElement
              pt="5px"
              children={<Search2Icon color="gray.300" />}
            />
            <Input
              pr="10px"
              borderRadius="20px"
              placeholder="Search for portfolio companies"
              color="#A0AEC0"
              focusBorderColor="#EBB435"
              fontWeight="700"
              border="none"
              autocomplete="off"
              onChange={searchFor}
            />
          </InputGroup>
        </Flex>

        <Flex
          flexDir={["column", "column", "row", "row", "row"]}
          width="99%"
          mt="20px"
          justifyContent="space-between"
        >
          <Flex
            width="250px"
            display={["none", "none", "flex", "flex", "flex"]}
            flexDir="column"
          >
            {/* <Button bg="deepYellow" color="white" mb='10px' onClick={updateFilters}>FILTER</Button> */}
            <Text
              color="lightGray"
              fontWeight="700"
              textTransform="uppercase"
              fontSize="16px"
            >
              REGION
            </Text>

            <CheckboxGroup onChange={setRegionFilter} value={regionFilter}>
              <Checkbox
                colorScheme="yellow"
                defaultIsChecked
                value="Singapore"
                fontWeight="700"
                br="10px"
                ml="2px"
                mb="2px"
                color="darkGray"
              >
                Singapore
              </Checkbox>

              <Checkbox
                colorScheme="yellow"
                defaultIsChecked
                value="Pakistan"
                fontWeight="700"
                br="10px"
                ml="2px"
                mb="2px"
                color="darkGray"
              >
                Pakistan
              </Checkbox>

              <Checkbox
                colorScheme="yellow"
                defaultIsChecked
                value="Uganda"
                fontWeight="700"
                br="10px"
                ml="2px"
                mb="2px"
                color="darkGray"
              >
                Uganda
              </Checkbox>

              <Checkbox
                colorScheme="yellow"
                defaultIsChecked
                value="India"
                fontWeight="700"
                br="10px"
                ml="2px"
                mb="2px"
                color="darkGray"
              >
                India
              </Checkbox>

              <Checkbox
                colorScheme="yellow"
                defaultIsChecked
                value="SEA"
                fontWeight="700"
                br="10px"
                ml="2px"
                mb="2px"
                color="darkGray"
              >
                SEA
              </Checkbox>

              <Checkbox
                colorScheme="yellow"
                defaultIsChecked
                value="Phillipines"
                fontWeight="700"
                br="10px"
                ml="2px"
                mb="2px"
                color="darkGray"
              >
                Phillipines
              </Checkbox>

              <Checkbox
                colorScheme="yellow"
                defaultIsChecked
                value="Myanmar"
                fontWeight="700"
                br="10px"
                ml="2px"
                mb="2px"
                color="darkGray"
              >
                Myanmar
              </Checkbox>

              <Checkbox
                colorScheme="yellow"
                defaultIsChecked
                value="Indonesia"
                fontWeight="700"
                br="10px"
                ml="2px"
                mb="2px"
                color="darkGray"
              >
                Indonesia
              </Checkbox>

              <Checkbox
                colorScheme="yellow"
                defaultIsChecked
                value="USA"
                fontWeight="700"
                br="10px"
                ml="2px"
                mb="2px"
                color="darkGray"
              >
                USA
              </Checkbox>

              <Checkbox
                colorScheme="yellow"
                defaultIsChecked
                value="Vietnam"
                fontWeight="700"
                br="10px"
                ml="2px"
                mb="2px"
                color="darkGray"
              >
                Vietnam
              </Checkbox>

              <Checkbox
                colorScheme="yellow"
                defaultIsChecked
                value="Nigeria"
                fontWeight="700"
                br="10px"
                ml="2px"
                mb="2px"
                color="darkGray"
              >
                Nigeria
              </Checkbox>

              <Checkbox
                colorScheme="yellow"
                defaultIsChecked
                value="Canada"
                fontWeight="700"
                br="10px"
                ml="2px"
                mb="2px"
                color="darkGray"
              >
                Canada
              </Checkbox>

              <Checkbox
                colorScheme="yellow"
                defaultIsChecked
                value="Africa"
                fontWeight="700"
                br="10px"
                ml="2px"
                mb="2px"
                color="darkGray"
              >
                Africa
              </Checkbox>

              <Checkbox
                colorScheme="yellow"
                defaultIsChecked
                value="Bangladesh"
                fontWeight="700"
                br="10px"
                ml="2px"
                mb="2px"
                color="darkGray"
              >
                Bangladesh
              </Checkbox>
            </CheckboxGroup>

            <Text
              color="lightGray"
              fontWeight="700"
              textTransform="uppercase"
              fontSize="16px"
              mt="20px"
            >
              INDUSTRY
            </Text>

            <CheckboxGroup onChange={setIndustryFilter} value={industryFilter}>
              <Checkbox
                colorScheme="yellow"
                defaultIsChecked
                value="Agritech"
                fontWeight="700"
                br="10px"
                ml="2px"
                mb="2px"
                color="darkGray"
              >
                Agritech
              </Checkbox>

              <Checkbox
                colorScheme="yellow"
                defaultIsChecked
                value="Mobility"
                fontWeight="700"
                br="10px"
                ml="2px"
                mb="2px"
                color="darkGray"
              >
                Mobility
              </Checkbox>

              <Checkbox
                colorScheme="yellow"
                defaultIsChecked
                value="Fintech"
                fontWeight="700"
                br="10px"
                ml="2px"
                mb="2px"
                color="darkGray"
              >
                Fintech
              </Checkbox>

              <Checkbox
                colorScheme="yellow"
                defaultIsChecked
                value="Analytics"
                fontWeight="700"
                br="10px"
                ml="2px"
                mb="2px"
                color="darkGray"
              >
                Analytics
              </Checkbox>

              <Checkbox
                colorScheme="yellow"
                defaultIsChecked
                value="Angel Funding"
                fontWeight="700"
                br="10px"
                ml="2px"
                mb="2px"
                color="darkGray"
              >
                Angel Funding
              </Checkbox>

              <Checkbox
                colorScheme="yellow"
                defaultIsChecked
                value="Food Tech"
                fontWeight="700"
                br="10px"
                ml="2px"
                mb="2px"
                color="darkGray"
              >
                Food Tech
              </Checkbox>

              <Checkbox
                colorScheme="yellow"
                defaultIsChecked
                value="Logistics"
                fontWeight="700"
                br="10px"
                ml="2px"
                mb="2px"
                color="darkGray"
              >
                Logistics
              </Checkbox>

              <Checkbox
                colorScheme="yellow"
                defaultIsChecked
                value="Real Estate"
                fontWeight="700"
                br="10px"
                ml="2px"
                mb="2px"
                color="darkGray"
              >
                Real Estate
              </Checkbox>

              <Checkbox
                colorScheme="yellow"
                defaultIsChecked
                value="Gaming"
                fontWeight="700"
                br="10px"
                ml="2px"
                mb="2px"
                color="darkGray"
              >
                Gaming
              </Checkbox>

              <Checkbox
                colorScheme="yellow"
                defaultIsChecked
                value="PoS"
                fontWeight="700"
                br="10px"
                ml="2px"
                mb="2px"
                color="darkGray"
              >
                PoS
              </Checkbox>

              <Checkbox
                colorScheme="yellow"
                defaultIsChecked
                value="Geotagging"
                fontWeight="700"
                br="10px"
                ml="2px"
                mb="2px"
                color="darkGray"
              >
                Geotagging
              </Checkbox>

              <Checkbox
                colorScheme="yellow"
                defaultIsChecked
                value="Cloud Kitchen"
                fontWeight="700"
                br="10px"
                ml="2px"
                mb="2px"
                color="darkGray"
              >
                Cloud Kitchen
              </Checkbox>

              <Checkbox
                colorScheme="yellow"
                defaultIsChecked
                value="HR Tech"
                fontWeight="700"
                br="10px"
                ml="2px"
                mb="2px"
                color="darkGray"
              >
                HR Tech
              </Checkbox>

              <Checkbox
                colorScheme="yellow"
                defaultIsChecked
                value="Lifestyle"
                fontWeight="700"
                br="10px"
                ml="2px"
                mb="2px"
                color="darkGray"
              >
                Lifestyle
              </Checkbox>

              <Checkbox
                colorScheme="yellow"
                defaultIsChecked
                value="AI"
                fontWeight="700"
                br="10px"
                ml="2px"
                mb="2px"
                color="darkGray"
              >
                AI
              </Checkbox>

              <Checkbox
                colorScheme="yellow"
                defaultIsChecked
                value="IoT"
                fontWeight="700"
                br="10px"
                ml="2px"
                mb="2px"
                color="darkGray"
              >
                IoT
              </Checkbox>

              <Checkbox
                colorScheme="yellow"
                defaultIsChecked
                value="Satellite"
                fontWeight="700"
                br="10px"
                ml="2px"
                mb="2px"
                color="darkGray"
              >
                Satellite
              </Checkbox>

              <Checkbox
                colorScheme="yellow"
                defaultIsChecked
                value="Health Tech"
                fontWeight="700"
                br="10px"
                ml="2px"
                mb="2px"
                color="darkGray"
              >
                Health Tech
              </Checkbox>

              <Checkbox
                colorScheme="yellow"
                defaultIsChecked
                value="Marketplace"
                fontWeight="700"
                br="10px"
                ml="2px"
                mb="2px"
                color="darkGray"
              >
                Marketplace
              </Checkbox>

              <Checkbox
                colorScheme="yellow"
                defaultIsChecked
                value="Proptech"
                fontWeight="700"
                br="10px"
                ml="2px"
                mb="2px"
                color="darkGray"
              >
                Proptech
              </Checkbox>

              <Checkbox
                colorScheme="yellow"
                defaultIsChecked
                value="Edtech"
                fontWeight="700"
                br="10px"
                ml="2px"
                mb="2px"
                color="darkGray"
              >
                Edtech
              </Checkbox>

              <Checkbox
                colorScheme="yellow"
                defaultIsChecked
                value="Insuretech"
                fontWeight="700"
                br="10px"
                ml="2px"
                mb="2px"
                color="darkGray"
              >
                Insuretech
              </Checkbox>

              <Checkbox
                colorScheme="yellow"
                defaultIsChecked
                value="Media"
                fontWeight="700"
                br="10px"
                ml="2px"
                mb="2px"
                color="darkGray"
              >
                Media
              </Checkbox>

              <Checkbox
                colorScheme="yellow"
                defaultIsChecked
                value="Platform"
                fontWeight="700"
                br="10px"
                ml="2px"
                mb="2px"
                color="darkGray"
              >
                Platform
              </Checkbox>

              <Checkbox
                colorScheme="yellow"
                defaultIsChecked
                value="Sustainability"
                fontWeight="700"
                br="10px"
                ml="2px"
                mb="2px"
                color="darkGray"
              >
                Sustainability
              </Checkbox>

              <Checkbox
                colorScheme="yellow"
                defaultIsChecked
                value="Enterprise SaaS"
                fontWeight="700"
                br="10px"
                ml="2px"
                mb="2px"
                color="darkGray"
              >
                Enterprise SaaS
              </Checkbox>
            </CheckboxGroup>
          </Flex>

          <Button
            onClick={onOpen}
            display={["flex", "flex", "none", "none", "none"]}
            width="100px"
            color="#EBB435"
            bg="#FFF2D2"
          >
            FILTERS
          </Button>

          <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>FILTERS</ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                <Text
                  color="lightGray"
                  fontWeight="700"
                  textTransform="uppercase"
                  fontSize="16px"
                >
                  REGION
                </Text>

                <CheckboxGroup onChange={setRegionFilter} value={regionFilter}>
                  <Checkbox
                    colorScheme="yellow"
                    defaultIsChecked
                    value="Singapore"
                    fontWeight="700"
                    br="10px"
                    ml="2px"
                    mb="2px"
                    color="darkGray"
                  >
                    Singapore
                  </Checkbox>

                  <Checkbox
                    colorScheme="yellow"
                    defaultIsChecked
                    value="Pakistan"
                    fontWeight="700"
                    br="10px"
                    ml="2px"
                    mb="2px"
                    color="darkGray"
                  >
                    Pakistan
                  </Checkbox>

                  <Checkbox
                    colorScheme="yellow"
                    defaultIsChecked
                    value="Uganda"
                    fontWeight="700"
                    br="10px"
                    ml="2px"
                    mb="2px"
                    color="darkGray"
                  >
                    Uganda
                  </Checkbox>

                  <Checkbox
                    colorScheme="yellow"
                    defaultIsChecked
                    value="India"
                    fontWeight="700"
                    br="10px"
                    ml="2px"
                    mb="2px"
                    color="darkGray"
                  >
                    India
                  </Checkbox>

                  <Checkbox
                    colorScheme="yellow"
                    defaultIsChecked
                    value="SEA"
                    fontWeight="700"
                    br="10px"
                    ml="2px"
                    mb="2px"
                    color="darkGray"
                  >
                    SEA
                  </Checkbox>

                  <Checkbox
                    colorScheme="yellow"
                    defaultIsChecked
                    value="Phillipines"
                    fontWeight="700"
                    br="10px"
                    ml="2px"
                    mb="2px"
                    color="darkGray"
                  >
                    Phillipines
                  </Checkbox>

                  <Checkbox
                    colorScheme="yellow"
                    defaultIsChecked
                    value="Myanmar"
                    fontWeight="700"
                    br="10px"
                    ml="2px"
                    mb="2px"
                    color="darkGray"
                  >
                    Myanmar
                  </Checkbox>

                  <Checkbox
                    colorScheme="yellow"
                    defaultIsChecked
                    value="Indonesia"
                    fontWeight="700"
                    br="10px"
                    ml="2px"
                    mb="2px"
                    color="darkGray"
                  >
                    Indonesia
                  </Checkbox>

                  <Checkbox
                    colorScheme="yellow"
                    defaultIsChecked
                    value="USA"
                    fontWeight="700"
                    br="10px"
                    ml="2px"
                    mb="2px"
                    color="darkGray"
                  >
                    USA
                  </Checkbox>

                  <Checkbox
                    colorScheme="yellow"
                    defaultIsChecked
                    value="Vietnam"
                    fontWeight="700"
                    br="10px"
                    ml="2px"
                    mb="2px"
                    color="darkGray"
                  >
                    Vietnam
                  </Checkbox>

                  <Checkbox
                    colorScheme="yellow"
                    defaultIsChecked
                    value="Nigeria"
                    fontWeight="700"
                    br="10px"
                    ml="2px"
                    mb="2px"
                    color="darkGray"
                  >
                    Nigeria
                  </Checkbox>

                  <Checkbox
                    colorScheme="yellow"
                    defaultIsChecked
                    value="Canada"
                    fontWeight="700"
                    br="10px"
                    ml="2px"
                    mb="2px"
                    color="darkGray"
                  >
                    Canada
                  </Checkbox>

                  <Checkbox
                    colorScheme="yellow"
                    defaultIsChecked
                    value="Africa"
                    fontWeight="700"
                    br="10px"
                    ml="2px"
                    mb="2px"
                    color="darkGray"
                  >
                    Africa
                  </Checkbox>

                  <Checkbox
                    colorScheme="yellow"
                    defaultIsChecked
                    value="Bangladesh"
                    fontWeight="700"
                    br="10px"
                    ml="2px"
                    mb="2px"
                    color="darkGray"
                  >
                    Bangladesh
                  </Checkbox>
                </CheckboxGroup>

                <Text
                  color="lightGray"
                  fontWeight="700"
                  textTransform="uppercase"
                  fontSize="16px"
                  mt="20px"
                >
                  INDUSTRY
                </Text>

                <CheckboxGroup
                  onChange={setIndustryFilter}
                  value={industryFilter}
                >
                  <Checkbox
                    colorScheme="yellow"
                    defaultIsChecked
                    value="Agritech"
                    fontWeight="700"
                    br="10px"
                    ml="2px"
                    mb="2px"
                    color="darkGray"
                  >
                    Agritech
                  </Checkbox>

                  <Checkbox
                    colorScheme="yellow"
                    defaultIsChecked
                    value="Mobility"
                    fontWeight="700"
                    br="10px"
                    ml="2px"
                    mb="2px"
                    color="darkGray"
                  >
                    Mobility
                  </Checkbox>

                  <Checkbox
                    colorScheme="yellow"
                    defaultIsChecked
                    value="Fintech"
                    fontWeight="700"
                    br="10px"
                    ml="2px"
                    mb="2px"
                    color="darkGray"
                  >
                    Fintech
                  </Checkbox>

                  <Checkbox
                    colorScheme="yellow"
                    defaultIsChecked
                    value="Analytics"
                    fontWeight="700"
                    br="10px"
                    ml="2px"
                    mb="2px"
                    color="darkGray"
                  >
                    Analytics
                  </Checkbox>

                  <Checkbox
                    colorScheme="yellow"
                    defaultIsChecked
                    value="Angel Funding"
                    fontWeight="700"
                    br="10px"
                    ml="2px"
                    mb="2px"
                    color="darkGray"
                  >
                    Angel Funding
                  </Checkbox>

                  <Checkbox
                    colorScheme="yellow"
                    defaultIsChecked
                    value="Food Tech"
                    fontWeight="700"
                    br="10px"
                    ml="2px"
                    mb="2px"
                    color="darkGray"
                  >
                    Food Tech
                  </Checkbox>

                  <Checkbox
                    colorScheme="yellow"
                    defaultIsChecked
                    value="Logistics"
                    fontWeight="700"
                    br="10px"
                    ml="2px"
                    mb="2px"
                    color="darkGray"
                  >
                    Logistics
                  </Checkbox>

                  <Checkbox
                    colorScheme="yellow"
                    defaultIsChecked
                    value="Real Estate"
                    fontWeight="700"
                    br="10px"
                    ml="2px"
                    mb="2px"
                    color="darkGray"
                  >
                    Real Estate
                  </Checkbox>

                  <Checkbox
                    colorScheme="yellow"
                    defaultIsChecked
                    value="Gaming"
                    fontWeight="700"
                    br="10px"
                    ml="2px"
                    mb="2px"
                    color="darkGray"
                  >
                    Gaming
                  </Checkbox>

                  <Checkbox
                    colorScheme="yellow"
                    defaultIsChecked
                    value="PoS"
                    fontWeight="700"
                    br="10px"
                    ml="2px"
                    mb="2px"
                    color="darkGray"
                  >
                    PoS
                  </Checkbox>

                  <Checkbox
                    colorScheme="yellow"
                    defaultIsChecked
                    value="Geotagging"
                    fontWeight="700"
                    br="10px"
                    ml="2px"
                    mb="2px"
                    color="darkGray"
                  >
                    Geotagging
                  </Checkbox>

                  <Checkbox
                    colorScheme="yellow"
                    defaultIsChecked
                    value="Cloud Kitchen"
                    fontWeight="700"
                    br="10px"
                    ml="2px"
                    mb="2px"
                    color="darkGray"
                  >
                    Cloud Kitchen
                  </Checkbox>

                  <Checkbox
                    colorScheme="yellow"
                    defaultIsChecked
                    value="HR Tech"
                    fontWeight="700"
                    br="10px"
                    ml="2px"
                    mb="2px"
                    color="darkGray"
                  >
                    HR Tech
                  </Checkbox>

                  <Checkbox
                    colorScheme="yellow"
                    defaultIsChecked
                    value="Lifestyle"
                    fontWeight="700"
                    br="10px"
                    ml="2px"
                    mb="2px"
                    color="darkGray"
                  >
                    Lifestyle
                  </Checkbox>

                  <Checkbox
                    colorScheme="yellow"
                    defaultIsChecked
                    value="AI"
                    fontWeight="700"
                    br="10px"
                    ml="2px"
                    mb="2px"
                    color="darkGray"
                  >
                    AI
                  </Checkbox>

                  <Checkbox
                    colorScheme="yellow"
                    defaultIsChecked
                    value="IoT"
                    fontWeight="700"
                    br="10px"
                    ml="2px"
                    mb="2px"
                    color="darkGray"
                  >
                    IoT
                  </Checkbox>

                  <Checkbox
                    colorScheme="yellow"
                    defaultIsChecked
                    value="Satellite"
                    fontWeight="700"
                    br="10px"
                    ml="2px"
                    mb="2px"
                    color="darkGray"
                  >
                    Satellite
                  </Checkbox>

                  <Checkbox
                    colorScheme="yellow"
                    defaultIsChecked
                    value="Health Tech"
                    fontWeight="700"
                    br="10px"
                    ml="2px"
                    mb="2px"
                    color="darkGray"
                  >
                    Health Tech
                  </Checkbox>

                  <Checkbox
                    colorScheme="yellow"
                    defaultIsChecked
                    value="Marketplace"
                    fontWeight="700"
                    br="10px"
                    ml="2px"
                    mb="2px"
                    color="darkGray"
                  >
                    Marketplace
                  </Checkbox>

                  <Checkbox
                    colorScheme="yellow"
                    defaultIsChecked
                    value="Proptech"
                    fontWeight="700"
                    br="10px"
                    ml="2px"
                    mb="2px"
                    color="darkGray"
                  >
                    Proptech
                  </Checkbox>

                  <Checkbox
                    colorScheme="yellow"
                    defaultIsChecked
                    value="Edtech"
                    fontWeight="700"
                    br="10px"
                    ml="2px"
                    mb="2px"
                    color="darkGray"
                  >
                    Edtech
                  </Checkbox>

                  <Checkbox
                    colorScheme="yellow"
                    defaultIsChecked
                    value="Insuretech"
                    fontWeight="700"
                    br="10px"
                    ml="2px"
                    mb="2px"
                    color="darkGray"
                  >
                    Insuretech
                  </Checkbox>

                  <Checkbox
                    colorScheme="yellow"
                    defaultIsChecked
                    value="Media"
                    fontWeight="700"
                    br="10px"
                    ml="2px"
                    mb="2px"
                    color="darkGray"
                  >
                    Media
                  </Checkbox>

                  <Checkbox
                    colorScheme="yellow"
                    defaultIsChecked
                    value="Platform"
                    fontWeight="700"
                    br="10px"
                    ml="2px"
                    mb="2px"
                    color="darkGray"
                  >
                    Platform
                  </Checkbox>

                  <Checkbox
                    colorScheme="yellow"
                    defaultIsChecked
                    value="Sustainability"
                    fontWeight="700"
                    br="10px"
                    ml="2px"
                    mb="2px"
                    color="darkGray"
                  >
                    Sustainability
                  </Checkbox>

                  <Checkbox
                    colorScheme="yellow"
                    defaultIsChecked
                    value="Enterprise SaaS"
                    fontWeight="700"
                    br="10px"
                    ml="2px"
                    mb="2px"
                    color="darkGray"
                  >
                    Enterprise SaaS
                  </Checkbox>
                </CheckboxGroup>
              </ModalBody>

              <ModalFooter>
                {/* <Button
                                color="#EBB435"
                                bg="#FFF2D2"
                                mr={3} 
                                onClick={closeModal}
                            >
                            FILTER
                            </Button> */}
              </ModalFooter>
            </ModalContent>
          </Modal>

          <Flex
            width="100%"
            ml={["0px", "0px", "20px", "20px", "20px"]}
            flexDir="column"
          >
            {portfolioData !== null ? (
              <>
                {portfolioData.map((company, index) => (
                  <CompanyCard
                    imgSrc={company.company.firm_logo}
                    title={company.company.name}
                    desc={company.company.description}
                    location={company.company.region.name}
                    website={company.company.website}
                    industry={company.company.industry.name}
                    subtitle={company.company.industry.name}
                    founders="-"
                    twitterLink="twitter.com"
                    linkedinLink="linkedin.com"
                    requestConnection="mailto:parth@theorangeyak.co"
                  />
                ))}
                <Flex margin="auto" flexDir="row">
                  <Button
                    size="md"
                    onClick={prevPage}
                    m="10px"
                    color="white"
                    bg="#EBB435"
                    isDisabled={offset === 0}
                  >
                    Last Page
                  </Button>
                  <Button
                    onClick={nextPage}
                    m="10px"
                    color="white"
                    bg="#EBB435"
                    isDisabled={offset + 30 > count}
                  >
                    Next Page
                  </Button>
                </Flex>
              </>
            ) : (
              <Spinner m="auto" />
            )}
            {/* <CompanyCard 
                            imgSrc={randomImage}
                            title="90 Seconds"
                            subtitle="Video Creation Platform"
                            desc="90 Seconds is the premiere global video creation platform, enabling brands to create quality videos anywhere in the world."
                            location="Singapore, Singapore"
                            website="90seconds.com"
                            industry="Content Creation, Social Platform"
                            founders="Tim Norton"
                            twitterLink="twitter.com"
                            linkedinLink="linkedin.com"
                            requestConnection="mailto:parth@theorangeya"
                        /> */}
          </Flex>
        </Flex>
      </Flex>

      {/* END OF SCROLLABLE INTERNAL SECTION */}
    </Box>
  );
};

export default Portfolio;
