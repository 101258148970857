import { Flex, Box, Image, Text } from "@chakra-ui/react";

import linkedinIcon from "../resources/imgs/linkedinIcon.png";
import twitterIcon from "../resources/imgs/twitterIcon.png";
import waIcon from "../resources/imgs/wa.png";

const TeamMember = (props) => {
  return (
    <Flex
      bg="white"
      width={["100%", "100%", "100%", "32%", "32%"]}
      p="20px"
      borderRadius="20px"
      flexDir="row"
      height="fit-content"
      ml="-10px"
      mb="20px"
      pt="20px"
      pb="20px"
      pl="0px"
      alignItems="center"
      m="5px"
    >
      <Flex flexDir="column" alignItems="center" width="40%">
        <Image src={props.memberImage} height="150px" />
      </Flex>
      <Flex flexDir="column" width="60%" justifyContent="space-between">
        <Box>
          <Text
            fontSize="18px"
            color="darkGray"
            fontWeight="700"
            lineHeight="1"
            mt="5px"
          >
            {props.name}
          </Text>
          <Text fontSize="16px" color="lightGray" lineHeight="1.2">
            {props.designation}
          </Text>
          <Flex flexDir="row" mt="20px">
            <a href={props.linkedin} target="_blank" rel="noreferrer">
              <Image
                boxSize="30px"
                objectFit="cover"
                mr="10px"
                src={linkedinIcon}
              />
            </a>

            <a href={props.twitter} target="_blank" rel="noreferrer">
              <Image
                boxSize="30px"
                mr="20px"
                objectFit="cover"
                src={twitterIcon}
              />
            </a>
            {props.whatsapp ? (
              <a href={props.whatsapp} target="_blank" rel="noreferrer">
                <Image
                  boxSize="30px"
                  mr="20px"
                  objectFit="cover"
                  src={waIcon}
                />
              </a>
            ) : null}
          </Flex>
          <Text
            fontSize="16px"
            fontWeight="700"
            color="lightGray"
            lineHeight="1.4"
          >
            {props.email}
          </Text>
        </Box>
      </Flex>
    </Flex>
  );
};

export default TeamMember;
