import {
  Flex,
  Heading,
  Image,
  FormControl,
  FormLabel,
  Input,
  Text,
  Button,
} from "@chakra-ui/react";
import { useRef } from "react";
import swal from "sweetalert";

import onboardingImage from "../../resources/imgs/onboarding.png";
import beenextLogo from "../../resources/imgs/beenextLogo.png";
import { useParams } from "react-router-dom";

const ForgotConfirm = () => {
  const p2ref = useRef();
  const p3ref = useRef();

  let { uid, token } = useParams();

  function forgorPassword() {
    const p2 = p2ref.current.value;
    const p3 = p3ref.current.value;

    fetch("https://beenext.orangeyak.xyz/rest-auth/password/reset/confirm/", {
      method: "POST",
      body: JSON.stringify({
        uid: uid,
        token: token,
        new_password1: p2,
        new_password2: p3,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    }).then((response) =>
      response
        .json()
        .then((data) => ({
          data: data,
          status: response.status,
        }))
        .then((res) => {
          if (res.status < 300) {
            swal(
              "Password changed successfully!",
              "You may now login with these details!",
              "success"
            ).then(() => {
              window.location = "/";
            });
          } else {
            swal("Password change failed.", res.data.detail, "error");
          }
        })
    );
  }

  return (
    <div>
      {/* <Navbar /> */}
      <Flex
        flexDir={[
          "column-reverse",
          "column-reverse",
          "column-reverse",
          "row",
          "row",
        ]}
        width="100vw"
        height={["100%", "100%", "100%", "100vh", "100vh"]}
        overflow="hidden"
        alignItems="center"
      >
        <Flex
          flexDir="column"
          width={["95vw", "95vw", "95vw", "60vw", "60vw"]}
          alignItems="center"
          justifyContent="center"
          height="100%"
        >
          <Flex
            flexDir="column"
            width={["90%", "90%", "85%", "85%", "85%"]}
            mt={["50px", "50px", "50px", "0px", "0px"]}
            mb={["50px", "50px", "50px", "0px", "0px"]}
          >
            <Image src={beenextLogo} height="12px" width="120px" />

            <Heading fontSize="28px" color="#EBB435" fontFamily="fonts.primary">
              Founder's Hub
            </Heading>

            <Heading
              fontSize="28px"
              color="#505058"
              fontFamily="fonts.primary"
              mt="40px"
            >
              Confirm New Password
            </Heading>

            <Text
              fontSize="18px"
              color="#A0AEC0"
              fontFamily="fonts.primary"
              mt="0px"
              fontWeight="700"
            >
              Forgot your password? No worries, we're here to help!
            </Text>

            <FormControl mt="50px">
              <FormLabel color="#505058" fontWeight="700">
                New Password*
              </FormLabel>
              <Input
                id="email"
                bg="#F9F9F9"
                color="#838383"
                type="password"
                fontWeight="700"
                focusBorderColor="#EBB435"
                border="none"
                placeholder="Enter password"
                autocomplete="off"
                ref={p2ref}
              />
            </FormControl>

            <FormControl mt="20px">
              <FormLabel color="#505058" fontWeight="700">
                Confirm New Password*
              </FormLabel>
              <Input
                id="email"
                bg="#F9F9F9"
                color="#838383"
                type="password"
                fontWeight="700"
                focusBorderColor="#EBB435"
                border="none"
                placeholder="Confirm password"
                autocomplete="off"
                ref={p3ref}
              />
            </FormControl>

            <Flex flexDir="row" mt="30px" flexWrap="wrap">
              <Button
                bg="#EBB435"
                width="240px"
                p="2px"
                mt={["0px", "0px", "0px", "60px", "60px"]}
                mb={["20px", "20px", "20px", "0px", "0px"]}
                color="white"
                fontSize="16px"
                onClick={forgorPassword}
                _hover={{
                  background: "#F2DBA6",
                }}
                mr="10px"
              >
                Confirm new password
              </Button>

              <Button
                variant="outline"
                colorScheme="gray"
                width="150px"
                p="2px"
                mt={["0px", "0px", "0px", "60px", "60px"]}
                mb={["20px", "20px", "20px", "0px", "0px"]}
                fontSize="16px"
                as="a"
                href="/"
              >
                Back to Login
              </Button>
            </Flex>
          </Flex>
        </Flex>

        <Flex
          flexDir="column"
          width={["100vw", "100vw", "100vw", "45vw", "45vw"]}
          height="100%"
        >
          <Image
            src={onboardingImage}
            objectFit="cover"
            height={["200px", "200px", "200px", "100%", "100%"]}
            width="100%"
          />
        </Flex>
      </Flex>
    </div>
  );
};

export default ForgotConfirm;
