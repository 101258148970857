export const quickLinks = {
  knowledgeHub:
    "This is the central place where we share all the public reports, studies, presentations etc across geographies, sectors and themes that we feel are useful for founders.",
  newsFeed:
    "Track all the latest updates and developments from the world of news media covering our portfolio companies and founders.",
  eventsSessions:
    "Both upcoming events that we feel would be useful for the community as well as recordings of previously held webinars, sessions at BEENEXT are available here.",
  founderDirectory:
    "This is the core of BEENEXT Founder community. Browse and update the directory here and make new connections. This is all about our community together.",
  operationalTemplates:
    "Useful templates for especially early stage founders to guide discussions and internal reviews, board meetings, etc.",
  partnershipsCredits:
    "Great collaborators and partners in the ecosystem who join us to offer benefits and credits to help the portfolios!",
  beenextPortfolio:
    "All of our amazing Portfolio companies listed by Geography, Sectors in a simple, searchable format.",
  agencyList:
    "List of curated service providers that can give you headstart in legal, marketing, recruitment, go to market etc.",
};
