import { Flex, Image, Text, Button } from "@chakra-ui/react";
import { shareOnWhatsapp } from "../store/functions";
import { BsWhatsapp } from "react-icons/bs";

import Tag from "./Tag";

const ArticleCard = (props) => {
  return (
    <Flex
      m="5px"
      bg="white"
      borderRadius="20px"
      p="25px"
      pt={["15px", "15px", "15px", "15px", "15px"]}
      pb={["15px", "15px", "15px", "15px", "15px"]}
      width="97%"
      alignItems="center"
      flexDir={["column", "column", "column", "row", "row"]}
      __hover={{
        border: "2px solid #EBB435",
      }}
    >
      <Image
        height="auto"
        width={["100%", "100%", "100%", "300px", "300px"]}
        mr={["0", "0", "0", "20px", "20px"]}
        borderRadius="10px"
        objectFit="cover"
        src={props.imgSrc}
      />
      <Flex flexDir="column" width="100%">
        <Flex flexDir="row" mt={["15px", "15px", "15px", "5px", "5px"]}>
          {props.industry ? <Tag text={props.industry} /> : null}
          {props.region ? <Tag text={props.region} /> : null}
        </Flex>

        <Text
          mt="5px"
          color="#4A5568"
          fontWeight="700"
          mb="5px"
          lineHeight="1"
          fontSize="24px"
        >
          {props.title}
        </Text>

        <Text color="#718096" fontSize="16px">
          {props.desc}
        </Text>

        <Flex flexDir="row">
          <Button
            pl="20px"
            mt="10px"
            pr="20px"
            height="30px"
            width="fit-content"
            color="#EBB435"
            background="#FFF2D2"
            textTransform="uppercase"
            as="a"
            href={props.link}
            target="_blank"
            rel="noreferrer"
          >
            Read More
          </Button>

          <Button
            leftIcon={<BsWhatsapp />}
            pl="20px"
            mt="10px"
            pr="20px"
            ml="10px"
            height="30px"
            width="fit-content"
            color="#EBB435"
            background="#FFF2D2"
            textTransform="uppercase"
            as="a"
            href={shareOnWhatsapp(
              "Check out this article from the BEENEXT Founder Hub — *" +
                props.title +
                "*! " +
                props.link
            )}
            target="_blank"
            rel="noreferrer"
          >
            SHARE
          </Button>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default ArticleCard;
