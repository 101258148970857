import { Flex, Heading, Image, Text } from "@chakra-ui/react";
import beenextLogo from "../../resources/imgs/beenextLogo.png";

const PrivacyPolicy = () => {
  return (
    <Flex width="97vw" margin="auto">
      <Flex
        flexDir="column"
        width={["80%", "80%", "700px", "700px", "700px"]}
        alignItems="flex-start"
        justifyContent="center"
        height="100%"
        margin="auto"
        mt="100px"
        mb="100px"
      >
        <Image src={beenextLogo} height="12px" width="120px" />

        <Heading fontSize="28px" color="#EBB435" fontFamily="fonts.primary">
          Founder's Hub
        </Heading>

        <Heading
          fontSize="28px"
          color="#505058"
          fontFamily="fonts.primary"
          mt="40px"
        >
          Privacy Policy
        </Heading>

        <Text
          fontSize="16px"
          color="#A0AEC0"
          fontFamily="fonts.primary"
          mt="20px"
          fontWeight="500"
        >
          Reference to “personal data” means any information relating to a
          person, whether true or not, by which such person may be identified
          from that data or other information to which BEENEXT CAPITAL
          MANAGEMENT Pte. Ltd. (“BEENEXT”) has or is likely to have access to.
          Common examples include names, identification numbers, contact
          information, financial records, credit card information, and location
          data.
          <br></br>
          <br></br>
          BEENEXT is committed to protecting the privacy of our visitors. This
          Privacy Policy is designed to assist you in understanding the types of
          information that is collected and recorded by BEENEXT. Please read
          this Privacy Policy carefully as it also sets out the purposes for
          which BEENEXT collects, uses, discloses, shares, transfers and/or
          processes your personal data.
          <br></br>
          <br></br>
          This Privacy Policy applies only to our online activities and is only
          valid for visitors to our websites with regards to the information
          that they share and/or that BEENEXT collects via the website. For the
          avoidance of doubt, this Privacy Policy is not applicable to any
          information collected offline or via any other channel other than this
          website.
          <br></br>
          <br></br>
          As part of our efforts to ensure that we properly manage, protect and
          process your personal data, we will be reviewing our policies,
          procedures and processes from time to time.
          <br></br>
          <br></br>
          We reserve the right to amend the terms of this Privacy Policy at our
          absolute discretion.
          <br></br>
          <br></br>
          You are encouraged to visit the above website from time to time to
          ensure that you are well informed of our latest policies in relation
          to personal data protection.
          <br></br>
          <br></br>
          By using our website, you hereby expressly provide your consent to our
          Privacy Policy and agree to its terms and the collection, use,
          disclosure, sharing, transfer and/or processing of your personal data
          in accordance with such terms.
        </Text>

        <Text
          fontSize="18px"
          color="#A0AEC0"
          fontFamily="fonts.primary"
          mt="20px"
          fontWeight="700"
        >
          Information We Collect
        </Text>

        <Text
          fontSize="16px"
          color="#A0AEC0"
          fontFamily="fonts.primary"
          mt="20px"
          fontWeight="500"
        >
          The personal data that you are asked to provide and the reasons why
          you are asked to provide it will be made clear to you at the point
          when we ask you to provide your personal data.
          <br></br>
          <br></br>
          If you contact us directly, we may receive additional information
          about you such as your name, email address, phone number, the contents
          of the message and/or attachments you may send us and any other
          information you may choose to provide.
          <br></br>
          <br></br>
          When you register for an account with us, we may ask for your contact
          information, including your name, company name, address, email address
          and telephone number.
          <br></br>
          <br></br>
          The type of personal data that we may collect and the reasons for
          using it will depend on the nature of your relationship with us but
          may include the types of information and purposes set out below.
        </Text>

        <Text
          fontSize="18px"
          color="#A0AEC0"
          fontFamily="fonts.primary"
          mt="20px"
          fontWeight="700"
        >
          How We Collect, Use and/or Disclose Your Information
        </Text>

        <Text
          fontSize="16px"
          color="#A0AEC0"
          fontFamily="fonts.primary"
          mt="20px"
          fontWeight="500"
        >
          We may collect, use and/or disclose your personal data for the
          following purposes:
          <br></br>
          <br></br>
          <ul>
            <li>providing, operating, and maintaining our website;</li>
            <li>improving, personalising, and expand our website;</li>
            <li>understanding and analysing how you use our website;</li>
            <li>
              developing new products for our community, services, features and
              functionality;
            </li>
            <li>
              communicating with you, either directly or through one of our
              partners, including for customer service, to provide you with
              updates and other information relating to the website;
            </li>
            <li>hsending you emails;</li>
            <li>
              carrying out your instructions or responding to any enquiry given
              by (or purported to be given by) you or on your behalf;
            </li>
            <li>
              storing, hosting, backing up (whether for disaster recovery or
              otherwise) of your personal data;
            </li>
            <li>
              conducting research, analysis and development activities
              (including but not limited to data analytics, surveys and/or
              profiling) to improve our services in order to enhance your
              relationship with us or for your benefit;
            </li>
            <li>
              conducting customer due diligence or other screening and personal
              identification processes in accordance with all laws, regulations
              or risk management procedures that may be required by law or that
              may have been put in place by us;
            </li>
            <li>finding and preventing fraud;</li>
            <li>administering security matters and/or arrangements;</li>
            <li>
              complying with or as required by any applicable law, governmental
              or regulatory requirements of any relevant jurisdiction, including
              meeting the requirements to make disclosure under the requirements
              of any law binding on us and/or for the purposes of any guidelines
              issued by regulatory or other authorities with which we are
              expected to comply; and
            </li>
            <li>
              complying with or as required by any request or direction of any
              governmental authority, or responding to requests for information
              from public agencies, ministries, statutory boards, or other
              similar authorities. For the avoidance of doubt, this means that
              we may/will disclose your personal data to the aforementioned
              parties upon their request or direction.
            </li>
          </ul>
          <br></br>
          <br></br>
          BEENEXT follows a standard procedure of using log files. These files
          log visitors when they visit websites. All hosting companies do this,
          and it is a part of hosting services' analytics. The information
          collected by log files include internet protocol ("IP”) addresses,
          browser type, Internet Service Provider (“ISP”), date and time stamps,
          referring/exit pages and possibly the number of clicks. These are not
          linked to any information that is personally identifiable. The purpose
          of the information is for analysing trends, administering the site,
          tracking users' movement on the website and gathering demographic
          information.
        </Text>

        <Text
          fontSize="18px"
          color="#A0AEC0"
          fontFamily="fonts.primary"
          mt="20px"
          fontWeight="700"
        >
          With whom do we share your personal data?
        </Text>

        <Text
          fontSize="16px"
          color="#A0AEC0"
          fontFamily="fonts.primary"
          mt="20px"
          fontWeight="500"
        >
          We do not share your personal data with any third party other than for
          storage purposes on servers which may be located outside the European
          Union (“EU"). We may also share your personal data with competent
          authorities if required under applicable law.
          <br></br>
          <br></br>
          In this case, BEENEXT will ensure that any transfers of personal data
          are in accordance with applicable laws and will put in place
          contractual or other appropriate protections to ensure that the
          relevant third parties provide an adequate level of protection to your
          personal data as set out in this Privacy Policy and as required by
          applicable local law.
        </Text>

        <Text
          fontSize="18px"
          color="#A0AEC0"
          fontFamily="fonts.primary"
          mt="20px"
          fontWeight="700"
        >
          How long do we store your personal data?
        </Text>

        <Text
          fontSize="16px"
          color="#A0AEC0"
          fontFamily="fonts.primary"
          mt="20px"
          fontWeight="500"
        >
          We will delete your personal data when (a) it is no longer reasonably
          required for the purposes for which you have given your consent; (b)
          you have withdrawn your consent (where applicable); or (c) when we are
          not legally required or otherwise permitted to continue to hold such
          data. We may, however, continue to store your personal data, if and
          for such duration and purpose as we may be required to do under
          applicable laws.
        </Text>

        <Text
          fontSize="18px"
          color="#A0AEC0"
          fontFamily="fonts.primary"
          mt="20px"
          fontWeight="700"
        >
          Cookies and Web Beacons
        </Text>

        <Text
          fontSize="16px"
          color="#A0AEC0"
          fontFamily="fonts.primary"
          mt="20px"
          fontWeight="500"
        >
          Like any other website, BEENEXT uses 'cookies'. These cookies are used
          to store information including visitors' preferences, and the pages on
          the website that the visitor accessed or visited. The information is
          used to optimize the users' experience by customizing our web page
          content based on visitors' browser type and/or other information.
          <br></br>
          <br></br>
          You can choose to disable cookies through your web browser options. We
          encourage you to consult your web browser’s website to find out more
          information about cookie management with your web browser.
        </Text>

        <Text
          fontSize="18px"
          color="#A0AEC0"
          fontFamily="fonts.primary"
          mt="20px"
          fontWeight="700"
        >
          Third Party Privacy Policies
        </Text>

        <Text
          fontSize="16px"
          color="#A0AEC0"
          fontFamily="fonts.primary"
          mt="20px"
          fontWeight="500"
        >
          This website may contain links to third party websites. These links
          are provided solely for your convenience.
          <br></br>
          <br></br>
          BEENEXT's Privacy Policy does not apply to other advertisers or
          websites. Access to any other website through any links provided on
          this website is at your own risk and BEENEXT is not responsible for
          the content of any such website or the accuracy or reliability of any
          information, data, or other contents thereof. Thus, we encourage you
          to consult the respective Privacy Policies of these third party
          advertisement servers or websites for more information on personal
          data is collected, used, disclosed and/or processed on these third
          party platforms.
        </Text>

        <Text
          fontSize="18px"
          color="#A0AEC0"
          fontFamily="fonts.primary"
          mt="20px"
          fontWeight="700"
        >
          Information for Singapore Residents
        </Text>

        <Text
          fontSize="16px"
          color="#A0AEC0"
          fontFamily="fonts.primary"
          mt="20px"
          fontWeight="500"
        >
          For Singapore Residents, we may also collect your personal data
          outside of the purposes listed in this Privacy Policy if we have
          assessed that to do so would be in our legitimate interests and
          beneficial to the public. Such legitimate interests would include
          ensuring security, managing disputes, and/or preventing the misuse of
          BEENEXT’s services. Before doing so, we will take steps to ensure that
          any adverse effects that might arise for you have already been
          identified and eliminated, reduced, or mitigated.
        </Text>

        <Text
          fontSize="18px"
          color="#A0AEC0"
          fontFamily="fonts.primary"
          mt="20px"
          fontWeight="700"
        >
          GDPR Data Protection Rights
        </Text>

        <Text
          fontSize="16px"
          color="#A0AEC0"
          fontFamily="fonts.primary"
          mt="20px"
          fontWeight="500"
        >
          EU residents may be entitled to certain rights under the General Data
          Protection Regulation.
          <br></br>
          <br></br>
          These include: The right to access – You have the right to request
          copies of your personal data. We may charge you a small fee for this
          service. <br></br>
          <br></br>The right to rectification – You have the right to request
          that we correct any information you believe is inaccurate. You also
          have the right to request that we complete the information you believe
          is incomplete. <br></br>
          <br></br>The right to erasure – You have the right to request that we
          erase your personal data, under certain conditions. <br></br>
          <br></br>The right to restrict processing – You have the right to
          request that we restrict the processing of your personal data, under
          certain conditions. <br></br>
          <br></br>The right to object to processing – You have the right to
          object to our processing of your personal data, under certain
          conditions. <br></br>
          <br></br>The right to data portability – You have the right to request
          that we transfer the data that we have collected to another
          organization, or directly to you, under certain conditions. <br></br>
          <br></br>If you make a request regarding any of the above, we reserve
          the right to take up to a maximum of one month to respond to you. If
          you would like to exercise any of these rights, please contact us at
          the details below.
        </Text>

        <Text
          fontSize="18px"
          color="#A0AEC0"
          fontFamily="fonts.primary"
          mt="20px"
          fontWeight="700"
        >
          Contact
        </Text>

        <Text
          fontSize="16px"
          color="#A0AEC0"
          fontFamily="fonts.primary"
          mt="20px"
          fontWeight="500"
        >
          If you, at any time, have any queries on this Privacy Policy or any
          other queries in relation to you rights with respect to your personal
          data, or how we may manage, protect and/or process your personal data,
          please do not hesitate to contact our Data Protection Officer at:
          <a href="mailto:privacy@beenext.com">
            <strong>privacy@beenext.com</strong>
          </a>
        </Text>

        <Text
          fontSize="16px"
          color="#A0AEC0"
          fontFamily="fonts.primary"
          mt="40px"
          fontWeight="700"
        >
          Last Updated on 15th May 2022
        </Text>
      </Flex>
    </Flex>
  );
};

export default PrivacyPolicy;
