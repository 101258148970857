import { useState, useContext, useEffect } from "react";
import {
  Box,
  Flex,
  Input,
  Text,
  InputGroup,
  InputLeftElement,
  Spinner,
} from "@chakra-ui/react";
import { Search2Icon } from "@chakra-ui/icons";
import swal from "sweetalert";

import TemplateCard from "../components/TemplateCard";
import Sidebar from "../components/Sidebar";

import AuthContext from "../store/auth";

// PageTemplates page function
const Templates = () => {
  // authentication context
  const authCtx = useContext(AuthContext);

  // useState to populate data from API
  const [tools, setTools] = useState(null);
  const [search, setSearch] = useState("");

  // local functions
  const searchFor = (event) => setSearch(event.target.value);

  const getTools = () => {
    fetch("https://beenext.orangeyak.xyz/api/resources/operational_tools/", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Token " + authCtx.token,
      },
    }).then((response) =>
      response
        .json()
        .then((data) => ({
          data: data,
          status: response.status,
        }))
        .then((res) => {
          if (res.status < 300) {
            setTools(res.data.results);
          } else {
            swal("Oops, something went wrong!", res.data.detail, "error");
          }
        })
    );
  };

  const searchFilters = () => {
    fetch(
      "https://beenext.orangeyak.xyz/api/resources/operational_tools/?search=" +
        search,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Token " + authCtx.token,
        },
      }
    ).then((response) =>
      response
        .json()
        .then((data) => ({
          data: data,
          status: response.status,
        }))
        .then((res) => {
          if (res.status < 300) {
            setTools(res.data.results);
          } else {
            swal("Oops, something went wrong!", res.data.detail, "error");
          }
        })
    );
  };

  // defining useEffects()
  useEffect(() => {
    searchFilters();
    // eslint-disable-next-line
  }, [search]);

  useEffect(() => {
    getTools();
    // eslint-disable-next-line
  }, []);

  // UI render function
  return (
    <Box
      bg="#EFEFEF"
      height="100vh"
      width="100vw"
      display="flex"
      flexDir="row"
      overflow="hidden"
    >
      <Sidebar />

      {/* MASTER FEED WRAPPER SECTION */}
      <Flex
        width={["99%", "99%", "100%", "100%", "100%"]}
        flexDir="column"
        alignItems="center"
        height="95%"
        ml={["10px", "10px", "320px", "320px", "320px"]}
        mr="10px"
        mt={["60px", "60px", "20px", "20px", "20px"]}
        overflowX="hidden"
        overflowY="scroll"
      >
        {/* TOPBAR SECTION */}
        <Flex
          bg="white"
          width="99%"
          borderRadius="20px"
          ml="-10px"
          pt="20px"
          pb="20px"
          flexDir="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Flex maxWidth="600px">
            <Flex flexDir="column" ml="30px">
              <Text fontSize="22px" fontWeight="700" color="#505058">
                OPERATIONAL TOOLS
              </Text>
              <Text
                fontSize="16px"
                lineHeight="120%"
                fontWeight="500"
                color="#A0AEC0"
              >
                Useful templates for especially early stage founders to guide
                discussions and internal reviews, board meetings, etc.
              </Text>
            </Flex>
          </Flex>

          {/* <GlobalSearchBar /> */}
        </Flex>
        {/* END OF TOPBAR SECTION */}

        <Flex bg="white" width="99%" borderRadius="200px" mt="10px">
          <InputGroup width="100%">
            <InputLeftElement
              pt="5px"
              children={<Search2Icon color="gray.300" />}
            />
            <Input
              pr="10px"
              borderRadius="20px"
              placeholder="Search for operational templates"
              color="#A0AEC0"
              focusBorderColor="#EBB435"
              fontWeight="700"
              border="none"
              autocomplete="off"
              onChange={searchFor}
            />
          </InputGroup>
        </Flex>

        <Flex
          flexDir={["column", "column", "row", "row", "row"]}
          width="100%"
          mt="20px"
          justifyContent="space-between"
        >
          <Flex width="100%" flexDir="column">
            {tools ? (
              <>
                {tools.map((tool, id) => (
                  <TemplateCard
                    imgSrc={tool.thumbnail}
                    title={tool.title}
                    desc={tool.description}
                    link={tool.link}
                    tags={tool.tags}
                  />
                ))}
              </>
            ) : (
              <Spinner m="auto" />
            )}
          </Flex>
        </Flex>
      </Flex>
      {/* END OF SCROLLABLE INTERNAL SECTION */}
    </Box>
  );
};

export default Templates;
