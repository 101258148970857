import {
  Box,
  Flex,
  Input,
  Text,
  InputGroup,
  InputLeftElement,
  Checkbox,
  CheckboxGroup,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Spinner,
} from "@chakra-ui/react";
import swal from "sweetalert";

import { Search2Icon } from "@chakra-ui/icons";

import { useState, useContext, useEffect } from "react";

import ArticleCard from "../components/ArticleCard";
import Sidebar from "../components/Sidebar";

import AuthContext from "../store/auth";
import { convertFiltersToRequest } from "../store/functions";

const KnowledgeHub = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const authCtx = useContext(AuthContext);
  const [hub, setHub] = useState(null);

  const getHub = () => {
    fetch("https://beenext.orangeyak.xyz/api/core/posts/", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Token " + authCtx.token,
      },
    }).then((response) =>
      response
        .json()
        .then((data) => ({
          data: data,
          status: response.status,
        }))
        .then((res) => {
          if (res.status < 300) {
            setHub(res.data.results);
          } else {
            swal("Oops, something went wrong!", res.data.detail, "error");
          }
        })
    );
  };

  const [regionFilter, setRegionFilter] = useState();
  const [industryFilter, setIndustryFilter] = useState();

  const [search, setSearch] = useState("");
  const searchFor = (event) => setSearch(event.target.value);

  const updateFilters = () => {
    fetch(
      "https://beenext.orangeyak.xyz/api/core/posts/?" +
        convertFiltersToRequest("industry", industryFilter) +
        "&" +
        convertFiltersToRequest("region", regionFilter),
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Token " + authCtx.token,
        },
      }
    ).then((response) =>
      response
        .json()
        .then((data) => ({
          data: data,
          status: response.status,
        }))
        .then((res) => {
          if (res.status < 300) {
            setHub(res.data.results);
          } else {
            swal("Oops, something went wrong!", res.data.detail, "error");
          }
        })
    );
  };

  const searchFilters = () => {
    fetch("https://beenext.orangeyak.xyz/api/core/posts/?search=" + search, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Token " + authCtx.token,
      },
    }).then((response) =>
      response
        .json()
        .then((data) => ({
          data: data,
          status: response.status,
        }))
        .then((res) => {
          if (res.status < 300) {
            setHub(res.data.results);
          } else {
            swal("Oops, something went wrong!", res.data.detail, "error");
          }
        })
    );
  };

  useEffect(() => {
    searchFilters();
    // eslint-disable-next-line
  }, [search]);

  useEffect(() => {
    updateFilters();
    // eslint-disable-next-line
  }, [regionFilter, industryFilter]);

  useEffect(() => {
    getHub();
    // eslint-disable-next-line
  }, []);

  return (
    <Box
      bg="#EFEFEF"
      height="100vh"
      width="100vw"
      display="flex"
      flexDir="row"
      overflow="hidden"
    >
      {/* SIDEBAR SECTION */}
      <Sidebar />
      {/* END OF SIDEBAR */}

      {/* MASTER FEED WRAPPER SECTION */}
      <Flex
        width={["99%", "99%", "100%", "100%", "100%"]}
        flexDir="column"
        alignItems="center"
        height="95%"
        ml={["10px", "10px", "320px", "320px", "320px"]}
        mr="10px"
        mt={["60px", "60px", "20px", "20px", "20px"]}
        overflowX="hidden"
        overflowY="scroll"
      >
        {/* TOPBAR SECTION */}
        <Flex
          bg="white"
          width="99%"
          borderRadius="20px"
          ml="-10px"
          pt="20px"
          pb="20px"
          flexDir="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Flex maxWidth="600px">
            <Flex flexDir="column" ml="30px">
              <Text fontSize="22px" fontWeight="700" color="#505058">
                KNOWLEDGE HUB
              </Text>
              <Text
                fontSize="16px"
                lineHeight="120%"
                fontWeight="500"
                color="#A0AEC0"
              >
                The central place where we share all the public reports,
                studies, presentations, etc across geographies, sectors and
                themes that we feel are useful for founders.
              </Text>
            </Flex>
          </Flex>

          {/* <GlobalSearchBar /> */}
        </Flex>
        {/* END OF TOPBAR SECTION */}

        <Flex bg="white" width="99%" borderRadius="200px" mt="10px">
          <InputGroup width="100%">
            <InputLeftElement
              pt="5px"
              children={<Search2Icon color="gray.300" />}
            />
            <Input
              pr="10px"
              borderRadius="20px"
              placeholder="Search for articles, insights, and reports"
              color="#A0AEC0"
              focusBorderColor="#EBB435"
              fontWeight="700"
              border="none"
              autocomplete="off"
              onChange={searchFor}
            />
          </InputGroup>
        </Flex>

        <Flex
          flexDir={["column", "column", "row", "row", "row"]}
          width="99%"
          mt="20px"
          justifyContent="space-between"
        >
          <Flex
            width="250px"
            display={["none", "none", "flex", "flex", "flex"]}
            flexDir="column"
          >
            {/* <Button bg="deepYellow" color="white" mb='10px' onClick={updateFilters}>SEARCH</Button> */}

            <Text
              color="lightGray"
              fontWeight="700"
              textTransform="uppercase"
              fontSize="16px"
            >
              REGION
            </Text>

            <CheckboxGroup onChange={setRegionFilter} value={regionFilter}>
              <Checkbox
                colorScheme="yellow"
                defaultIsChecked
                value="SEA"
                fontWeight="700"
                br="10px"
                ml="2px"
                mb="2px"
                color="darkGray"
              >
                SEA
              </Checkbox>

              <Checkbox
                colorScheme="yellow"
                defaultIsChecked
                value="India"
                fontWeight="700"
                br="10px"
                ml="2px"
                mb="2px"
                color="darkGray"
              >
                India
              </Checkbox>

              <Checkbox
                colorScheme="yellow"
                defaultIsChecked
                value="Latin America"
                fontWeight="700"
                br="10px"
                ml="2px"
                mb="2px"
                color="darkGray"
              >
                Latin America
              </Checkbox>

              <Checkbox
                colorScheme="yellow"
                defaultIsChecked
                value="Global"
                fontWeight="700"
                br="10px"
                ml="2px"
                mb="2px"
                color="darkGray"
              >
                Global
              </Checkbox>
            </CheckboxGroup>

            <Text
              color="lightGray"
              fontWeight="700"
              textTransform="uppercase"
              fontSize="16px"
              mt="20px"
            >
              INDUSTRY
            </Text>

            <CheckboxGroup onChange={setIndustryFilter} value={industryFilter}>
              <Checkbox
                colorScheme="yellow"
                defaultIsChecked
                value="Super App"
                fontWeight="700"
                br="10px"
                ml="2px"
                mb="2px"
                color="darkGray"
              >
                Super App
              </Checkbox>

              <Checkbox
                colorScheme="yellow"
                defaultIsChecked
                value="Emerging Markets"
                fontWeight="700"
                ml="2px"
                mb="2px"
                color="darkGray"
              >
                Emerging Markets
              </Checkbox>

              <Checkbox
                colorScheme="yellow"
                value="Crypto"
                fontWeight="700"
                ml="2px"
                mb="2px"
                color="darkGray"
              >
                Crypto
              </Checkbox>

              <Checkbox
                colorScheme="yellow"
                value="Vietnam"
                fontWeight="700"
                ml="2px"
                mb="2px"
                color="darkGray"
              >
                EdTech
              </Checkbox>

              <Checkbox
                colorScheme="yellow"
                value="Mobile Gaming"
                fontWeight="700"
                ml="2px"
                mb="2px"
                color="darkGray"
              >
                Mobile Gaming
              </Checkbox>

              <Checkbox
                colorScheme="yellow"
                value="Crypto NFT"
                fontWeight="700"
                ml="2px"
                mb="2px"
                color="darkGray"
              >
                Crypto NFT
              </Checkbox>

              <Checkbox
                colorScheme="yellow"
                value="SMB"
                fontWeight="700"
                ml="2px"
                mb="2px"
                color="darkGray"
              >
                SMB
              </Checkbox>

              <Checkbox
                colorScheme="yellow"
                value="Startup Funding"
                fontWeight="700"
                ml="2px"
                mb="2px"
                color="darkGray"
              >
                Startup Funding
              </Checkbox>

              <Checkbox
                colorScheme="yellow"
                value="Agritech"
                fontWeight="700"
                ml="2px"
                mb="2px"
                color="darkGray"
              >
                Agritech
              </Checkbox>

              <Checkbox
                colorScheme="yellow"
                value="Climate"
                fontWeight="700"
                ml="2px"
                mb="2px"
                color="darkGray"
              >
                Climate
              </Checkbox>

              <Checkbox
                colorScheme="yellow"
                value="Startup"
                fontWeight="700"
                ml="2px"
                mb="2px"
                color="darkGray"
              >
                Startup
              </Checkbox>

              <Checkbox
                colorScheme="yellow"
                value="Global Ideas"
                fontWeight="700"
                ml="2px"
                mb="2px"
                color="darkGray"
              >
                Global Ideas
              </Checkbox>

              <Checkbox
                colorScheme="yellow"
                value="Budget"
                fontWeight="700"
                ml="2px"
                mb="2px"
                color="darkGray"
              >
                Budget
              </Checkbox>
            </CheckboxGroup>
          </Flex>

          <Button
            onClick={onOpen}
            display={["flex", "flex", "none", "none", "none"]}
            width="100px"
            color="#EBB435"
            bg="#FFF2D2"
          >
            FILTERS
          </Button>

          <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>FILTERS</ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                <Text
                  color="lightGray"
                  fontWeight="700"
                  textTransform="uppercase"
                  fontSize="16px"
                >
                  REGION
                </Text>

                <CheckboxGroup onChange={setRegionFilter} value={regionFilter}>
                  <Checkbox
                    colorScheme="yellow"
                    defaultIsChecked
                    value="SEA"
                    fontWeight="700"
                    br="10px"
                    ml="2px"
                    mb="2px"
                    color="darkGray"
                  >
                    SEA
                  </Checkbox>

                  <Checkbox
                    colorScheme="yellow"
                    defaultIsChecked
                    value="India"
                    fontWeight="700"
                    br="10px"
                    ml="2px"
                    mb="2px"
                    color="darkGray"
                  >
                    India
                  </Checkbox>

                  <Checkbox
                    colorScheme="yellow"
                    defaultIsChecked
                    value="Latin America"
                    fontWeight="700"
                    br="10px"
                    ml="2px"
                    mb="2px"
                    color="darkGray"
                  >
                    Latin America
                  </Checkbox>

                  <Checkbox
                    colorScheme="yellow"
                    defaultIsChecked
                    value="Global"
                    fontWeight="700"
                    br="10px"
                    ml="2px"
                    mb="2px"
                    color="darkGray"
                  >
                    Global
                  </Checkbox>
                </CheckboxGroup>

                <Text
                  color="lightGray"
                  fontWeight="700"
                  textTransform="uppercase"
                  fontSize="16px"
                  mt="20px"
                >
                  INDUSTRY
                </Text>

                <CheckboxGroup
                  onChange={setIndustryFilter}
                  value={industryFilter}
                >
                  <Checkbox
                    colorScheme="yellow"
                    defaultIsChecked
                    value="Super App"
                    fontWeight="700"
                    br="10px"
                    ml="2px"
                    mb="2px"
                    color="darkGray"
                  >
                    Super App
                  </Checkbox>

                  <Checkbox
                    colorScheme="yellow"
                    defaultIsChecked
                    value="Emerging Markets"
                    fontWeight="700"
                    ml="2px"
                    mb="2px"
                    color="darkGray"
                  >
                    Emerging Markets
                  </Checkbox>

                  <Checkbox
                    colorScheme="yellow"
                    value="Crypto"
                    fontWeight="700"
                    ml="2px"
                    mb="2px"
                    color="darkGray"
                  >
                    Crypto
                  </Checkbox>

                  <Checkbox
                    colorScheme="yellow"
                    value="Vietnam"
                    fontWeight="700"
                    ml="2px"
                    mb="2px"
                    color="darkGray"
                  >
                    EdTech
                  </Checkbox>

                  <Checkbox
                    colorScheme="yellow"
                    value="Mobile Gaming"
                    fontWeight="700"
                    ml="2px"
                    mb="2px"
                    color="darkGray"
                  >
                    Mobile Gaming
                  </Checkbox>

                  <Checkbox
                    colorScheme="yellow"
                    value="Crypto NFT"
                    fontWeight="700"
                    ml="2px"
                    mb="2px"
                    color="darkGray"
                  >
                    Crypto NFT
                  </Checkbox>

                  <Checkbox
                    colorScheme="yellow"
                    value="SMB"
                    fontWeight="700"
                    ml="2px"
                    mb="2px"
                    color="darkGray"
                  >
                    SMB
                  </Checkbox>

                  <Checkbox
                    colorScheme="yellow"
                    value="Startup Funding"
                    fontWeight="700"
                    ml="2px"
                    mb="2px"
                    color="darkGray"
                  >
                    Startup Funding
                  </Checkbox>

                  <Checkbox
                    colorScheme="yellow"
                    value="Agritech"
                    fontWeight="700"
                    ml="2px"
                    mb="2px"
                    color="darkGray"
                  >
                    Agritech
                  </Checkbox>

                  <Checkbox
                    colorScheme="yellow"
                    value="Climate"
                    fontWeight="700"
                    ml="2px"
                    mb="2px"
                    color="darkGray"
                  >
                    Climate
                  </Checkbox>

                  <Checkbox
                    colorScheme="yellow"
                    value="Startup"
                    fontWeight="700"
                    ml="2px"
                    mb="2px"
                    color="darkGray"
                  >
                    Startup
                  </Checkbox>

                  <Checkbox
                    colorScheme="yellow"
                    value="Global Ideas"
                    fontWeight="700"
                    ml="2px"
                    mb="2px"
                    color="darkGray"
                  >
                    Global Ideas
                  </Checkbox>

                  <Checkbox
                    colorScheme="yellow"
                    value="Budget"
                    fontWeight="700"
                    ml="2px"
                    mb="2px"
                    color="darkGray"
                  >
                    Budget
                  </Checkbox>
                </CheckboxGroup>
              </ModalBody>

              <ModalFooter>
                {/* <Button
                                color="#EBB435"
                                bg="#FFF2D2"
                                mr={3} 
                                onClick={closeModal}
                                
                            >
                            Search
                            </Button> */}
              </ModalFooter>
            </ModalContent>
          </Modal>

          <Flex
            width="100%"
            ml={["0px", "0px", "20px", "20px", "20px"]}
            flexDir="column"
          >
            <Text
              color="darkGray"
              fontWeight="700"
              textTransform="uppercase"
              fontSize="24px"
              ml="20px"
              mt={["10px", "10px", "0px", "0px", "0px"]}
            >
              LATEST INSIGHTS
            </Text>
            <Text
              color="lightGray"
              fontWeight="400"
              fontSize="16px"
              lineHeight="1.2"
              ml="20px"
              mb="10px"
              width={["90%", "90%", "90%", "70%", "70%", "70%"]}
            >
              The resources shared here are only meant for the BEENEXT Founders'
              Community. Thank you for treating everything with care and strict
              confidentiality <b>(for internal circulation ONLY)</b>.
            </Text>
            {hub ? (
              <>
                {hub.map((h, id) => (
                  <ArticleCard
                    region={h.region.name}
                    industry={h.industry.name}
                    imgSrc={h.thumbnail}
                    title={h.title}
                    desc={h.description}
                    link={h.attachments[0].file}
                  />
                ))}
              </>
            ) : (
              <Spinner margin="auto" />
            )}
          </Flex>
        </Flex>
      </Flex>

      {/* END OF SCROLLABLE INTERNAL SECTION */}
    </Box>
  );
};

export default KnowledgeHub;
