import {
  Flex,
  Button,
  Image,
  Text,
  useDisclosure,
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
} from "@chakra-ui/react";
import { useRef, useContext } from "react";
import swal from "sweetalert";

import fallbackProfile from "../resources/imgs/profile.png";
import AuthContext from "../store/auth";

import { shareOnWhatsapp } from "../store/functions";
import { BsWhatsapp } from "react-icons/bs";

const AgencyCard = (props) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef = useRef();
  const authCtx = useContext(AuthContext);

  const requestConnectionPost = () => {
    fetch("https://beenext.orangeyak.xyz/api/resources/request_connection/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Token " + authCtx.token,
      },
      body: JSON.stringify({
        content_type: "Agency",
        object_name: props.name,
      }),
    }).then((res) => {
      if (res.ok) alert("Connection requested successfully!");
      else
        swal(
          "Something went wrong.",
          "Please try again after some time.",
          "error"
        );
    });
  };

  return (
    <Flex
      m={["5px", "5px", "10px", "10px", "10px"]}
      mt={["15px", "15px", "0px", "0px", "0px"]}
      bg="white"
      borderRadius="20px"
      p="10px"
      width="100%"
      maxWidth="420px"
      alignItems="center"
      flexDir="row"
      ref={btnRef}
      onClick={onOpen}
      cursor="pointer"
    >
      <Image
        boxSize="70px"
        borderRadius="100%"
        ml="20px"
        src={props.imgSrc}
        objectFit="contain"
        fallbackSrc={fallbackProfile}
      />
      <Flex flexDir="column" ml="40px" mr="20px">
        <Text color="#4A5568" fontWeight="700" mb="0px" fontSize="20px">
          {props.name}
        </Text>

        <Text color="deepYellow" fontWeight="700" mt="-5px" fontSize="16px">
          {props.designation} {props.company ? <>at {props.company}</> : null}
        </Text>

        <Drawer
          isOpen={isOpen}
          placement="right"
          onClose={onClose}
          finalFocusRef={btnRef}
        >
          <DrawerOverlay />
          <DrawerContent>
            <DrawerCloseButton />

            <DrawerBody>
              <Flex
                flexDir="column"
                mt="20px"
                justifyContent="center"
                alignItems="center"
              >
                <Image
                  boxSize="120px"
                  src={props.imgSrc}
                  objectFit="contain"
                  borderRadius="100%"
                  fallbackSrc={fallbackProfile}
                />

                <Text
                  mt="10px"
                  color="darkGray"
                  fontWeight="700"
                  fontSize="24px"
                >
                  {props.name}
                </Text>

                <Text
                  color="lightGray"
                  fontWeight="700"
                  fontSize="20px"
                  textAlign="center"
                  lineHeight="1"
                >
                  {props.designation}
                </Text>

                <Flex
                  flexDir="column"
                  mt="10px"
                  width="100%"
                  alignItems="flex-start"
                >
                  {props.brandName ? (
                    <>
                      <Text
                        color="#4A5568"
                        fontSize="16px"
                        fontWeight="700"
                        mt="20px"
                      >
                        BRAND NAME
                      </Text>
                      <Text color="#718096" fontSize="16px">
                        {props.brandName}
                      </Text>
                    </>
                  ) : null}

                  {props.aboutMe ? (
                    <>
                      <Text
                        color="#4A5568"
                        fontSize="16px"
                        fontWeight="700"
                        mt="20px"
                      >
                        ABOUT ME
                      </Text>
                      <Text color="#718096" fontSize="16px">
                        {props.aboutMe}
                      </Text>
                    </>
                  ) : null}

                  <Text
                    color="#4A5568"
                    fontSize="16px"
                    fontWeight="700"
                    mt="20px"
                  >
                    LOCATION
                  </Text>
                  <Text color="#718096" fontSize="16px">
                    {props.location}
                  </Text>

                  <Text
                    color="#4A5568"
                    fontSize="16px"
                    fontWeight="700"
                    mt="20px"
                  >
                    ABOUT COMPANY
                  </Text>
                  <Text color="#718096" fontSize="16px">
                    {props.aboutCompany}
                  </Text>

                  <Text
                    color="#4A5568"
                    fontSize="16px"
                    fontWeight="700"
                    mt="20px"
                  >
                    INDUSTRY
                  </Text>
                  <Text color="#718096" fontSize="16px">
                    {props.industry}
                  </Text>
                </Flex>
              </Flex>
            </DrawerBody>

            <DrawerFooter>
              <Button
                leftIcon={<BsWhatsapp />}
                variant="outline"
                pl="20px"
                pr="20px"
                width="fit-content"
                color="#EBB435"
                background="#fff"
                mr="10px"
                as="a"
                href={shareOnWhatsapp(
                  "Check out this agency from the BEENEXT Founder Hub — *" +
                    props.name +
                    "*!"
                )}
                target="_blank"
                rel="noreferrer"
              >
                Share
              </Button>
              <Button
                color="#EBB435"
                bg="#FFF2D2"
                as="a"
                href={props.reqConnect}
                target="_blank"
                onClick={requestConnectionPost}
                cursor="pointer"
              >
                Request Connection
              </Button>
            </DrawerFooter>
          </DrawerContent>
        </Drawer>
      </Flex>
    </Flex>
  );
};

export default AgencyCard;
