// REACT IMPORTS
import {
  Flex,
  Heading,
  Image,
  FormControl,
  Input,
  Text,
  Button,
  Icon,
  Box,
  Select,
  InputRightElement,
  InputGroup,
} from "@chakra-ui/react";
import { useContext, useState, useEffect } from "react";
import S3FileUpload from "react-s3";
import { useDropzone } from "react-dropzone";
import { AiOutlineTwitter, AiFillLinkedin } from "react-icons/ai";
import swal from "sweetalert";

// RESOURCE IMPORTS
import uploadFile2 from "../../resources/imgs/uploadFile.png";
import onboardingImage from "../../resources/imgs/onboarding.png";
import line from "../../resources/imgs/line.png";

// AUTH CONTEXT IMPORT
import AuthContext from "../../store/auth";

// WINDOW.BUFFER
window.Buffer = window.Buffer || require("buffer").Buffer;

const FormA = (props) => {
  // AUTH CONTEXT
  const authCtx = useContext(AuthContext);

  const [imageURL, setImageURL] = useState(null);

  const [website, setWebsite] = useState(null);
  const [operatingCountries, setOperatingCountries] = useState(null);
  const [businessCategories, setBusinessCategories] = useState(null);
  const [companyTwitter, setCompanyTwitter] = useState(null);
  const [companyLinkedin, setCompanyLinkedin] = useState(null);

  // DROPZONE LOGIC
  const { acceptedFiles, getRootProps, getInputProps } = useDropzone();

  // const files = acceptedFiles.map((file) => (
  //   <li key={file.path}>
  //     {file.path} - {file.size} bytes
  //   </li>
  // ));

  const onFileChange = (file) => {
    const config = {
      bucketName: "beenext-assets",
      dirName: "media/public/uploads",
      region: "ap-south-1",
      accessKeyId: "AKIA2BUOWC5BCTQYGWWZ",
      secretAccessKey: "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",
    };
    S3FileUpload.uploadFile(file, config)
      .then((data) => {
        setImageURL(data.location);
        return data.location;
      })
      .catch((err) => {
        alert(err);
        return null;
      });
  };

  // onImageChange HANDLER FUNCTION PAIR
  const [initialRender, setInitialRender] = useState(true);
  useEffect(() => {
    if (initialRender) setInitialRender(false);
    else onFileChange(acceptedFiles[0]);
    // eslint-disable-next-line
  }, [acceptedFiles[0]]);

  // INITIAL USER DATA — GET REQUEST
  const getOnboardingDetails = () => {
    fetch("https://beenext.orangeyak.xyz/api/users/onboarding_page_1/", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Token " + authCtx.token,
      },
    }).then((response) =>
      response
        .json()
        .then((data) => ({
          data: data,
          status: response.status,
        }))
        .then((res) => {
          if (res.data.status) {
            setBusinessCategories(res.data.user.firm.business_categories);
            setImageURL(decodeURI(res.data.user.firm.firm_logo));
            setWebsite(res.data.user.firm.website);
            setOperatingCountries(res.data.user.firm.other_regions);
            setCompanyTwitter(res.data.user.firm.twitter);
            setCompanyLinkedin(res.data.user.firm.linkedin);
          } else {
            swal("Something went wrong.", res.data.detail, "error");
          }
        })
    );
  };

  // USE EFFECT TO LOAD DATA ON FIRST PAGE LOAD
  useEffect(() => {
    getOnboardingDetails();
    // eslint-disable-next-line
  }, []);

  // UPDATE USER DATA – POST REQUEST
  const setOnboardingDetails = () => {
    fetch("https://beenext.orangeyak.xyz/api/users/onboarding_page_1/", {
      method: "POST",
      body: JSON.stringify({
        business_categories: businessCategories,
        companyLogo: encodeURI(imageURL),
        website: website,
        other_regions: operatingCountries,
        twitter: companyTwitter,
        linkedin: companyLinkedin,
      }),
      headers: {
        "Content-Type": "application/json",
        Authorization: "Token " + authCtx.token,
      },
    }).then((response) =>
      response
        .json()
        .then((data) => ({
          data: data,
          status: response.status,
        }))
        .then((res) => {
          if (res.data.status) {
            window.location = "/onboardingb";
          } else {
            swal("Something went wrong.", res.data.detail, "error");
          }
        })
    );
  };

  return (
    <div>
      <Flex
        flexDir={[
          "column-reverse",
          "column-reverse",
          "column-reverse",
          "row",
          "row",
        ]}
        width="100vw"
        height={["100%", "100%", "100%", "100vh", "100vh"]}
        overflow="hidden"
        alignItems="center"
      >
        <Flex
          flexDir="column"
          width={["95vw", "95vw", "95vw", "60vw", "60vw"]}
          alignItems="center"
          justifyContent="center"
          height="100%"
        >
          <Flex
            flexDir="column"
            width={["90%", "90%", "90%", "80%", "80%"]}
            mt={["50px", "50px", "50px", "0px", "0px"]}
            mb={["50px", "50px", "50px", "0px", "0px"]}
          >
            <Heading
              fontSize="28px"
              color="#505058"
              fontFamily="fonts.primary"
              mt="40px"
            >
              Update your startup profile.
            </Heading>

            <Text
              fontSize="18px"
              color="#A0AEC0"
              fontFamily="fonts.primary"
              mt="0px"
              fontWeight="700"
            >
              Please help us validate and fill the necessary information.
            </Text>

            <Flex
              flexDir="row"
              width="100%"
              justifyContent="space-between"
              marginTop="10px"
            >
              <Box
                width="32%"
                height="10px"
                background="#EBB435"
                borderRadius="50px"
              ></Box>

              <Box
                width="32%"
                height="10px"
                background="#F9F9F9"
                borderRadius="50px"
              ></Box>

              <Box
                width="32%"
                height="10px"
                background="#F9F9F9"
                borderRadius="50px"
              ></Box>
            </Flex>

            {/* <FormControl mt="40px">
              <Flex flexDir="row" mt="10px" justifyContent="space-between">
                <InputGroup bg="#F9F9F9" borderRadius="10px">
                  <Input
                    id="email"
                    color="#838383"
                    type="text"
                    fontWeight="700"
                    background="#F9F9F9"
                    border="none"
                    value={company}
                    disabled="true"
                    autocomplete="off"
                  />
                </InputGroup>
              </Flex>

              <Flex flexDir="row" mt="10px" justifyContent="space-between">
                <InputGroup width="48%" bg="#F9F9F9" borderRadius="10px">
                  <Input
                    id="email"
                    color="#838383"
                    type="text"
                    fontWeight="700"
                    background="#F9F9F9"
                    border="none"
                    value={industry}
                    disabled="true"
                    autocomplete="off"
                  />
                </InputGroup>

                <InputGroup width="48%" bg="#F9F9F9" borderRadius="10px">
                  <InputRightElement
                    pointerEvents="none"
                    children={<CalendarIcon color="gray.300" />}
                  />

                  <Input
                    id="email"
                    color="#838383"
                    type="text"
                    fontWeight="700"
                    background="#F9F9F9"
                    border="none"
                    value={date}
                    disabled="true"
                    autocomplete="off"
                  />
                </InputGroup>
              </Flex>

              <Flex flexDir="row" mt="10px" justifyContent="space-between">
                <InputGroup width="48%" bg="#F9F9F9" borderRadius="10px">
                  <Input
                    id="email"
                    color="#838383"
                    type="text"
                    fontWeight="700"
                    background="#F9F9F9"
                    border="none"
                    value={regName}
                    disabled="true"
                    autocomplete="off"
                  />
                </InputGroup>

                <InputGroup width="48%" bg="#F9F9F9" borderRadius="10px">
                  <Input
                    id="email"
                    color="#838383"
                    type="email"
                    fontWeight="700"
                    background="#F9F9F9"
                    border="none"
                    value={region}
                    disabled="true"
                    autocomplete="off"
                  />
                </InputGroup>
              </Flex>
            </FormControl> */}

            <Image
              mt="20px"
              src={line}
              objectFit="cover"
              width="100%"
              height="1px"
              opacity="30%"
              mb="10px"
            />

            <Flex m="30px" mb="40px">
              <Box
                cursor="pointer"
                {...getRootProps({ className: "dropzone" })}
              >
                <Input {...getInputProps()} />
                <Flex flexDir="row" alignItems="center">
                  {imageURL ? (
                    <Image
                      src={imageURL}
                      objectFit="cover"
                      boxSize="70px"
                      borderRadius="100%"
                    />
                  ) : (
                    <Image src={uploadFile2} boxSize="70px" />
                  )}
                  <Flex flexDir="column" ml="20px">
                    <Text fontSize="20px" fontWeight="700" color="#838383">
                      Upload your company logo.
                    </Text>
                    <Text
                      fontSize="16px"
                      fontWeight="700"
                      lineHeight="1.2"
                      color="#A0AEC0"
                    >
                      Please upload a square, high resolution company logo.
                    </Text>
                  </Flex>
                </Flex>
              </Box>
            </Flex>

            <FormControl>
              <Input
                id="text"
                bg="#F9F9F9"
                color="#838383"
                focusBorderColor="#EBB435"
                type="text"
                fontWeight="700"
                border="none"
                placeholder="Company Website"
                autocomplete="off"
                value={website}
                onChange={(e) => setWebsite(e.target.value)}
                mb="10px"
                size="lg"
              />

              {/* <Input
                mt="10px"
                id="text"
                bg="#F9F9F9"
                color="#838383"
                focusBorderColor="#EBB435"
                type="text"
                fontWeight="700"
                border="none"
                placeholder="Business Categories"
                autocomplete="off"
                value={businessCategories}
              /> */}
              <Select
                placeholder="Select Business Category"
                variant="filled"
                fontWeight="700"
                focusBorderColor="#EBB435"
                mt="10px"
                id="text"
                bg="#F9F9F9"
                value={businessCategories}
                color="#838383"
                onChange={(e) => setBusinessCategories(e.target.value)}
                mb="10px"
                size="lg"
              >
                <option value="Agritech">Agritech</option>
                <option value="AI">AI</option>
                <option value="Analytics">Analytics</option>
                <option value="Angel Funding">Angel Funding</option>
                <option value="Cloud Kitchen">Cloud Kitchen</option>
                <option value="EdTech">EdTech</option>
                <option value="Enterprise SaaS">Enterprise SaaS</option>
                <option value="Fintech">Fintech</option>
                <option value="Food Tech">Food Tech</option>
                <option value="Gaming">Gaming</option>
                <option value="Geotagging">Geotagging</option>
                <option value="Health Tech">Health Tech</option>
                <option value="HR Tech">HR Tech</option>
                <option value="Insuretech">Insuretech</option>
                <option value="IoT">IoT</option>
                <option value="Lifestyle">Lifestyle</option>
                <option value="Logistics">Logistics</option>
                <option value="Marketplace">Marketplace</option>
                <option value="Media">Media</option>
                <option value="Mobility">Mobility</option>
                <option value="Platform">Platform</option>
                <option value="PoS">PoS</option>
                <option value="Proptech">Proptech</option>
                <option value="Real Estate">Real Estate</option>
                <option value="Satellite">Satellite</option>
                <option value="Sustainability">Sustainability</option>
              </Select>

              <Input
                mt="10px"
                id="text"
                bg="#F9F9F9"
                color="#838383"
                focusBorderColor="#EBB435"
                type="text"
                fontWeight="700"
                border="none"
                opacity="1"
                placeholder="Other operating countries"
                autocomplete="off"
                value={operatingCountries}
                onChange={(e) => setOperatingCountries(e.target.value)}
                mb="10px"
                size="lg"
              />

              <Text ml="16px" fontSize="12px" fontWeight="700" color="#A0AEC0">
                Comma-seperated values of countries other than your main country
                of operation
              </Text>
            </FormControl>

            <Flex flexDir="row" mt="10px" justifyContent="space-between">
              <InputGroup
                width="49%"
                bg="#F9F9F9"
                borderRadius="10px"
                size="lg"
              >
                <Input
                  id="email"
                  color="#838383"
                  type="text"
                  fontWeight="700"
                  background="#F9F9F9"
                  border="none"
                  placeholder="Company Twitter URL"
                  autocomplete="off"
                  value={companyTwitter}
                  onChange={(e) => setCompanyTwitter(e.target.value)}
                  focusBorderColor="#EBB435"
                />

                <InputRightElement
                  pointerEvents="none"
                  children={<Icon as={AiOutlineTwitter} color="gray.300" />}
                />
              </InputGroup>

              <InputGroup
                width="49%"
                bg="#F9F9F9"
                borderRadius="10px"
                size="lg"
              >
                <Input
                  id="email"
                  color="#838383"
                  type="email"
                  fontWeight="700"
                  background="#F9F9F9"
                  border="none"
                  placeholder="Company LinkedIn URL"
                  value={companyLinkedin}
                  onChange={(e) => setCompanyLinkedin(e.target.value)}
                  focusBorderColor="#EBB435"
                  autocomplete="off"
                />

                <InputRightElement
                  pointerEvents="none"
                  children={<Icon as={AiFillLinkedin} color="gray.300" />}
                />
              </InputGroup>
            </Flex>

            <Flex flexDir="row" justifyContent="space-between" mt="40px">
              <Button
                bg="#EDF2F7"
                width="150px"
                mr="10px"
                p="2px"
                mt={["0px", "0px", "0px", "30px", "30px"]}
                mb={["20px", "20px", "20px", "0px", "0px"]}
                color="#8E8E8E"
                fontSize="16px"
                _hover={{
                  background: "#E4E4E4",
                }}
                onClick={() => {
                  window.location = "/";
                }}
              >
                Go Back
              </Button>

              <Button
                bg="#EBB435"
                width={["180px", "180px", "180px", "200px", "200px"]}
                p="2px"
                mt={["0px", "0px", "0px", "30px", "30px"]}
                mb={["20px", "20px", "20px", "0px", "0px"]}
                color="white"
                fontSize="16px"
                _hover={{
                  background: "#F2DBA6",
                }}
                isDisabled={!website || !businessCategories}
                onClick={setOnboardingDetails}
              >
                Save and Continue
              </Button>
            </Flex>
          </Flex>
        </Flex>

        <Flex
          flexDir="column"
          width={["100vw", "100vw", "100vw", "45vw", "45vw"]}
          height="100%"
        >
          <Image
            src={onboardingImage}
            objectFit="cover"
            height={["200px", "200px", "200px", "100%", "100%"]}
            width="100%"
          />
        </Flex>
      </Flex>
    </div>
  );
};

export default FormA;
