import { Flex, Box, Text, Icon, Image, Heading } from "@chakra-ui/react";
import { useContext, useState, useEffect } from "react";
import { Link } from "react-router-dom";

import {
  BsLightningCharge,
  BsGlobe,
  BsNewspaper,
  BsBriefcase,
  BsCalendarEvent,
  BsPower,
  BsPencil,
  BsSearch,
  BsKey,
} from "react-icons/bs";
import { GiHamburgerMenu } from "react-icons/gi";

import SidebarLink from "./SidebarLink";

import AuthContext from "../store/auth";
import beenextLogo from "../resources/imgs/beenextLogo.png";

const Sidebar = () => {
  const authCtx = useContext(AuthContext);
  const logout = authCtx.logout;

  const generateFounderPath = (id) => {
    let path = "/founder/";
    return path + id;
  };

  const [sidebarScroll, setSidebarScroll] = useState(null);
  const [blur, setBlur] = useState(null);

  const [gm, setGm] = useState("Good morning, ");

  const gmUser = () => {
    let t1 = new Date().getHours();
    if (t1 < 12) setGm("Good morning, ");
    else if (t1 < 18) setGm("Good afternoon, ");
    else setGm("Good evening, ");
  };

  useEffect(() => {
    gmUser();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <Box
        height="100%"
        width="100%"
        display={blur ? "flex" : "none"}
        bg="black"
        zIndex="500"
        overflowY="hidden"
        opacity="0.3"
        position="absolute"
        onClick={() => {
          setSidebarScroll(false);
          setBlur(false);
        }}
      />

      <Flex position="absolute" zIndex="1000">
        <Icon
          as={GiHamburgerMenu}
          onClick={() => {
            setSidebarScroll(true);
            setBlur(true);
          }}
          ml="20px"
          mt="20px"
          boxSize="20px"
          display={[
            sidebarScroll ? "none" : "flex",
            sidebarScroll ? "none" : "flex",
            "none",
            "none",
            "none",
          ]}
        />

        <Flex
          flexDir="column"
          height="100%"
          mt="20px"
          onClick={() => {
            setSidebarScroll(false);
            setBlur(false);
          }}
          ml={[
            sidebarScroll ? "0px" : "-500px",
            sidebarScroll ? "0px" : "-500px",
            "0px",
            "0px",
            "0px",
          ]}
        >
          <Flex
            flexDir="column"
            minHeight="95vh"
            bg="white"
            width="280px"
            borderRadius="25px"
            ml="20px"
            justifyContent="space-between"
          >
            <Flex flexDir="column">
              <Image
                mt="30px"
                ml="20px"
                src={beenextLogo}
                height="12px"
                width="120px"
                mb="3px"
              />

              <Heading
                ml="20px"
                fontSize="24px"
                mb="30px"
                color="#EBB435"
                fontFamily="fonts.primary"
              >
                Founder's Hub
              </Heading>

              <Link to={generateFounderPath(authCtx.id)}>
                <Flex flexDir="row" alignItems="center" mb="20px">
                  <Image
                    boxSize="45px"
                    ml="20px"
                    src={authCtx.userPp}
                    borderRadius="100%"
                  />

                  <Text
                    ml="10px"
                    color="#505058"
                    fontWeight="700"
                    fontSize="17px"
                    letterSpacing="-0.5"
                    mr="5px"
                    lineHeight="1.1"
                  >
                    {gm}
                    <br></br>
                    {authCtx.name}! 👋🏻
                  </Text>
                </Flex>
              </Link>

              <SidebarLink
                linkIcon={BsLightningCharge}
                linkIsActive={window.location.pathname === "/dashboard"}
                linkTo="/dashboard"
                linkText="Dashboard"
              />

              <SidebarLink
                linkIcon={BsGlobe}
                linkIsActive={window.location.pathname === "/beenext-hq"}
                linkTo="/beenext-hq"
                linkText="BEENEXT HQ"
              />

              <SidebarLink
                linkIcon={BsNewspaper}
                linkIsActive={window.location.pathname === "/newsfeed"}
                linkTo="/newsfeed"
                linkText="News Feed"
              />

              <SidebarLink
                linkIcon={BsBriefcase}
                linkIsActive={window.location.pathname === "/portfolio"}
                linkTo="/portfolio"
                linkText="BEENEXT Portfolio"
              />

              <Text
                ml="20px"
                mt="20px"
                color="#A0AEC0"
                fontWeight="700"
                fontSize="14px"
              >
                BEENEXT DIRECTORY
              </Text>

              <SidebarLink
                linkIcon={BsCalendarEvent}
                linkIsActive={window.location.pathname === "/events"}
                linkTo="/events"
                linkText="Events/Sessions"
              />

              <SidebarLink
                linkIcon={BsGlobe}
                linkIsActive={window.location.pathname === "/knowledge-hub"}
                linkTo="/knowledge-hub"
                linkText="Knowledge Hub"
              />

              <SidebarLink
                linkIcon={BsLightningCharge}
                linkIsActive={window.location.pathname === "/founders"}
                linkTo="/founders"
                linkText="Founder Directory"
              />

              <SidebarLink
                linkIcon={BsGlobe}
                linkIsActive={window.location.pathname === "/agency-list"}
                linkTo="/agency-list"
                linkText="Agency List"
              />

              {/* <SidebarLink 
                    linkIcon={BsNewspaper}
                    linkIsActive={window.location.pathname === "/subject-experts"}               
                    linkTo="/subject-experts"
                    linkText="Subject Experts"
                /> */}

              <SidebarLink
                linkIcon={BsCalendarEvent}
                linkIsActive={window.location.pathname === "/templates"}
                linkTo="/templates"
                linkText="Operational Templates"
              />

              {/* <SidebarLink 
                    linkIcon={BsLightningCharge}
                    linkIsActive={window.location.pathname === "/talent"}               
                    linkTo="/talent"
                    linkText="Talent Recruitment"
                /> */}

              <SidebarLink
                linkIcon={BsGlobe}
                linkIsActive={window.location.pathname === "/partnerships"}
                linkTo="/partnerships"
                linkText="Partnerships"
              />

              <Text
                ml="20px"
                mt="20px"
                color="#A0AEC0"
                fontWeight="700"
                fontSize="14px"
              >
                SEARCH
              </Text>

              <SidebarLink
                linkIcon={BsSearch}
                linkIsActive={window.location.pathname === "/search"}
                linkTo="/search"
                linkText="Global Search"
              />

              {/* <SidebarLink 
                    linkIcon={BsNewspaper}
                    linkIsActive={window.location.pathname === "/team"}               
                    linkTo="/team"
                    linkText="BEENEXT Team"
                /> */}
            </Flex>

            <Flex
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
            >
              <Flex
                flexDir="row"
                alignItems="center"
                justifyContent="center"
                width="100%"
                cursor="pointer"
                mb="10px"
              >
                <Flex as="a" href="/onboardinga">
                  <Icon as={BsPencil} color="#A0AEC0" />

                  <Text
                    ml="10px"
                    lineHeight="1"
                    letterSpacing="-0.5px"
                    color="#A0AEC0"
                    fontWeight="700"
                    fontSize="14px"
                  >
                    Edit Profile
                  </Text>
                </Flex>

                <Flex>
                  <Text
                    ml="10px"
                    lineHeight="1"
                    letterSpacing="-0.5px"
                    color="#A0AEC0"
                    fontWeight="500"
                    fontSize="14px"
                    mb="2px"
                    mr="10px"
                  >
                    |
                  </Text>
                </Flex>

                <Flex as="a" href="/change-password">
                  <Icon as={BsKey} color="#A0AEC0" />

                  <Text
                    ml="5px"
                    lineHeight="1"
                    letterSpacing="-0.5px"
                    color="#A0AEC0"
                    fontWeight="700"
                    fontSize="14px"
                  >
                    Change Password
                  </Text>
                </Flex>
              </Flex>

              <Flex
                flexDir="row"
                alignItems="center"
                justifyContent="center"
                width="100%"
                cursor="pointer"
                mb="20px"
              >
                <Flex onClick={logout}>
                  <Icon as={BsPower} color="#A0AEC0" />

                  <Text
                    ml="10px"
                    lineHeight="1"
                    letterSpacing="-0.5px"
                    color="#A0AEC0"
                    fontWeight="700"
                    fontSize="14px"
                  >
                    Logout
                  </Text>
                </Flex>
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </>
  );
};

export default Sidebar;
