import { Flex, Image, Text, Button } from "@chakra-ui/react";
import { Link } from "react-router-dom";

const PastEvent = (props) => {
  return (
    <Flex
      bg="white"
      width={["100%", "100%", "100%", "49%", "49%"]}
      p="10px"
      pr="40px"
      borderRadius="20px"
      flexDir="column"
      mt="20px"
    >
      <Flex flexDir="column" width="100%" margin="10px" alignItems="center">
        <Image
          src={props.articleImg}
          width="100%"
          height={[null, null, null, "300px", "300px"]}
          mb="10px"
          objectFit="cover"
          borderRadius="10px"
        ></Image>

        <Flex flexDir="column" width="100%" ml="20px">
          <Text
            fontSize="24px"
            fontWeight="700"
            color="darkGray"
            lineHeight="1.2"
            mt="5px"
          >
            {props.name}
          </Text>

          <Text
            fontSize="16px"
            fontWeight="500"
            color="lightGray"
            lineHeight="1.2"
            mt="5px"
          >
            {props.date} | {props.location}
          </Text>
          <Text fontSize="14px" color="darkGray" lineHeight="1.4" mt="5px">
            {props.desc}
          </Text>

          <Flex flexDir="row">
            <Link to={`/events/${props.slug}`}>
              <Button
                pl="20px"
                mt="10px"
                pr="20px"
                height="30px"
                width="fit-content"
                color="#EBB435"
                background="#FFF2D2"
                textTransform="uppercase"
              >
                View more
              </Button>
            </Link>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default PastEvent;
