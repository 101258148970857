import { Box, Flex, Text } from "@chakra-ui/react";
import { useParams } from "react-router-dom";
import { useState, useContext, useEffect } from "react";
import swal from "sweetalert";

import Sidebar from "../components/Sidebar";

import AuthContext from "../store/auth";

const EventDetail = () => {
  const authCtx = useContext(AuthContext);
  const [event, setEvent] = useState(null);
  let { eventSlug } = useParams();

  const getEvents = () => {
    fetch("https://beenext.orangeyak.xyz/api/core/events/" + eventSlug, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Token " + authCtx.token,
      },
    }).then((response) =>
      response
        .json()
        .then((data) => ({
          data: data,
          status: response.status,
        }))
        .then((res) => {
          if (res.status < 300) {
            setEvent(res.data);
          } else {
            swal("Oops, something went wrong!", res.data.detail, "error");
          }
        })
    );
  };

  useEffect(() => {
    getEvents();
    // eslint-disable-next-line
  }, []);

  return (
    <Box
      bg="#EFEFEF"
      height="100vh"
      width="100vw"
      display="flex"
      flexDir="row"
      overflow="hidden"
    >
      {/* SIDEBAR SECTION */}
      <Sidebar />
      {/* END OF SIDEBAR */}

      {/* MASTER FEED WRAPPER SECTION */}
      <Flex
        width={["99%", "99%", "100%", "100%", "100%"]}
        flexDir="column"
        alignItems="center"
        height="95%"
        ml={["10px", "10px", "320px", "320px", "320px"]}
        mr="10px"
        mt={["60px", "60px", "20px", "20px", "20px"]}
        overflowX="hidden"
        overflowY="scroll"
      >
        {/* TOPBAR SECTION */}
        <Flex
          bg="white"
          width="99%"
          borderRadius="20px"
          ml="-10px"
          pt="20px"
          pb="20px"
          flexDir="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Flex maxWidth="600px">
            <Flex flexDir="column" ml="30px">
              <Text fontSize="22px" fontWeight="700" color="#505058">
                BEENEXT Events and Sessions
              </Text>
              <Text
                fontSize="16px"
                lineHeight="120%"
                fontWeight="500"
                color="#A0AEC0"
              >
                We’re thrilled to have you in our BEENEXT family! Feel free to
                read about our vision, goals, find quick links, or connect with
                our team members!
              </Text>
            </Flex>
          </Flex>

          {/* <GlobalSearchBar /> */}
        </Flex>
        {/* END OF TOPBAR SECTION */}

        <Flex flexDir="column" width="100%;" justifyContent="flex-start">
          <Text
            fontWeight="900"
            color="lightGrey"
            fontSize="20px"
            mt="20px"
            ml="20px"
            mb="-10px"
          >
            EVENTS &gt; PAST
          </Text>

          <Flex
            ml="20px"
            flexDir={["column-reverse", "column-reverse", "row", "row", "row"]}
            justifyContent="space-between"
          >
            <Flex width={["90%", "90%", "90%", "40%", "40%"]} flexDir="column">
              {event ? (
                <>
                  <Text
                    fontSize="28px"
                    fontWeight="900"
                    lineHeight="1"
                    color="#505058"
                    mt="20px"
                  >
                    {event.title}
                  </Text>
                  <Text
                    fontSize="18px"
                    lineHeight="1.2"
                    mt="10px"
                    fontWeight="500"
                    color="#A0AEC0"
                  >
                    {event.date} | {event.location}
                  </Text>

                  <Text
                    fontSize="24px"
                    lineHeight="1.2"
                    mt="10px"
                    fontWeight="500"
                    color="#A0AEC0"
                  >
                    {event.description}
                  </Text>
                </>
              ) : null}
            </Flex>

            {event ? (
              <Flex
                width={["90%", "90%", "90%", "55%", "50%"]}
                justifyContent="flex-end"
                mr="20px"
                mt="20px"
              >
                <iframe
                  width="800"
                  height="350"
                  src={event.link}
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; fullscreen; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                ></iframe>
              </Flex>
            ) : null}
          </Flex>
        </Flex>

        <Flex
          flexDir={["column", "column", "column", "row", "row"]}
          width="99%"
          flexWrap="wrap"
          justifyContent="space-between"
        ></Flex>
      </Flex>

      {/* END OF SCROLLABLE INTERNAL SECTION */}
    </Box>
  );
};

export default EventDetail;
