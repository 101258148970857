// react.js imports
import { Box, Flex, Image, Text, Spinner, Icon } from "@chakra-ui/react";
import { useEffect, useState, useContext } from "react";
import swal from "sweetalert";

import {
  BsGlobe,
  BsLightningCharge,
  BsNewspaper,
  BsBriefcaseFill,
  BsCalendarEvent,
  BsTools,
  BsCardList,
  BsCreditCard2Front,
  BsWhatsapp,
  BsLinkedin,
  BsTwitter,
} from "react-icons/bs";

// component imports
import Sidebar from "../components/Sidebar";
import FounderLink from "../components/FounderLink";
import TeamMember from "../components/TeamMember";

// resource imports
import AuthContext from "../store/auth";
import beenextTeamImage from "../resources/imgs/beenextTeamImage.png";
import { quickLinks } from "../store/copy";

// BEENEXT HQ — page function
const BEENEXTHQ = () => {
  // initialise authentication context for API request
  const authCtx = useContext(AuthContext);

  // stores team members after API call
  const [team, setTeam] = useState(null);

  // fetches team members from the API
  const getTeam = () => {
    fetch("https://beenext.orangeyak.xyz/api/resources/beenext_team/", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Token " + authCtx.token,
      },
    }).then((response) =>
      response
        .json()
        .then((data) => ({
          data: data,
          status: response.status,
        }))
        .then((res) => {
          if (res.status < 300) {
            setTeam(res.data.results);
          } else {
            swal("Oops, something went wrong!", res.data.detail, "error");
          }
        })
    );
  };

  // runs on page load
  useEffect(() => {
    getTeam();

    // eslint-disable-next-line
  }, []);

  // UI render
  return (
    <Box
      bg="#EFEFEF"
      height="100vh"
      width="100vw"
      display="flex"
      flexDir="row"
      overflow="hidden"
    >
      {/* SIDEBAR SECTION */}
      <Sidebar />

      {/* MASTER FEED WRAPPER SECTION */}
      <Flex
        width={["99%", "99%", "100%", "100%", "100%"]}
        flexDir="column"
        alignItems="center"
        height="95%"
        ml={["10px", "10px", "320px", "320px", "320px"]}
        mr="10px"
        mt={["60px", "60px", "20px", "20px", "20px"]}
        overflowX="hidden"
        overflowY="scroll"
      >
        {/* TOPBAR SECTION */}
        <Flex
          bg="white"
          width="99%"
          borderRadius="20px"
          ml="-10px"
          pt="20px"
          pb="20px"
          flexDir="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Flex maxWidth="600px">
            <Flex flexDir="column" ml="30px">
              <Text fontSize="22px" fontWeight="700" color="#505058">
                BEENEXT HQ
              </Text>
              <Text
                fontSize="16px"
                lineHeight="120%"
                fontWeight="500"
                color="#A0AEC0"
              >
                We’re thrilled to have you in our BEENEXT family! Feel free to
                read about our vision, goals, find quick links, or connect with
                our team members!
              </Text>
            </Flex>
          </Flex>

          {/* <GlobalSearchBar /> */}
        </Flex>
        {/* END OF TOPBAR SECTION */}

        <Flex flexDir="column" width="100%;" justifyContent="flex-start">
          <Text
            fontWeight="700"
            color="#505058"
            fontSize="18px"
            mt="20px"
            ml="20px"
            mb="10px"
          >
            a message from the BEENEXT community
          </Text>
        </Flex>

        <Flex
          flexDir={["column", "column", "column", "row", "row"]}
          width="99%"
          justifyContent="space-between"
        >
          <Flex
            bg="white"
            width={["100%", "100%", "100%", "49%", "49%"]}
            p="20px"
            borderRadius="20px"
            flexDir="column"
            height="fit-content"
            ml="-10px"
            mb="20px"
            pt="20px"
            pb="20px"
            pl="30px"
          >
            <Image src={beenextTeamImage} />

            <Text
              fontSize="16px"
              lineHeight="120%"
              fontWeight="500"
              color="#718096"
              mt="20px"
            >
              Welcome to the <strong>BEENEXT founders' community</strong> and
              thank you for trusting us to be part of your journey. We are
              thrilled about you and your vision, and what is to come!
            </Text>

            <Text
              fontSize="16px"
              lineHeight="120%"
              fontWeight="500"
              color="#718096"
              mt="20px"
            >
              BEENEXT is a partnership{" "}
              <strong>
                of the founders, by the founders, and for the founders.
              </strong>{" "}
              We envision a shared future through partnership, collaboration,
              and empathy and are proud to be defined by our community of
              founders and investors who share a collective identity.
            </Text>

            <Text
              fontSize="16px"
              lineHeight="120%"
              fontWeight="500"
              color="#718096"
              mt="20px"
            >
              As a new member to the community, please allow us to welcome you
              again. We cannot stress how keen we are to share and{" "}
              <strong>
                build opportunities for co-creation, inclusive growth,
                knowledge-exchange, co-learning, and capital support, together!
              </strong>
            </Text>
          </Flex>

          <Flex
            width={["100%", "100%", "100%", "49%", "49%"]}
            flexDir="column"
            ml="-10px"
            mb="20px"
            mr="10px"
          >
            <Flex
              height="fit-content"
              flexDir="column"
              bg="white"
              p="20px"
              borderRadius="20px"
            >
              <Text fontWeight="700" color="#EBB435" fontSize="20px" mt="-5px">
                our shared beliefs
              </Text>

              <Text
                fontSize="18px"
                mt="10px"
                lineHeight="120%"
                fontWeight="500"
                color="#718096"
                mr="10px"
              >
                <strong>💡 Vision:</strong> We support founders to build
                legendary long-lasting companies.<br></br>
                <strong>🚀 Mission:</strong> Be the most trusted founder centric
                partner. <br></br>
                <strong>🤝 Values:</strong> Founders First. Focus on the Future.
                Trust is Everything. Empathy towards Others.
              </Text>
            </Flex>

            <Flex
              height="fit-content"
              flexDir="column"
              bg="white"
              p="20px"
              borderRadius="20px"
              mt="20px"
            >
              <Text fontWeight="700" color="#EBB435" fontSize="20px" mt="-5px">
                securing our future together
              </Text>

              <Text
                fontSize="18px"
                mt="10px"
                lineHeight="120%"
                fontWeight="500"
                color="#718096"
                mr="10px"
              >
                The resources shared here are{" "}
                <strong>only meant for the BEENEXT Founders' Community.</strong>{" "}
                Thank you for treating everything with care and strict
                confidentiality.
              </Text>
            </Flex>
          </Flex>
        </Flex>

        {/* LINKS SECTIONS */}

        {/* LINKS HEADING */}
        <Flex flexDir="column" width="100%;" justifyContent="flex-start">
          <Text
            fontWeight="700"
            color="#505058"
            fontSize="18px"
            mt="20px"
            ml="20px"
            mb="10px"
          >
            How can I use the BEENEXT Founder Hub?
          </Text>
        </Flex>

        {/* LINKS CONTENT */}
        <Flex
          flexDir={["column", "column", "column", "row", "row"]}
          width="99%"
          bg="white"
          borderRadius="20px"
        >
          <Flex
            width={["100%", "100%", "100%", "50%", "50%"]}
            p="20px"
            flexDir="column"
            justifyContent="space-between"
            ml="-10px"
            mb="0px"
            pt="15px"
            pb={["10px", "10px", "0px", "20px", "20px"]}
            pl="30px"
          >
            <FounderLink
              icon={BsGlobe}
              title="Knowledge Hub"
              link="/knowledge-hub"
              subtitle={quickLinks.knowledgeHub}
            />
            <FounderLink
              icon={BsNewspaper}
              title="News Feed"
              link="/newsfeed"
              subtitle={quickLinks.newsFeed}
            />
            <FounderLink
              icon={BsBriefcaseFill}
              title="BEENEXT Portfolio"
              link="/portfolio"
              subtitle={quickLinks.beenextPortfolio}
            />
            <FounderLink
              icon={BsCalendarEvent}
              title="Events/Sessions"
              link="/events"
              subtitle={quickLinks.eventsSessions}
            />
          </Flex>

          <Flex
            width={["100%", "100%", "100%", "45%", "45%"]}
            p="20px"
            borderRadius="20px"
            flexDir="column"
            justifyContent="space-between"
            ml={["0px", "0px", "0px", "10px", "10px"]}
            mt={["-10px", "-10px", "-10px", "0", "0"]}
            mb="0px"
            pt={["0px", "0px", "0px", "15px", "15px"]}
            pb="20px"
          >
            <FounderLink
              icon={BsLightningCharge}
              title="Founder Directory"
              link="/founders"
              subtitle={quickLinks.founderDirectory}
            />
            <FounderLink
              icon={BsCardList}
              title="Agency List"
              link="/agency-list"
              subtitle={quickLinks.agencyList}
            />
            <FounderLink
              icon={BsTools}
              title="Operational Tools"
              link="/templates"
              subtitle={quickLinks.operationalTemplates}
            />
            <FounderLink
              icon={BsCreditCard2Front}
              title="Partnerships and Credits"
              link="/partnerships"
              subtitle={quickLinks.partnershipsCredits}
            />
          </Flex>
        </Flex>
        {/* END LINKS SECTION */}

        <Flex flexDir="column" width="100%;" justifyContent="flex-start">
          <Text
            fontWeight="700"
            color="#505058"
            fontSize="18px"
            mt="40px"
            ml="20px"
            mb="10px"
          >
            Join the BEENEXT Founder’s Community on WhatsApp!
          </Text>
        </Flex>

        <Flex
          flexDir={["column", "column", "column", "row", "row"]}
          width="99%"
          bg="white"
          borderRadius="20px"
        >
          <Flex
            width={["100%", "100%", "100%", "50%", "50%"]}
            p="20px"
            flexDir="column"
            height="fit-content"
            ml="-10px"
            pt="15px"
            pb={["10px", "10px", "0px", "15px", "15px"]}
            pl="30px"
          >
            <FounderLink
              icon={BsWhatsapp}
              title="BEENEXT Founder Community"
              link="https://chat.whatsapp.com/CaTdJDGuYZkCLOoBaDSvp5"
              subtitle="A general purpose WhatsApp group exclusively for BEENEXT Founders and Co-founders only."
            />

            <FounderLink
              icon={BsWhatsapp}
              title="BEENEXT Fin + Insure Tech"
              link="https://chat.whatsapp.com/G1BbUdDTKIlJEB78ZA4Ar9"
              subtitle="A group of Fintech and Insurance related portfolio companies."
            />

            <FounderLink
              icon={BsWhatsapp}
              title="BEENEXT HRTech"
              link="https://chat.whatsapp.com/Krqan3zohJs470DcW2VNi9"
              subtitle="A group of HRTech and related portfolio companies."
            />
          </Flex>

          <Flex
            width={["100%", "100%", "100%", "45%", "45%"]}
            p="20px"
            borderRadius="20px"
            flexDir="column"
            height="fit-content"
            ml={["0px", "0px", "0px", "10px", "10px"]}
            mt={["-10px", "-10px", "-10px", "0", "0"]}
            pt={["0px", "0px", "0px", "20px", "20px"]}
            pb="20px"
          >
            <FounderLink
              icon={BsWhatsapp}
              title="BEENEXT EdTech"
              link="https://chat.whatsapp.com/CiR98hMxUC2B2vMlILAYam"
              subtitle="A group of Edtech and related portfolio companies."
            />
            <FounderLink
              icon={BsWhatsapp}
              title="BEENEXT LogTech"
              link="https://chat.whatsapp.com/HzS0UjhLLivEopXJjMy0SL"
              subtitle="A group of Logistics, Supply chain and related portfolio companies."
            />
            <FounderLink
              icon={BsWhatsapp}
              title="BEENEXT Climate + Sustainability"
              link="https://chat.whatsapp.com/E1QbDUPngVUJiDBD9XIWUz"
              subtitle="A group of Climate and sustainability related portfolio companies."
            />
          </Flex>
        </Flex>

        <Flex flexDir="column" width="100%;" justifyContent="flex-start">
          <Text
            fontWeight="700"
            color="#505058"
            fontSize="18px"
            mt="40px"
            ml="20px"
            mb="10px"
          >
            Follow BEENEXT on social media!
          </Text>
        </Flex>
        <Flex flexDir="row" width="100%;" ml="20px" justifyContent="flex-start">
          <Flex
            minWidth="40px"
            minHeight="40px"
            bg="lightYellow"
            borderRadius="8px"
            alignItems="center"
            justifyContent="center"
            flexDir="row"
            mr="8px"
            pl="15px"
            pr="15px"
            as="a"
            href="https://www.linkedin.com/company/beenext-pte--ltd/"
            target="_blank"
            rel="noreferrer"
            cursor="pointer"
          >
            <Icon as={BsLinkedin} boxSize="25px" color="#EBB435" />
            <Text fontWeight="600" color="deepYellow" fontSize="18px" ml="10px">
              LinkedIn
            </Text>
          </Flex>

          <Flex
            minWidth="40px"
            minHeight="40px"
            bg="lightYellow"
            borderRadius="8px"
            alignItems="center"
            justifyContent="center"
            flexDir="row"
            mr="8px"
            pl="15px"
            pr="15px"
            as="a"
            href="https://twitter.com/beenextVC"
            target="_blank"
            rel="noreferrer"
            cursor="pointer"
          >
            <Icon as={BsTwitter} boxSize="25px" color="#EBB435" />
            <Text fontWeight="600" color="deepYellow" fontSize="18px" ml="10px">
              Twitter
            </Text>
          </Flex>
        </Flex>

        <Flex flexDir="column" width="100%;" justifyContent="flex-start">
          <Text
            fontWeight="700"
            color="#505058"
            fontSize="18px"
            mt="40px"
            ml="20px"
            mb="10px"
          >
            Meet the BEENEXT Community
          </Text>
        </Flex>

        <Flex
          flexDir={["column", "column", "column", "row", "row"]}
          width="99%"
          flexWrap="wrap"
        >
          {team ? (
            <>
              {team.map((tm, index) => (
                <TeamMember
                  name={tm.name}
                  designation={tm.title}
                  twitter={tm.twitter}
                  linkedin={tm.linkedin}
                  // wa={tm.whatsapp}
                  location={tm.location}
                  email={tm.email}
                  memberImage={tm.avatar}
                />
              ))}
            </>
          ) : (
            <Spinner m="auto" />
          )}
        </Flex>
      </Flex>

      {/* END OF SCROLLABLE INTERNAL SECTION */}
    </Box>
  );
};

export default BEENEXTHQ;
