import { Box, Flex, Spinner, Text } from "@chakra-ui/react";
import { useState, useContext, useEffect } from "react";
import swal from "sweetalert";

import PastEvent from "../components/PastEvent";
import Sidebar from "../components/Sidebar";
import UpcomingEvent from "../components/UpcomingEvent";

import AuthContext from "../store/auth";

const Events = () => {
  const authCtx = useContext(AuthContext);
  const [events, setEvents] = useState(null);

  const getEvents = () => {
    fetch("https://beenext.orangeyak.xyz/api/core/events", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Token " + authCtx.token,
      },
    }).then((response) =>
      response
        .json()
        .then((data) => ({
          data: data,
          status: response.status,
        }))
        .then((res) => {
          if (res.status < 300) {
            setEvents(res.data.results);
          } else {
            swal("Oops, something went wrong!", res.data.detail, "error");
          }
        })
    );
  };

  useEffect(() => {
    getEvents();
    // eslint-disable-next-line
  }, []);

  return (
    <Box
      bg="#EFEFEF"
      height="100vh"
      width="100vw"
      display="flex"
      flexDir="row"
      overflow="hidden"
    >
      {/* SIDEBAR SECTION */}
      <Sidebar />
      {/* END OF SIDEBAR */}

      {/* MASTER FEED WRAPPER SECTION */}
      <Flex
        width={["99%", "99%", "100%", "100%", "100%"]}
        flexDir="column"
        alignItems="center"
        height="95%"
        ml={["10px", "10px", "320px", "320px", "320px"]}
        mr="10px"
        mt={["60px", "60px", "20px", "20px", "20px"]}
        overflowX="hidden"
        overflowY="scroll"
      >
        {/* TOPBAR SECTION */}
        <Flex
          bg="white"
          width="99%"
          borderRadius="20px"
          ml="-10px"
          pt="20px"
          pb="20px"
          flexDir="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Flex maxWidth="600px">
            <Flex flexDir="column" ml="30px">
              <Text fontSize="22px" fontWeight="700" color="#505058">
                BEENEXT Events and Sessions
              </Text>
              <Text
                fontSize="16px"
                lineHeight="120%"
                fontWeight="500"
                color="#A0AEC0"
              >
                Both upcoming events that we feel would be useful for the
                community as well as recordings of previously held webinars,
                sessions at BEENEXT are available here.
              </Text>
            </Flex>
          </Flex>

          {/* <GlobalSearchBar /> */}
        </Flex>
        {/* END OF TOPBAR SECTION */}

        <Flex flexDir="column" width="99%;" justifyContent="flex-start">
          <Text
            fontWeight="900"
            color="#505058"
            fontSize="20px"
            mt="20px"
            ml="20px"
            mb="-10px"
          >
            UPCOMING SESSIONS
          </Text>
        </Flex>

        <Flex
          flexDir={["column", "column", "column", "row", "row"]}
          width="99%"
          flexWrap="wrap"
          justifyContent="space-between"
        >
          {events ? (
            <>
              {events.map((event, index) => (
                <>
                  {event.is_past ? null : (
                    <UpcomingEvent
                      name={event.title}
                      subtitle={event.subtitle}
                      desc={event.description}
                      articleImg={event.thumbnail}
                      link={event.link}
                      date={event.date}
                      location={event.location}
                    />
                  )}
                </>
              ))}
            </>
          ) : (
            <Spinner margin="auto" />
          )}
        </Flex>

        <Flex flexDir="column" width="99%;" justifyContent="flex-start">
          <Text
            fontWeight="900"
            color="#505058"
            fontSize="20px"
            mt="20px"
            ml="20px"
            mb="-10px"
          >
            PAST SESSIONS
          </Text>
        </Flex>

        <Flex
          flexDir={["column", "column", "column", "row", "row"]}
          width="99%"
          flexWrap="wrap"
          justifyContent="space-between"
        >
          {events ? (
            <>
              {events.map((event, index) => (
                <>
                  {event.is_past ? (
                    <PastEvent
                      name={event.title}
                      subtitle={event.subtitle}
                      desc={event.description}
                      articleImg={event.thumbnail}
                      slug={event.slug}
                      date={event.date}
                      location={event.location}
                    />
                  ) : null}
                </>
              ))}
            </>
          ) : (
            <Spinner margin="auto" />
          )}
        </Flex>
      </Flex>

      {/* END OF SCROLLABLE INTERNAL SECTION */}
    </Box>
  );
};

export default Events;
