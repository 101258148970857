import {
  Box,
  Flex,
  Input,
  Text,
  InputGroup,
  InputLeftElement,
  Button,
} from "@chakra-ui/react";
import swal from "sweetalert";

import FounderCard from "../components/FounderCard";
import AgencyCard from "../components/AgencyCard";
import CompanyCard from "../components/CompanyCard";

import { Search2Icon } from "@chakra-ui/icons";

import { useState, useContext } from "react";
import AuthContext from "../store/auth";

import Sidebar from "../components/Sidebar";

const GlobalSearch = () => {
  const authCtx = useContext(AuthContext);
  const [param, setParam] = useState(null);
  const [results, setResults] = useState({
    founders: [],
    agency: [],
    portfolio: [],
  });
  const handleChange = (event) => setParam(event.target.value);

  const getGlobalResults = () => {
    fetch(
      "https://beenext.orangeyak.xyz/api/resources/global_search?q=" + param,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Token " + authCtx.token,
        },
      }
    ).then((response) =>
      response
        .json()
        .then((data) => ({
          data: data,
          status: response.status,
        }))
        .then((res) => {
          if (res.status < 300) {
            setResults(res.data);
          } else {
            swal("Oops, something went wrong!", res.data.detail, "error");
          }
        })
    );
  };

  return (
    <Box
      bg="#EFEFEF"
      height="100vh"
      width="100vw"
      display="flex"
      flexDir="row"
      overflow="hidden"
    >
      {/* SIDEBAR SECTION */}
      <Sidebar />
      {/* END OF SIDEBAR */}

      {/* MASTER FEED WRAPPER SECTION */}
      <Flex
        width={["99%", "99%", "100%", "100%", "100%"]}
        flexDir="column"
        alignItems="center"
        height="95%"
        ml={["10px", "10px", "320px", "320px", "320px"]}
        mr="10px"
        mt={["60px", "60px", "20px", "20px", "20px"]}
        overflowX="hidden"
        overflowY="scroll"
      >
        {/* TOPBAR SECTION */}
        <Flex
          bg="white"
          width="99%"
          borderRadius="20px"
          ml="-10px"
          pt="20px"
          pb="20px"
          flexDir={["column", "column", "column", "row", "row"]}
          alignItems="center"
          justifyContent="space-between"
        >
          <Flex maxWidth="600px">
            <Flex flexDir="column" ml="30px">
              <Text fontSize="22px" fontWeight="700" color="#505058">
                Search all of BEENEXT
              </Text>
              <Text
                fontSize="16px"
                lineHeight="120%"
                fontWeight="500"
                color="#A0AEC0"
              >
                We’re thrilled to have you in our BEENEXT family! Feel free to
                read about our vision, goals, find quick links, or connect with
                our team members!
              </Text>
            </Flex>
          </Flex>

          <InputGroup
            maxWidth="350px"
            mt={["20px", "20px", "20px", "0", "0"]}
            ml="20px"
            mr="30px"
          >
            <InputLeftElement children={<Search2Icon color="gray.300" />} />
            <Input
              pr="10px"
              onChange={handleChange}
              borderRadius="20px"
              placeholder="Search for resources, events, people"
              bg="#FFF6E2"
              color="#A0AEC0"
              focusBorderColor="#EBB435"
              fontWeight="700"
              border="none"
              autocomplete="off"
              type="search"
            />
            <Button
              bg="#E7BD5B"
              ml="20px"
              color="white"
              onClick={getGlobalResults}
            >
              Search
            </Button>
          </InputGroup>
        </Flex>
        {/* END OF TOPBAR SECTION */}

        <Flex flexDir="column" width="99%" flexWrap="wrap" mt="20px">
          {results.founders.length > 0 ? (
            <>
              <Text
                fontSize="28px"
                fontWeight="900"
                lineHeight="1"
                color="#505058"
                mt="20px"
                mb="20px"
                ml="10px"
              >
                FOUNDERS
              </Text>
              <Flex width="99%" flexDir="row" flexWrap="wrap">
                {results.founders.map((fnd, id) => (
                  <FounderCard
                    imgSrc={fnd.avatar}
                    name={fnd.name}
                    founderId={fnd.founder_id}
                    designation={fnd.title}
                    brandName={fnd.firm.name}
                    aboutMe={fnd.bio}
                    location={fnd.firm.region.name}
                    aboutCompany={fnd.firm.description}
                    industry={fnd.firm.industry ? fnd.firm.industry.name : null}
                    reqConnect={fnd.connection}
                    company={fnd.firm.name}
                  />
                ))}
              </Flex>
            </>
          ) : null}

          {results.agency.length > 0 ? (
            <>
              <Text
                fontSize="28px"
                fontWeight="900"
                lineHeight="1"
                color="#505058"
                mt="20px"
                mb="20px"
                ml="10px"
              >
                AGENCIES
              </Text>
              <Flex width="99%" flexDir="row" flexWrap="wrap">
                {results.agency.map((agency, id) => (
                  <AgencyCard
                    imgSrc={agency.thumbnail}
                    name={agency.firm.name}
                    designation={agency.firm.industry.name}
                    brandName={agency.firm.registered_name}
                    location={agency.service_areas_string}
                    aboutCompany={agency.firm.description}
                    industry={agency.firm.industry.name}
                    website={agency.firm.website}
                  />
                ))}
              </Flex>
            </>
          ) : null}

          {results.portfolio.length > 0 ? (
            <>
              <Text
                fontSize="28px"
                fontWeight="900"
                lineHeight="1"
                color="#505058"
                mt="20px"
                mb="20px"
                ml="10px"
              >
                PORTFOLIO
              </Text>
              <Flex width="99%" flexDir="row" flexWrap="wrap">
                {results.portfolio.map((company, id) => (
                  <CompanyCard
                    imgSrc={company.company.firm_logo}
                    title={company.company.name}
                    desc={company.company.description}
                    location={company.company.region.name}
                    website={company.company.website}
                    industry={company.company.industry.name}
                    subtitle={company.company.industry.name}
                    founders="Tim Norton"
                    twitterLink="twitter.com"
                    linkedinLink="linkedin.com"
                  />
                ))}
              </Flex>
            </>
          ) : null}
        </Flex>
      </Flex>

      {/* END OF SCROLLABLE INTERNAL SECTION */}
    </Box>
  );
};

export default GlobalSearch;
