import { extendTheme } from "@chakra-ui/react";

const theme = {
  colors: {
    deepYellow: "#E7BD5B",
    lightYellow: "#FFF2D2",
    darkGray: "#4A5568",
    lightGray: "#A0AEC0",
    bodyGray: "#718096",
    white: "#FFFFFF",
    black: "#000000",
  },
  fonts: {
    primary: "europa",
    secondary: "europa",
    heading: "europa",
    body: "europa",
  },
  config: {
    useSystemColorMode: false,
  },
};

export default extendTheme(theme);
