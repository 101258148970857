import { useState, useContext, useEffect } from "react";

import {
  Box,
  Flex,
  Input,
  Text,
  InputGroup,
  InputLeftElement,
  Button,
  Spinner,
} from "@chakra-ui/react";
import swal from "sweetalert";
import { Search2Icon } from "@chakra-ui/icons";

import NewsCard from "../components/NewsCard";
import Sidebar from "../components/Sidebar";
import AuthContext from "../store/auth";

const Newsfeed = () => {
  const authCtx = useContext(AuthContext);

  const [filter, setFilter] = useState("");

  const [news, setNews] = useState(null);

  const [search, setSearch] = useState("");
  const searchFor = (event) => setSearch(event.target.value);

  const getNewsFeed = () => {
    fetch("https://beenext.orangeyak.xyz/api/resources/news", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Token " + authCtx.token,
      },
    }).then((response) =>
      response
        .json()
        .then((data) => ({
          data: data,
          status: response.status,
        }))
        .then((res) => {
          if (res.status < 300) {
            setNews(res.data.results);
          } else {
            swal("Oops, something went wrong!", res.data.detail, "error");
          }
        })
    );
  };

  useEffect(() => {
    getNewsFeed();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    updateFilters();
    // eslint-disable-next-line
  }, [filter]);

  const updateFilters = () => {
    fetch(
      "https://beenext.orangeyak.xyz/api/resources/news/?region=" + filter,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Token " + authCtx.token,
        },
      }
    ).then((response) =>
      response
        .json()
        .then((data) => ({
          data: data,
          status: response.status,
        }))
        .then((res) => {
          if (res.status < 300) {
            setNews(res.data.results);
          } else {
            swal("Oops, something went wrong!", res.data.detail, "error");
          }
        })
    );
  };

  const searchFilters = () => {
    fetch(
      "https://beenext.orangeyak.xyz/api/resources/news/?search=" + search,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Token " + authCtx.token,
        },
      }
    ).then((response) =>
      response
        .json()
        .then((data) => ({
          data: data,
          status: response.status,
        }))
        .then((res) => {
          if (res.status < 300) {
            setNews(res.data.results);
          } else {
            swal("Oops, something went wrong!", res.data.detail, "error");
          }
        })
    );
  };

  useEffect(() => {
    searchFilters();
    // eslint-disable-next-line
  }, [search]);

  return (
    <Box
      bg="#EFEFEF"
      height="100vh"
      width="100vw"
      display="flex"
      flexDir="row"
      overflow="hidden"
    >
      {/* SIDEBAR SECTION */}
      <Sidebar />
      {/* END OF SIDEBAR */}

      {/* MASTER FEED WRAPPER SECTION */}
      <Flex
        width={["99%", "99%", "100%", "100%", "100%"]}
        flexDir="column"
        alignItems="center"
        height="95%"
        ml={["10px", "10px", "320px", "320px", "320px"]}
        mr="10px"
        mt={["60px", "60px", "20px", "20px", "20px"]}
        overflowX="hidden"
        overflowY="scroll"
      >
        {/* TOPBAR SECTION */}
        <Flex
          bg="white"
          width="99%"
          borderRadius="20px"
          ml="-10px"
          pt="20px"
          pb="20px"
          flexDir="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Flex maxWidth="600px">
            <Flex flexDir="column" ml="30px">
              <Text fontSize="22px" fontWeight="700" color="#505058">
                BEENEXT NEWS
              </Text>
              <Text
                fontSize="16px"
                lineHeight="120%"
                fontWeight="500"
                color="#A0AEC0"
              >
                Track all the latest updates and developments from the world of
                news media covering our portfolio companies and founders.
              </Text>
            </Flex>
          </Flex>

          {/* <GlobalSearchBar /> */}
        </Flex>
        {/* END OF TOPBAR SECTION */}

        <Flex bg="white" width="99%" borderRadius="200px" mt="10px">
          <InputGroup width="100%">
            <InputLeftElement
              pt="5px"
              children={<Search2Icon color="gray.300" />}
            />
            <Input
              pr="10px"
              borderRadius="20px"
              placeholder="Search for news articles"
              color="#A0AEC0"
              focusBorderColor="#EBB435"
              fontWeight="700"
              border="none"
              autocomplete="off"
              onChange={searchFor}
            />
          </InputGroup>
        </Flex>

        <Flex width="99%" flexDir="row" flexWrap="wrap" mt="10px">
          <Button
            color={filter === "" ? "white" : "#8E8E8E"}
            bg={filter === "" ? "#EBB435" : "white"}
            onClick={() => setFilter("")}
            mr="10px"
            mt="10px"
          >
            All Countries
          </Button>

          <Button
            color={filter === "Phillipines" ? "white" : "#8E8E8E"}
            bg={filter === "Phillipines" ? "#EBB435" : "white"}
            onClick={() => setFilter("Phillipines")}
            border="none"
            mr="10px"
            mt="10px"
          >
            Portfolio — Phillipines
          </Button>

          <Button
            color={filter === "Indonesia" ? "white" : "#8E8E8E"}
            bg={filter === "Indonesia" ? "#EBB435" : "white"}
            onClick={() => setFilter("Indonesia")}
            border="none"
            mr="10px"
            mt="10px"
          >
            Portfolio — Indonesia
          </Button>

          <Button
            color={filter === "India" ? "white" : "#8E8E8E"}
            bg={filter === "India" ? "#EBB435" : "white"}
            onClick={() => setFilter("India")}
            border="none"
            mr="10px"
            mt="10px"
          >
            Portfolio — India
          </Button>
        </Flex>

        <Flex flexDir="column" width="100%;" justifyContent="flex-start">
          <Text
            fontWeight="700"
            color="#505058"
            fontSize="18px"
            mt="20px"
            ml="20px"
            mb="10px"
          >
            NEWS FROM THE WEEK
          </Text>
        </Flex>

        <Flex
          flexDir={["column", "column", "column", "row", "row"]}
          width="99%"
          justifyContent="space-between"
          flexWrap="wrap"
        >
          {news !== null ? (
            <>
              {news.map((newz, index) => (
                <NewsCard
                  imgSrc={newz.thumbnail}
                  tagText={newz.company_name}
                  title={newz.title}
                  agency={newz.publication}
                  desc={newz.description}
                  link={newz.link}
                  date={newz.publication_date}
                />
              ))}
            </>
          ) : (
            <Spinner margin="auto" />
          )}
        </Flex>
      </Flex>

      {/* END OF SCROLLABLE INTERNAL SECTION */}

      {/* .slice(0).reverse() */}
    </Box>
  );
};

export default Newsfeed;
