import { Flex, Box, Text, Icon } from "@chakra-ui/react";

const FounderLink = (props) => {
  return (
    <a href={props.link}>
      <Flex
        flexDir="row"
        alignItems="center"
        width="100%"
        mt="5px"
        mb="0px"
        p="5px"
      >
        <Box
          minWidth="50px"
          minHeight="50px"
          display="flex"
          bg="#FFF2D2"
          borderRadius="10px"
          alignItems="center"
          justifyContent="center"
          mr="15px"
        >
          <Icon as={props.icon} boxSize="25px" color="#EBB435" />
        </Box>

        <Flex flexDir="column">
          <Text
            fontSize="16px"
            lineHeight="1.1"
            color="#505058"
            width="100%"
            fontWeight="700"
            mt="5px"
          >
            {props.title}
          </Text>
          <Text
            fontSize="15px"
            lineHeight="1.1"
            color="#A0AEC0"
            fontWeight="500"
            mt="4px"
            mb="5px"
          >
            {props.subtitle}
          </Text>
        </Flex>
      </Flex>
    </a>
  );
};

export default FounderLink;
