import { Flex, Text } from "@chakra-ui/react";

const Announcement = (props) => {
  return (
    <Flex
      flexDir="column"
      bg="#FFF6E2"
      width="100%"
      mt="12px"
      mb="0px"
      pt="10px"
      pb="10px"
      borderRadius="10px"
      pl="20px"
    >
      <Text
        fontSize="16px"
        lineHeight="1.1"
        color="#505058"
        fontWeight="700"
        mt="5px"
      >
        {props.title}
      </Text>
      <Text
        fontSize="13px"
        lineHeight="1.1"
        color="#A0AEC0"
        fontWeight="700"
        mt="5px"
        mb="5px"
      >
        {props.desc}
      </Text>
    </Flex>
  );
};

export default Announcement;
