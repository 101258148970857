import {
  Flex,
  Button,
  Image,
  Text,
  useDisclosure,
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
} from "@chakra-ui/react";
import { useRef } from "react";

const CompanyCard = (props) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef = useRef();
  return (
    <Flex
      m="10px"
      bg="white"
      borderRadius="20px"
      p="20px"
      width="97%"
      alignItems="center"
      flexDir="row"
      __hover={{
        border: "2px solid #EBB435",
      }}
    >
      <Image
        boxSize={["100px", "100px", "100px", "150px", "150px"]}
        ml="20px"
        src={props.imgSrc}
        objectFit="contain"
      />
      <Flex flexDir="column" ml="40px" mr="20px">
        <Text mt="10px" color="#4A5568" fontWeight="700" fontSize="20px">
          {props.title}
        </Text>

        <Text
          color="#A0AEC0"
          fontWeight="700"
          textTransform="uppercase"
          fontSize="18px"
        >
          {props.location}
        </Text>

        <Text color="#718096" fontSize="16px">
          {props.desc}
        </Text>

        <Flex flexDir="row">
          <Button
            ref={btnRef}
            onClick={onOpen}
            pl="20px"
            mt="10px"
            pr="20px"
            height="30px"
            width="fit-content"
            color="#EBB435"
            bg="#FFF2D2"
            textTransform="uppercase"
          >
            Learn More
          </Button>
        </Flex>

        <Drawer
          isOpen={isOpen}
          placement="right"
          onClose={onClose}
          finalFocusRef={btnRef}
        >
          <DrawerOverlay />
          <DrawerContent>
            <DrawerCloseButton />

            <DrawerBody>
              <Flex
                flexDir="column"
                mt="20px"
                justifyContent="center"
                alignItems="center"
              >
                <Image
                  width="100px"
                  src={props.imgSrc}
                  height="100px"
                  objectFit="contain"
                />

                <Text
                  mt="10px"
                  color="#4A5568"
                  fontWeight="700"
                  fontSize="24px"
                >
                  {props.title}
                </Text>

                <Text
                  color="#A0AEC0"
                  fontWeight="700"
                  textTransform="uppercase"
                  fontSize="20px"
                >
                  {props.subtitle}
                </Text>

                {/* <Flex flexDir="row" justifyContent="center" flexWrap="wrap" mt="10px">
                                <Tag text="MEDIA"/>
                                <Tag text="PLATFORM"/>
                            </Flex> */}

                <Flex flexDir="column" mt="10px">
                  <Text
                    color="#4A5568"
                    fontSize="16px"
                    fontWeight="700"
                    mt="20px"
                  >
                    DESCRIPTION
                  </Text>
                  <Text color="#718096" fontSize="16px">
                    {props.desc}
                  </Text>

                  <Text
                    color="#4A5568"
                    fontSize="16px"
                    fontWeight="700"
                    mt="20px"
                  >
                    LOCATION
                  </Text>
                  <Text color="#718096" fontSize="16px">
                    {props.location}
                  </Text>

                  <Text
                    color="#4A5568"
                    fontSize="16px"
                    fontWeight="700"
                    mt="20px"
                  >
                    WEBSITE
                  </Text>
                  <Text
                    as="a"
                    href={props.website}
                    color="#71AAFF"
                    fontSize="16px"
                  >
                    {props.website}
                  </Text>

                  <Text
                    color="#4A5568"
                    fontSize="16px"
                    fontWeight="700"
                    mt="20px"
                  >
                    INDUSTRY
                  </Text>
                  <Text color="#718096" fontSize="16px">
                    {props.industry}
                  </Text>

                  <Text
                    color="#4A5568"
                    fontSize="16px"
                    fontWeight="700"
                    mt="20px"
                  >
                    FOUNDERS
                  </Text>
                  <Text color="#718096" fontSize="16px">
                    {props.founders}
                  </Text>

                  <Flex></Flex>
                </Flex>
              </Flex>
            </DrawerBody>

            <DrawerFooter>
              <Button variant="outline" mr={3} onClick={onClose}>
                Cancel
              </Button>
              <Button color="#EBB435" bg="#FFF2D2">
                Request Connection
              </Button>
            </DrawerFooter>
          </DrawerContent>
        </Drawer>
      </Flex>
    </Flex>
  );
};

export default CompanyCard;
