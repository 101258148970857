import { Button, Flex, Image, Text } from "@chakra-ui/react";

const TemplateCard = (props) => {
  return (
    <Flex
      m="5px"
      bg="white"
      borderRadius="20px"
      p="25px"
      pt="20px"
      pb="20px"
      width="99%"
      alignItems="center"
      flexDir={["column", "column", "column", "row", "row"]}
      __hover={{
        border: "2px solid #EBB435",
      }}
      target="_blank"
      rel="noreferrer"
    >
      <Image
        height="auto"
        width={["100%", "100%", "100%", "300px", "300px"]}
        mr={["0", "0", "0", "20px", "20px"]}
        borderRadius="10px"
        objectFit="cover"
        src={props.imgSrc}
      />
      <Flex flexDir="column">
        <Text
          mt={["15px", "15px", "15px", "5px", "5px"]}
          color="#4A5568"
          fontWeight="700"
          mb="5px"
          lineHeight="1"
          fontSize="24px"
        >
          {props.title}
        </Text>

        <Text color="#718096" fontSize="16px">
          {props.desc}
        </Text>

        <Flex flexDir="row">
          <Button
            width="120px"
            mt="10px"
            size="sm"
            as="a"
            href={props.link}
            fontSize="16px"
            bg="lightYellow"
            color="deepYellow"
            _hover={{
              background: "#F2DBA6",
            }}
          >
            DOWNLOAD
          </Button>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default TemplateCard;
