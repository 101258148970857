import { Box, Flex, Text } from "@chakra-ui/react";

import Sidebar from "../components/Sidebar";
import PartnerCard from "../components/PartnerCard";

import snaphunt from "../resources/imgs/snaphunt.jpeg";
import microsoft from "../resources/imgs/microsoft.png";
import aws from "../resources/imgs/aws.png";
import google from "../resources/imgs/google.png";
import notion from "../resources/imgs/notion.png";
import tracxn from "../resources/imgs/traxcn.png";
import freshworks from "../resources/imgs/freshworks.png";
import hubspot from "../resources/imgs/hubspot.png";
import stripe from "../resources/imgs/stripe.png";
import webengage from "../resources/imgs/webengage.png";
import retool from "../resources/imgs/retool.png";
import moenglogo from "../resources/imgs/moenglogo.jpg";
import zimg from "../resources/imgs/zimg.png";
import workable from "../resources/imgs/workable.webp";

const Partnerships = () => {
  return (
    <Box
      bg="#EFEFEF"
      height="100vh"
      width="100vw"
      display="flex"
      flexDir="row"
      overflow="hidden"
    >
      {/* SIDEBAR SECTION */}
      <Sidebar />
      {/* END OF SIDEBAR */}

      {/* MASTER FEED WRAPPER SECTION */}
      <Flex
        width={["99%", "99%", "100%", "100%", "100%"]}
        flexDir="column"
        alignItems="center"
        height="95%"
        ml={["10px", "10px", "320px", "320px", "320px"]}
        mr="10px"
        mt={["60px", "60px", "20px", "20px", "20px"]}
        overflowX="hidden"
        overflowY="scroll"
      >
        {/* TOPBAR SECTION */}
        <Flex
          bg="white"
          width="99%"
          borderRadius="20px"
          ml="-10px"
          pt="20px"
          pb="20px"
          flexDir="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Flex maxWidth="600px">
            <Flex flexDir="column" ml="30px">
              <Text fontSize="22px" fontWeight="700" color="#505058">
                BEENEXT Partnerships
              </Text>
              <Text
                fontSize="16px"
                lineHeight="120%"
                fontWeight="500"
                color="#A0AEC0"
              >
                Great collaborators and partners in the ecosystem who join us to
                offer benefits and credits to help the portfolios!
              </Text>
            </Flex>
          </Flex>

          {/* <GlobalSearchBar /> */}
        </Flex>
        {/* END OF TOPBAR SECTION */}

        <Flex
          flexDir={["column", "column", "column", "row", "row"]}
          width="99%"
          flexWrap="wrap"
          justifyContent="space-between"
        >
          <PartnerCard
            name="Workable"
            subtitle="RECRUITING SOFTWARE"
            desc="Make the right hires, faster with the world's leading recruiting software"
            logo={workable}
            content={WorkableContent}
          />

          <PartnerCard
            name="MoEngage"
            subtitle="GROWTH ACCELERATOR"
            desc="Retain & Engage more users with the MoEngage platform, training, and community!"
            logo={moenglogo}
            content={MoEngage}
          />

          <PartnerCard
            name="Zimyo"
            subtitle="HR AND PAYROLL PLATFORM"
            desc="From Onboarding to Offboarding Everything you need for HR management!"
            logo={zimg}
            content={Zimyo}
          />

          <PartnerCard
            name="Snaphunt Talent Partnership"
            subtitle="TALENT RECRUITMENT"
            desc="Snaphunt is Asia's fastest growing remote hiring platform and its founder, Tulika, is a member of our community!"
            logo={snaphunt}
            content={SnaphuntContent}
          />

          <PartnerCard
            name="Microsoft Partnerships"
            subtitle="CLOUD CREDITS"
            desc="BEENEXT has a strategic partnership with Microsoft India and APAC. Our relationship has allowed us to work collaboratively with founders on specific use cases, stages, and scenarios."
            logo={microsoft}
            content={AmazonContent}
          />
          <PartnerCard
            name="Amazon Web Services"
            subtitle="CLOUD CREDITS"
            desc="Each startup can choose from either $25,000 in AWS Promotional Credit valid for 2 Years or $100,000 in AWS Promotional Credit for 1 year (one or the other). If you are interested, please follow the simple process below."
            logo={aws}
            content={MicrosoftContent}
          />
          <PartnerCard
            name="Google Credits"
            subtitle="CLOUD CREDITS"
            desc="Google Cloud for Startups will provide a single avenue for startups to apply for benefits, which will simplify the process for qualifying for Cloud credits and allow them to better tailor benefits to startups across stages. "
            logo={google}
            content={GoogleContent}
          />
          <PartnerCard
            name="Notion Credits"
            subtitle="CLOUD CREDITS"
            desc="Notion is also part of the BEENEXT Founder's Community! Please access the following link and apply for $1,000 in credit:"
            applyLink="https://www.notion.so/startups"
            logo={notion}
          />

          <PartnerCard
            name="Tracxn Founder's Pass"
            subtitle="CLOUD CREDITS"
            desc="Tracxn is one of the world’s largest platforms for tracking startups and private companies spread across 300+ technology sectors and 800+ emerging themes, with dedicated coverage on 30+ countries. With Founder's Pass, one can have access to Tracxn for a period of 2 weeks, atleast. Please apply here."
            logo={tracxn}
            content={tc}
          />

          <PartnerCard
            name="Freshworks Credits"
            subtitle="CLOUD CREDITS"
            desc="Freshworks is a leading maker of cloud-based customer and employee engagement software based in San Mateo, California. The company's cloud-based suite is widely used by over 150,000 businesses around the world including the NHS, Honda, Rightmove, Hugo Boss, Citizens Advice, Toshiba, and Cisco."
            logo={freshworks}
            content={Fresh}
          />

          <PartnerCard
            name="Hubspot Discount"
            subtitle="CLOUD CREDITS"
            desc="We’re excited to partner with HubSpot for Startups to offer their program designed specifically to help startups grow and scale better, and faster - at a startup-friendly cost! Read on to learn more about the program."
            logo={hubspot}
            content={hub}
          />

          <PartnerCard
            name="Stripe Discount"
            subtitle="CLOUD CREDITS"
            desc="Whether you’re creating a software subscription service, a marketplace, or an e-commerce store, Stripe’s integrated payments platform helps you build and scale your business online."
            logo={stripe}
            content={stripeContent}
          />
          <PartnerCard
            name="WebEngage Startup Program"
            subtitle="CLOUD CREDITS"
            desc="WebEngage has launched its Startup Program 2.0 to help early stage startups lay foundations for long-term user retention, set the right dashboards and metrics and focus on personalized user engagement."
            logo={webengage}
            content={webEngage}
          />

          <PartnerCard
            name="Retool"
            subtitle="CLOUD CREDITS"
            desc="Retool is a low-code platform that makes it fast and easy to build internal tools. Business teams need custom apps, dashboards, admin panels, and other internal tools to run critical operations. Rather than build from scratch, developers can use Retool to build powerful tools, faster."
            logo={retool}
            content={retoolc}
          />
        </Flex>
      </Flex>

      {/* END OF SCROLLABLE INTERNAL SECTION */}
    </Box>
  );
};

export default Partnerships;

const MoEngage = `
    <div>
        <h1>
        Beenext has partnered with MoEngage to launch its <a href="https://www.moengage.com/exp/growth-acceleration-program-sea/" target="_blank" rel="noreferrer" style="color: blue;">Growth Acceleration Program (GAP)</a> exclusively for Startups in the Beenext portfolio. As a part of this initiative, they are offering heavy discounts on your monthly and annual subscription plans to the startups in our portfolio.
            <br></br>
        </h1>
        <h1>
          <strong>About MoEngage</strong>
          <br></br>
          MoEngage’s vision since its inception has always been to empower consumer brands to create great experiences by offering convenience and ease. Their intelligent customer engagement platform is built for the user-obsessed marketer. With AI-powered customer journey orchestration, personalization capabilities, and in-built analytics, MoEngage enables hyper-personalization at scale across mobile, email, web, SMS, social media, and messaging channels. 
          <br></br>

          Their efforts have been recognized by the world’s leading analysts. 
          <ol>
            <li>Gartner declared MoEngage a Leader in the <a href="https://www.moengage.com/analyst-reports/gartner-mq-mobile-marketing-leader-thank-you/" target="_blank" rel="noreferrer" style="color: blue;">2020 Gartner Magic Quadrant</a> for Mobile Marketing Platforms </li>
            <li>MoEngage received the highest overall rating in the  <a href="https://www.moengage.com/blog/moengage-highest-customer-rating-gartner-peer-insights-mobile-marketing-platforms/" target="_blank" rel="noreferrer" style="color: blue;">2019 Gartner Peer Insights ‘Voice Of The Customer’</a> report. </li>
            <li>Forrester identified MoEngage as a Strong Performer in  <a href="https://www.moengage.com/moengage-strong-performer-forrester-wave-thankyou/" target="_blank" style="color: blue;" rel="noreferrer">The Forrester Wave™: Mobile Engagement Automation, Q3 2020.</a></li>
            <li>Lastly, G2 ranked MoEngage the #1 Mobile Marketing Platform in their  <a href="https://www.moengage.com/blog/mobile-marketing-platform-g2s-spring-2021/" target="_blank" style="color: blue;" rel="noreferrer">Spring 2021 Momentum Report</a> and a Leader across 11 categories including Mobile Marketing and Analytics, based on customer ratings.</li>
          </ol>
          <br></br>

          Startups that are a part of MoEngage GAP will benefit from:
          <ol>
            <li>Pricing Discounts upto 50%</li>
            <li>Dedicated Learning & Development resources (benchmark reports, webinars, industry case studies, etc)</li>
            <li>Access to #GROWTH community – a community of founders, product owners, and growth managers from growth-focused startups</li>
          </ol>
          <br></br>
          If you’re interested in learning more about MoEngage GAP, you can  <a href="https://www.moengage.com/exp/growth-acceleration-program/?utm_source=stellaris&utm_medium=googlemail&utm_campaign=Gap" style="color: blue;" target="_blank" rel="noreferrer">click here</a> for more details or reach out to  <a href="mailto:moses.purba@moengage.com" target="_blank" style="color: blue;" rel="noreferrer">Moses</a> (+62811314776)  from the MoEngage team directly.
          </h1>
    </div>
`;

const Zimyo = `
    <div>
    <h1>
    About Zimyo:
    </h1>
    <br></br>
    Zimyo aims to transform employee experiences for high-growth organizations by providing an AI-driven, cloud-based HRMS suite. We are the leading HRMS provider in the Asia-Pacific region, offering core HR, Payroll, Performance, and Recruitment solutions that assist in retaining the employee lifecycle.
    <br></br>
    With 1000+ companies of varied sizes onboard, Zimyo is rising perennially like a phoenix. Our solutions are replacing the process-driven, legacy platforms while assisting in reducing employee attrition and ultimately surging the retention rates.
    <br></br><br></br>
    <h1>Our offer for BEENEXT startups:</h1>
    <ul>
    <li>Free six months SaaS subscription for Core HR & Payroll Product Suite including Mobile App, Employee Self Service, Benefits, etc
    <li>25% discount on all other modules including - Recruitment Management, Performance Management, etc</li>
    <li>Dedicated 18 Hours of onboarding including setting up HR processes for the startup</li>
    <li>Dedicated 4 hours of 'Strategic HR/ OKR' Consulting</li>
    <br></br>
    </ul>
    Free HR Templates hosted on the platform for Offer letter generation, appraisal, and many more.
    Offer Available only for BEENEXT startups
    <br></br>
    <br></br>
    <h1>How to avail the offer: </h1>
    <br></br>
    Visit https://www.zimyo.work/register.  After Signup (Create Userid and Password )→ Subscription  (on the left bottom side of the page) → Manage Subscriptions → Upgrade → Select HRMS BASIC + Add number of users→ Select half yearly subscription → Apply coupon code BNXT2022 → Proceed to pay→ User validation will be done by deduction of Rs 1.
    <br></br>
    
    For Further information, mail at sonal.varshney@zimyo.com or call at 9711133327 
    <br></br>
    Looking forward to your sharing the proposal with BEENEXT startups. Also, we would be grateful if you can promote our startup program by sharing it with fellow VCs/Accelerators in the ecosystem or startups who can benefit from this program.
    </div>
`;

const WorkableContent = `
    <div>
    <strong>
    Offers for portfolio companies
    </strong>
    <br>  <br>


<strong>15% off your annual contract</strong>
<br>

Sign up with Workable, you'll get 15% off your annual contract price for the first year. Plus, Video Interviews and Texting are FREE for 2 months* 
<br>
Fill our the following form ensuring you select your VC from the list:
<strong><a href="https://get.workable.com/startup-network">Click here to avail</a></strong>


 <br></br>

 <strong><h2>3 months for FREE access to Workable</h2></strong>

Sign up to Workable Paygo plan online, and get 3 months for FREE access to Workable.

Signup for a free trial and select a Paygo plan to access this offer.  <strong><a href="https://get.workable.com/startup-network-trial?hsLang=en">Click here to avail</a></strong>

<br></br>

<strong><h3>Resources</h3></strong>
<ul>
  <li><a href="https://www.youtube.com/watch?v=Bg1n3EEsODs">Video: Workable, the world’s leading recruiting software</a></li>
  <li><a href="https://www.workable.com/pricing">Pricing Information</a></li>
  <li><a href="https://drive.google.com/file/d/1gjc6av3mzjMx3yTV1PFJrkYVmhW0n20A/view?usp=sharing">One Pager — Product Overview</a></li>
  <li><a href="https://drive.google.com/file/d/1kLI04oKyxflCOEUdR2zoIWoD398WLr8s/view?usp=sharing">One Pager — Feature Pricing</a></li>
</ul>
</div>
`;

const SnaphuntContent = `
    <div>
        <h2>
            <a href="https://snaphunt.com/">Snaphunt</a> is Asia's fastest growing remote hiring platform and its founder, <a href="https://www.linkedin.com/in/tulika-tripathi/?originalSubdomain=sg">Tulika</a>, is a member of our community!
        </h2>
        
        <br></br>
        
        <p>
            She has helped us create a platform to help our community members recruit talent at scale. If you are interested in conducting targeted hiring for talent, please follow the simple process below. Thank you Snaphunt and Tulika!
        </p>
        
        <br></br>

        <ol>
            <li>Send details of all your open role(s) to beenext@snaphunt.com and cc chinmaya@beenext.com</li>
            <li>The Snaphunt team will contact you to understand your requirements, create your role on the BEENEXT Snaphunt Account, and invite you or your team member to access applications for your role(s) via a dedicated log-in (Please note, each startup can only receive one login)</li>
            <li>Your company details and role(s) will be advertised on the BEENEXT career page (http://beenext.snaphunt.com/) and will automatically be distributed across multiple channels (including free job boards), to draw the widest pool of applicants</li>
            <li>On your account, you can view and manage applications with a click, conduct video interviews, and get automated reference checks for applicants</li>
            <li>Each role will remain open for only 30 days so please ensure you login regularly and avail of the talent in this time. Should you need your role extended, please contact the Snaphunt team to assist you</li>
            <li>You can reach the BEENEXT account team at Snaphunt for support anytime via the in- product chat or via our dedicated [support email: beenext@snaphunt.com]</li>
        </ol>
    </div>
`;

const MicrosoftContent = `
    <div>
        <h1>
            Each startup can choose from either $25,000 in AWS Promotional Credit valid for 2 Years or $100,000 in AWS Promotional Credit for 1 year (one or the other). If you are interested, please follow the simple process below.
            <br></br>
        </h1>
        <ol>
            <li>Access https://aws.amazon.com/activate/portfolioplus-signup/ </li>
            <li>Input AWS account details and BEENEXT's specific code (Activate Organization ID: 16zLN)</li>
            <li>Questions can be sent to AWS-Activate@amazon.com (http://AWS-Activate@amazon.com/)</li>
        </ol>
        <br></br>
        <h1>Please take a look at the below presentation for details and terms and conditions (also highlighted in this toggle)</h1>
        <br></br>
        <p>Details and T&C:</p>
        <ul>
            <li>$25,000 in AWS Promotional Credit valid for 2 Years or $100,000 in AWS Promotional Credit for 1 year</li>
            <li>1 year of AWS Business Support (up to $10,000)</li>
            <li>80 credits for self-paced labs</li>
        </ul>
        <br></br>
        <h1>Terms and Conditions: The maximum eligibility value of AWS Promotional Credit over the lifetime of the receiving company is $100,000 and bound by the credit expiration date. The $100,000 for 1 year option is limited to startups who have raised no more than one round of capital. The effective start date used to calculate credit terms is based on the date you became associated with your accelerator, incubator, Seed/VC Fund, or other startup-enabling organization.</h1>
        <br></br>
        <a href="https://s3.us-west-2.amazonaws.com/secure.notion-static.com/6fe77b52-f672-4270-9663-3467b74ad12b/AWS_Activate_Package_for_Beenext.pdf?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Credential=AKIAT73L2G45EIPT3X45%2F20220307%2Fus-west-2%2Fs3%2Faws4_request&X-Amz-Date=20220307T033828Z&X-Amz-Expires=86400&X-Amz-Signature=ae51956a1bb8380c4d7b0ee4a59504c048bbbfce3f6669928dffd5821e94c4e7&X-Amz-SignedHeaders=host&response-content-disposition=filename%20%3D%22AWS%2520Activate%2520Package%2520for%2520Beenext.pdf%22&x-id=GetObject" style="color: blue;">Click here!</a>
    </div>
`;

const AmazonContent = `
    <div>
        <h1>
            BEENEXT has a strategic partnership with Microsoft India and APAC. Our relationship has allowed us to work collaboratively with founders on specific use cases, stages, and scenarios. If you are an enterprise focused company and would like to explore further, please reach out for a conversation.
            <br></br>
            Please take a look at the below presentation for an overview of the engagement. To note, this presentation is confidential and only meant for sharing amongst the BEENEXT Founders' Community.
        </h1>
        <br>
        <p>
            <a href="https://docs.google.com/presentation/d/1xcL9qZUpd3-d_6cGxg22Lcma1dwOp2gv/edit?usp=sharing&ouid=107752805964176346998&rtpof=true&sd=true" style="color: blue;">Click here!</a>
        </p>
    </div>
`;

const GoogleContent = `
    <h1>Google Cloud is increasing their commitment to the startup ecosystem and has partnered with us in the below format: </h1>
    <br></br>
    <h1>Overview: Google Cloud for Startups is increasing their ongoing investments in the startup ecosystem—including in education, startup events, diversity and inclusion support, and other resources. They will provide a single avenue for startups to apply for benefits, which will simplify the process for qualifying for Cloud credits and allow them to better tailor benefits to startups across stages. They are also focused on building more holistic partnerships with startups based on the depth of their engagement with Google, and developing new offerings for startups to help build their business.</h1>
    <br></br>
    <h1>Process: Simply apply directly on cloud.google.com/startup</h1>
    <br></br>
    <h1>Benefits Offered: Once you apply via the site, you will get access to Google Cloud credits depending on evaluation by the Google team. The evaluation for the offer will be based on objective data such as funding raised and stage of growth, as well as the depth of engagement.</h1>
    <br></br>
    <h1>Questions: Please feel free to reach out to Google at cloud-startup-partners@google.com.</h1>
`;

const tc = `
    <h1>Tracxn is one of the world’s largest platforms for tracking startups and private companies spread across 300+ technology sectors and 800+ emerging themes, with dedicated coverage on 30+ countries. With Founder's Pass, one can have access to Tracxn for a period of 2 weeks, atleast. Please apply here.
    </h1>
    <br>
    <a href="https://forms.gle/Dps4tu9uYgyi4VvB8" style="color: blue;">Click here to apply!</a>
    <br></br>
    <h1>PS: Only founders from 20 companies can have access simultaneously, hence it's offered on a first cum first serve basis. You may apply for the access again.</h1>
    <a href="https://s3.us-west-2.amazonaws.com/secure.notion-static.com/34b544d2-1729-46cb-b2f2-1350f0306984/Founders_Corner_-_Guide.pdf?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Credential=AKIAT73L2G45EIPT3X45%2F20220307%2Fus-west-2%2Fs3%2Faws4_request&X-Amz-Date=20220307T034635Z&X-Amz-Expires=86400&X-Amz-Signature=dd036028975399900239487c881c3db4fa9992adfe0ca4c0ab4629d8c8be30a4&X-Amz-SignedHeaders=host&response-content-disposition=filename%20%3D%22Founders%27%2520Corner%2520-%2520Guide.pdf%22&x-id=GetObject" style="color: blue;">Learn more!</a>
`;

const Fresh = `
    <h1>
    For those of you who are unfamiliar with the company, Freshworks is a leading maker of cloud-based customer and employee engagement software based in San Mateo, California. The company's cloud-based suite is widely used by over 150,000 businesses around the world including the NHS, Honda, Rightmove, Hugo Boss, Citizens Advice, Toshiba, and Cisco.
    <br>
    In partnership with Freshworks, $10,000 worth of credits (in the below Freshworks' suite of products) is available to founders:
    </h1>
    <br>
    <ul>
        <li>Freshsales - Sales CRM Software</li>
        <li>Freshdesk - Customer Support Software</li>
        <li>Freshchat - Customer Messaging Software</li>
        <li>Freshrelease - Agile Project Management Software</li>
        <li>Freshcaller - Cloud Telephone Software</li>
        <li>Freshmarketer - Marketing Automation Software</li>
        <li>Freshteam - HR Management Software</li>
        <li>Freshservice - IT Service Management Software</li>
        <li>White-Glove Onboarding - Includes functional training around sales, marketing, and customer support</li>
        <li>Resources & Mentorship (launching soon) - Startup focused resources and mentorship on Freshworks' areas of expertise. Access to virtual or physical office hours with functional leaders from Freshworks and its extended mentor network</li>
    </ul>
    <br></br>
    <h1>How to Redeem:</h1>
    <ol>
        <li>Register for the offer at freshworks.com/partners/startup-program/bee-next</li>
        <li>Select the products that you would like to avail the offer for.</li>
        <li>Drop a note to startup@freshworks.com if you have any further queries related to the program</li>
    </ol>
`;

const hub = `
    <h1>
        We’re excited to partner with HubSpot for Startups to offer their program designed specifically to help startups grow and scale better, and faster - at a startup-friendly cost! Read on to learn more about the program.
    <br></br>
        <strong>What is the HubSpot for Startups program?</strong>
    </h1>
    <ul>
        <li>EDUCATION RESOURCES AND TAILORED TRAINING | Content, masterclasses, and 24/7 support to get your startup growing. From board deck templates to quick tips on customer acquisition, this content is made just for you. Plus, you’ll have access to world-class support from HubSpot experts and a startup-friendly onboarding experience.</li>
        <li>PROFESSIONAL SOFTWARE, STARTUP PRICING | Access to HubSpot Growth Platform, a full suite of software for marketing, sales, and customer service, with a completely free CRM at its core at a startup-friendly price, to help you grow and scale better. The software grows as you do, so you have access to in-person product training, too. All of this is up to 90% off. [Learn more about the software here.]</li>
        <li>INTEGRATED PLATFORM FOR STARTUPS | Access to over 500+ software integrations, many at startup-friendly pricing, as well as the opportunity to build on the HubSpot platform.</li>
    </ul>
    <br></br>
    <h1>Who is eligible?</h1>
    <h1>Any startup that is a current participant or alumni of BEENEXT and meets certain funding criteria is eligible for this exclusive program!  See below for more details:
    <br>
    If you are...</h1>
    <br>
    <ol>
        <li>A startup with under $2 million in funding | You are eligible for up to 90% off HubSpot software in your first year, 50% off in your second, and 25% off ongoing.</li>
        <li>A startup who has raised over $2 million in named funding up to and including Series A | You are eligible for up to 50% off in your first year, and 25% off ongoing.</li>
        <li>HubSpot for Startups pricing is applicable to net-new Professional or Enterprise level products. Starter level products are excluded, unless bundled with qualifying Professional or Enterprise purchases or upgrades. Customers may not apply the startup program pricing to existing subscriptions of any level.</li>
    </ol>
    <br></br>
    <h1>How to redeem benefits?</h1>
    <h1>To be instantly accepted, apply using our BEENEXT's unique referral link: [https://app.hubspot.com/signup/hubspot-for-startups?partner-code=W4IXCoKyKK8l](https://app.hubspot.com/signup/hubspot-for-startups?partner-code=W4IXCoKyKK8l)</h1>
`;

const stripeContent = `
    <h1>
        Whether you’re creating a software subscription service, a marketplace, or an e-
        commerce store, Stripe’s integrated payments platform helps you build and scale your business online. If you’re ready to start processing, click to redeem your discount. Or, if you want to chat to a member of the sales team, fill out your details on the form!
    <br></br>
     <a href="https://stripe.com/en-in/contact/startup-offer?code=strxsgbnxt" style="color: blue;">Click here to apply!</a><BR><BR>
     <a href="https://s3.us-west-2.amazonaws.com/secure.notion-static.com/66c33e82-64fa-4a36-8d28-7b16ae8e28d0/Partner_Perks_Beenext____Stripe.pdf?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Credential=AKIAT73L2G45EIPT3X45%2F20220307%2Fus-west-2%2Fs3%2Faws4_request&X-Amz-Date=20220307T113217Z&X-Amz-Expires=86400&X-Amz-Signature=d4e7508a3c6cf4c60284ae7bd3e09e6440912774af92f8f461eb71dfe5b917f9&X-Amz-SignedHeaders=host&response-content-disposition=filename%20%3D%22%255BPartner%2520Perks%255D%2520Beenext%2520__%2520Stripe.pdf%22&x-id=GetObject" style="color: blue;">Learn More!</a>
    </h1>
`;

const webEngage = `
    <h1>
    WebEngage is a 360 Marketing Automation Platform that helps founders create hyper personalized and contextual cross channel user engagement campaigns and retain users for life. For details on the program, please refer to the attached presentation and then apply here: 
    <a href="https://webengage.com/startups" style="color: blue;">https://webengage.com/startups</a><BR><BR>
    <br></br>
     <a href="https://s3.us-west-2.amazonaws.com/secure.notion-static.com/f7ee1fa3-470e-4a6e-9f26-8a4716ae4106/WebEngage_Startup_Program_Beenext_%283%29.pdf?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Credential=AKIAT73L2G45EIPT3X45%2F20220307%2Fus-west-2%2Fs3%2Faws4_request&X-Amz-Date=20220307T113438Z&X-Amz-Expires=86400&X-Amz-Signature=522ffc7eabe0d29a267a4044cbb5767c1a3ab4b82d11463ddc49f94b4cefe57b&X-Amz-SignedHeaders=host&response-content-disposition=filename%20%3D%22WebEngage%2520Startup%2520Program_Beenext%2520%283%29.pdf%22&x-id=GetObject" style="color: blue;">Learn More!</a>
    </h1>
`;

const retoolc = `
    <h1>
    Retool is a low-code platform that makes it fast and easy to build internal tools. Business teams need custom apps, dashboards, admin panels, and other internal tools to run critical operations. Rather than build from scratch, developers can use Retool to build powerful tools, faster. Our app builder includes drag-and-drop building blocks (e.g. components for tables, buttons, forms, charts) so you can assemble an app in minutes. From there, you can write custom code, connect to any data source, and build custom logic and queries to create exactly the right tools for your business. Thousands of companies like Doordash, Pinterest, Coinbase, and Amazon use Retool to build internal tools that drive faster operations and better business outcomes.
    <br></br>
<strong>Retool for Startups Offer Details for BEENEXT Startups</strong>
<br>
<ul>
    <li>$1,200 credits for Retool Cloud version ([Startup or Pro plan](https://retool.com/pricing/))</li>
    <li>$160k in discounts,perks and credits in the Retool for Startups Deal Book (AWS, Mongo, Intercom, Amplitude, and more)</li>
    <li>Office hours with experts on our team</li>
    <li>Get shout outs in Retool webinars and events</li>
</ul>
</h1>
<br></br><br>
<strong>How to redeem the offer</strong>
<a href="https://retool.typeform.com/to/qGcaOOHW#partnercode=beenext1k&partnername=beenext" style="color: blue;">Apply Here!</a>
<br></br>
<h1>
    <strong>Partner Code(optional):</strong> beenext1k
</h1>
<br></br>
<h1>
In order to qualify for Retool Startup Perks, a startup must be:
</h1>

<ul>
    <li>a new Retool signup</li>
    <li>founded within last 5years</li>
    <li>less than $10m in funding</li>
</ul>

<br></br>
<h1><strong>Resources:</strong></h1>

<h1>Here are some resources that can help you get started on Retool:
<br><br>
1. [Demo](https://www.youtube.com/watch?v=8ZrJtnCKlxk&)<br><br>
2. [Reschool](https://docs.retool.com/docs/reschool) - your one-stop shop for learning the basics of Retool<br><br>
3. Lightning Demos ( walkthroughs of real apps teams have built)<br><br>
    1. [Fintech Lighting Demos w/ Brex, Ramp, Commonbond](https://www.youtube.com/watch?v=aj34DJgE6SI&&t=3s)<br><br>
    2. [DoorDash Lightning Demo](https://www.youtube.com/watch?v=Gmxj1KKBeIk&list=PLqWdQFDVLADmkOrHQ-x-YPjma_Dzlvrq5&index=4&ab_channel=Retool)<br><br>
    3. [Cousera's Lighing Demo](https://www.youtube.com/watch?v=Q11vLV4-e24&list=PLqWdQFDVLADmkOrHQ-x-YPjma_Dzlvrq5&index=20&ab_channel=Retool) - from spreadsheets to applications<br><br>
    4. [LBB Lightning Demo](https://www.youtube.com/watch?v=hAL1ex3s5r8&list=PLqWdQFDVLADmkOrHQ-x-YPjma_Dzlvrq5&index=3&ab_channel=Retool) - Why Retool replaced LBB’s off-the-shelf visual merchandising tool<br><br>
    5. [Pepper Content Lightning Demo -](https://www.youtube.com/watch?v=j0KIGMhwtkw&list=PLqWdQFDVLADmkOrHQ-x-YPjma_Dzlvrq5&index=2&ab_channel=Retool)  How Pepper is using Retool to onboard 45k+ creators<br><br>
4. [Customers](https://retool.com/customers/)<br><br>
5. [Integrations](https://retool.com/integrations/)<br><br>
6. [Templates](https://retool.com/templates/)<br><br>
    </h1>
`;
