import {
  Flex,
  Heading,
  Image,
  FormControl,
  FormLabel,
  Input,
  Text,
  Checkbox,
  Button,
} from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { useRef, useContext } from "react";
import swal from "sweetalert";

import onboardingImage from "../../resources/imgs/onboarding.png";
import beenextLogo from "../../resources/imgs/beenextLogo.png";

import AuthContext from "../../store/auth";

const Login = () => {
  const authCtx = useContext(AuthContext);
  const emailInputRef = useRef();
  const passwordInputRef = useRef();

  function loginHandler() {
    const enteredEmail = emailInputRef.current.value;
    const enteredPassword = passwordInputRef.current.value;

    if (enteredEmail && enteredPassword) {
      fetch("https://beenext.orangeyak.xyz/api/users/login/", {
        method: "POST",
        body: JSON.stringify({
          email: enteredEmail,
          password: enteredPassword,
        }),
        headers: {
          "Content-Type": "application/json",
        },
      }).then((response) =>
        response
          .json()
          .then((data) => ({
            data: data,
            status: response.status,
          }))
          .then((res) => {
            if (res.data.status) {
              console.log(res.data);
              if (res.data.onboarding_complete) {
                authCtx.login(res.data.key);
                console.log("/dashboard");
              } else {
                authCtx.login(res.data.key);
                window.location = "/onboardinga";
              }
            } else {
              swal("Login failed.", res.data.detail, "error");
            }
          })
      );
    }
  }

  return (
    <div>
      {/* <Navbar /> */}
      <Flex
        flexDir={[
          "column-reverse",
          "column-reverse",
          "column-reverse",
          "row",
          "row",
          "row",
        ]}
        width="100vw"
        height={["100%", "100%", "100%", "100vh", "100vh"]}
        overflow="hidden"
        alignItems="center"
      >
        <Flex
          flexDir="column"
          width={["95vw", "95vw", "95vw", "60vw", "60vw"]}
          alignItems="center"
          justifyContent="center"
          height="100%"
        >
          <Flex
            flexDir="column"
            width={["90%", "90%", "90%", "600px", "600px"]}
            mt={["50px", "50px", "50px", "0px", "0px"]}
            mb={["50px", "50px", "50px", "0px", "0px"]}
          >
            <Image src={beenextLogo} height="12px" width="120px" />

            <Heading fontSize="28px" color="#EBB435" fontFamily="fonts.primary">
              Founder's Hub
            </Heading>

            <Heading
              fontSize="28px"
              color="#505058"
              fontFamily="fonts.primary"
              mt="40px"
            >
              Login
            </Heading>

            <Text
              fontSize="18px"
              color="#A0AEC0"
              fontFamily="fonts.primary"
              mt="0px"
              fontWeight="700"
            >
              Welcome back to the one-stop platform for all our BEENEXT
              Founders.
            </Text>

            <FormControl mt="20px">
              <FormLabel color="#505058" fontWeight="700" htmlFor="email">
                Email address*
              </FormLabel>
              <Input
                id="email"
                bg="#F9F9F9"
                color="#838383"
                type="email"
                fontWeight="700"
                focusBorderColor="#EBB435"
                border="none"
                placeholder="founder@beenext.com"
                autocomplete="off"
                ref={emailInputRef}
              />
            </FormControl>

            <FormControl mt="10px">
              <FormLabel color="#505058" fontWeight="700" htmlFor="email">
                Password*
              </FormLabel>
              <Input
                id="password"
                bg="#F9F9F9"
                color="#838383"
                focusBorderColor="#EBB435"
                type="password"
                fontWeight="700"
                border="none"
                placeholder="Minimum 6 characters"
                autocomplete="off"
                ref={passwordInputRef}
              />
            </FormControl>

            <Flex
              flexDir="row"
              justifyContent="space-between"
              alignItems="center"
              mt="25px"
              mb={["50px", "50px", "50px", "0px", "0px"]}
            >
              <Checkbox
                defaultIsChecked
                size="md"
                colorScheme="yellow"
                fontWeight="600"
                color="#505058"
                borderRadius="10px"
              >
                Remember me
              </Checkbox>

              <Link to="/forgot-password">
                <Text
                  fontSize="16px"
                  color="#EBB435"
                  fontWeight="700"
                  fontFamily="fonts.primary"
                >
                  Forgot your password?
                </Text>
              </Link>
            </Flex>

            <Button
              bg="#EBB435"
              width="300px"
              p="2px"
              mt={["0px", "0px", "60px", "60px"]}
              mb={["20px", "20px", "0px", "0px"]}
              color="white"
              fontSize="16px"
              onClick={loginHandler}
              _hover={{
                background: "#F2DBA6",
              }}
            >
              Login to your Founder Dashboard
            </Button>
          </Flex>
        </Flex>

        <Flex
          flexDir="column"
          width={["100vw", "100vw", "100vw", "45vw", "45vw"]}
          height="100%"
        >
          <Image
            src={onboardingImage}
            objectFit="cover"
            height={["200px", "200px", "200px", "100%", "100%"]}
            width="100%"
          />
        </Flex>
      </Flex>
    </div>
  );
};

export default Login;
