import { Flex, Box, Text } from "@chakra-ui/react";
import { NavLink } from "react-router-dom";

const EventItem = (props) => {
  return (
    <NavLink to="/events">
      <Flex flexDir="row" alignItems="center" width="100%" mt="12px" mb="0px">
        <Box
          width="50px"
          height="50px"
          display="flex"
          bg="#FFF2D2"
          borderRadius="10px"
          mr="15px"
        >
          <Text margin="auto" color="#EBB435" fontWeight="700" fontSize="28px">
            {props.date}
          </Text>
        </Box>

        <Flex flexDir="column">
          <Text
            fontSize="16px"
            lineHeight="1.1"
            color="#505058"
            fontWeight="700"
            mt="5px"
          >
            {props.title}
          </Text>
          <Text
            fontSize="13px"
            lineHeight="1.1"
            color="#A0AEC0"
            fontWeight="700"
            mt="5px"
            mb="5px"
          >
            {props.dateTime}
          </Text>
        </Flex>
      </Flex>
    </NavLink>
  );
};

export default EventItem;
