import { Flex, Image, Text } from "@chakra-ui/react";
import fallbackProfile from "../resources/imgs/profile.png";

const generateFounderPath = (id) => {
  let path = "/founder/";
  return path + id;
};

const FounderCard = (props) => {
  return (
    <Flex
      m={["5px", "5px", "10px", "10px", "10px"]}
      mt={["15px", "15px", "0px", "0px", "0px"]}
      bg="white"
      borderRadius="20px"
      p="10px"
      pt="15px"
      pb="15px"
      width="100%"
      maxWidth="420px"
      alignItems="center"
      flexDir="row"
      as="a"
      href={generateFounderPath(props.founderId)}
      cursor="pointer"
    >
      <Image
        boxSize="70px"
        borderRadius="100%"
        ml="20px"
        src={props.imgSrc}
        objectFit="cover"
        fallbackSrc={fallbackProfile}
      />
      <Flex flexDir="column" ml="40px" mr="20px">
        <Text
          color="#4A5568"
          lineHeight="1"
          fontWeight="700"
          mb="2px"
          fontSize="20px"
        >
          {props.name}
        </Text>

        <Text
          color="deepYellow"
          lineHeight="1.2"
          fontWeight="700"
          fontSize="16px"
        >
          {props.designation} {props.company ? <>at {props.company}</> : null}
        </Text>
      </Flex>
    </Flex>
  );
};

export default FounderCard;
