import {
  Flex,
  Heading,
  Image,
  FormControl,
  FormLabel,
  Input,
  Text,
  Button,
  FormHelperText,
} from "@chakra-ui/react";
import { useContext, useState, useRef } from "react";
import swal from "sweetalert";

import onboardingImage from "../../resources/imgs/onboarding.png";
import beenextLogo from "../../resources/imgs/beenextLogo.png";
import AuthContext from "../../store/auth";

const ChangePassword = () => {
  const new1 = useRef();
  const new2 = useRef();

  const [newHelp, setNewHelp] = useState(
    "Password must be minimum 6 characters."
  );
  const [newHelp2, setNewHelp2] = useState(
    "Password must be minimum 6 characters."
  );

  const authCtx = useContext(AuthContext);

  function changePass() {
    const new1Val = new1.current.value;
    const new2Val = new2.current.value;

    if (new1Val && new2Val) {
      fetch("https://beenext.orangeyak.xyz/rest-auth/password/change/", {
        method: "POST",
        body: JSON.stringify({
          new_password1: new1Val,
          new_password2: new2Val,
        }),
        headers: {
          "Content-Type": "application/json",
          Authorization: "Token " + authCtx.token,
        },
      }).then((response) =>
        response
          .json()
          .then((data) => ({
            data: data,
            status: response.status,
          }))
          .then((res) => {
            if (res.status < 300) {
              swal(
                "Password changed successfully!",
                "You can continue to use the Founder's Hub.",
                "success"
              );
              authCtx.logout();
            } else {
              if (res.data.new_password1) setNewHelp(res.data.new_password1);
              if (res.data.new_password2) setNewHelp2(res.data.new_password2);
            }
          })
      );
    }
  }

  return (
    <div>
      {/* <Navbar /> */}
      <Flex
        flexDir={[
          "column-reverse",
          "column-reverse",
          "column-reverse",
          "row",
          "row",
        ]}
        width="100vw"
        height={["100%", "100%", "100%", "100vh", "100vh"]}
        overflow="hidden"
        alignItems="center"
      >
        <Flex
          flexDir="column"
          width={["95vw", "95vw", "95vw", "60vw", "60vw"]}
          alignItems="center"
          justifyContent="center"
          height="100%"
        >
          <Flex
            flexDir="column"
            width={["90%", "90%", "85%", "85%", "85%"]}
            mt={["50px", "50px", "50px", "0px", "0px"]}
            mb={["50px", "50px", "50px", "0px", "0px"]}
          >
            <Image src={beenextLogo} height="12px" width="120px" />

            <Heading fontSize="28px" color="#EBB435" fontFamily="fonts.primary">
              Founder's Hub
            </Heading>

            <Heading
              fontSize="28px"
              color="#505058"
              fontFamily="fonts.primary"
              mt="40px"
            >
              Change my Password
            </Heading>

            <Text
              fontSize="18px"
              color="#A0AEC0"
              fontFamily="fonts.primary"
              mt="0px"
              fontWeight="700"
            >
              Please follow the steps below to change your password.
            </Text>

            <FormControl mt="20px">
              <FormLabel color="#505058" fontWeight="700" htmlFor="email">
                New Password*
              </FormLabel>
              <Input
                id="new1"
                bg="#F9F9F9"
                color="#838383"
                type="password"
                fontWeight="700"
                focusBorderColor="#EBB435"
                border="none"
                placeholder="Enter your new password"
                autocomplete="off"
                ref={new1}
              />
              <FormHelperText>{newHelp}</FormHelperText>
            </FormControl>

            <FormControl mt="20px">
              <FormLabel color="#505058" fontWeight="700" htmlFor="email">
                Confirm new Password*
              </FormLabel>
              <Input
                id="new2"
                bg="#F9F9F9"
                color="#838383"
                type="password"
                fontWeight="700"
                focusBorderColor="#EBB435"
                border="none"
                placeholder="Confirm your new password"
                autocomplete="off"
                ref={new2}
              />
              <FormHelperText>{newHelp2}</FormHelperText>
            </FormControl>

            <Flex flexDir="row" mt="30px" flexWrap="wrap">
              <Button
                bg="#EBB435"
                width="200px"
                p="2px"
                mt={["0px", "0px", "0px", "60px", "60px"]}
                mb={["20px", "20px", "20px", "0px", "0px"]}
                color="white"
                fontSize="16px"
                onClick={changePass}
                _hover={{
                  background: "#F2DBA6",
                }}
                mr="10px"
              >
                Change my Password
              </Button>

              <Button
                variant="outline"
                colorScheme="gray"
                width="180px"
                p="2px"
                mt={["0px", "0px", "0px", "60px", "60px"]}
                mb={["20px", "20px", "20px", "0px", "0px"]}
                fontSize="16px"
                as="a"
                href="/"
              >
                Back to Dashboard
              </Button>
            </Flex>
          </Flex>
        </Flex>

        <Flex
          flexDir="column"
          width={["100vw", "100vw", "100vw", "45vw", "45vw"]}
          height="100%"
        >
          <Image
            src={onboardingImage}
            objectFit="cover"
            height={["200px", "200px", "200px", "100%", "100%"]}
            width="100%"
          />
        </Flex>
      </Flex>
    </div>
  );
};

export default ChangePassword;
