import {
  Box,
  Flex,
  Input,
  Text,
  InputGroup,
  InputLeftElement,
  Checkbox,
  CheckboxGroup,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Spinner,
} from "@chakra-ui/react";
import swal from "sweetalert";

import { useState, useContext, useEffect } from "react";
import { convertFiltersToRequest } from "../store/functions";

import { Search2Icon } from "@chakra-ui/icons";

import FounderCard from "../components/FounderCard";
import Sidebar from "../components/Sidebar";
import AuthContext from "../store/auth";

const Founders = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const authCtx = useContext(AuthContext);
  const [founders, setFounders] = useState(null);

  const [regionFilter, setRegionFilter] = useState();
  const [industryFilter, setIndustryFilter] = useState();

  const [search, setSearch] = useState("");
  const searchFor = (event) => setSearch(event.target.value);

  const updateFilters = () => {
    fetch(
      "https://beenext.orangeyak.xyz/api/resources/founders/?" +
        convertFiltersToRequest("industry", industryFilter) +
        "&" +
        convertFiltersToRequest("region", regionFilter),
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Token " + authCtx.token,
        },
      }
    ).then((response) =>
      response
        .json()
        .then((data) => ({
          data: data,
          status: response.status,
        }))
        .then((res) => {
          if (res.status < 300) {
            setFounders(res.data.results);
          } else {
            swal("Oops, something went wrong!", res.data.detail, "error");
          }
        })
    );
  };

  const searchFilters = () => {
    fetch(
      "https://beenext.orangeyak.xyz/api/resources/founders/?search=" + search,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Token " + authCtx.token,
        },
      }
    ).then((response) =>
      response
        .json()
        .then((data) => ({
          data: data,
          status: response.status,
        }))
        .then((res) => {
          if (res.status < 300) {
            setFounders(res.data.results);
          } else {
            swal("Oops, something went wrong!", res.data.detail, "error");
          }
        })
    );
  };

  const getFounders = () => {
    fetch("https://beenext.orangeyak.xyz/api/resources/founders/?limit=1000", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Token " + authCtx.token,
      },
    }).then((response) =>
      response
        .json()
        .then((data) => ({
          data: data,
          status: response.status,
        }))
        .then((res) => {
          if (res.status < 300) {
            setFounders(res.data.results);
          } else {
            swal("Oops, something went wrong!", res.data.detail, "error");
          }
        })
    );
  };

  useEffect(() => {
    updateFilters();
    // eslint-disable-next-line
  }, [regionFilter, industryFilter]);

  useEffect(() => {
    searchFilters();
    // eslint-disable-next-line
  }, [search]);

  useEffect(() => {
    getFounders();
    // eslint-disable-next-line
  }, []);

  return (
    <Box
      bg="#EFEFEF"
      height="100vh"
      width="100vw"
      display="flex"
      flexDir="row"
      overflow="hidden"
    >
      {/* SIDEBAR SECTION */}
      <Sidebar />
      {/* END OF SIDEBAR */}

      {/* MASTER FEED WRAPPER SECTION */}
      <Flex
        width={["99%", "99%", "100%", "100%", "100%"]}
        flexDir="column"
        alignItems="center"
        height="95%"
        ml={["10px", "10px", "320px", "320px", "320px"]}
        mr="10px"
        mt={["60px", "60px", "20px", "20px", "20px"]}
        overflowX="hidden"
        overflowY="scroll"
      >
        {/* TOPBAR SECTION */}
        <Flex
          bg="white"
          width="99%"
          borderRadius="20px"
          ml="-10px"
          pt="20px"
          pb="20px"
          flexDir="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Flex maxWidth="600px">
            <Flex flexDir="column" ml="30px">
              <Text fontSize="22px" fontWeight="700" color="#505058">
                FOUNDER DIRECTORY
              </Text>
              <Text
                fontSize="16px"
                lineHeight="120%"
                fontWeight="500"
                color="#A0AEC0"
              >
                This is the core of BEENEXT Founder community. Browse and update
                the directory here and make new connections. This is all about
                our community together!
              </Text>
            </Flex>
          </Flex>

          {/* <GlobalSearchBar /> */}
        </Flex>
        {/* END OF TOPBAR SECTION */}

        <Flex bg="white" width="99%" borderRadius="200px" mt="10px">
          <InputGroup width="100%">
            <InputLeftElement
              pt="5px"
              children={<Search2Icon color="gray.300" />}
            />
            <Input
              pr="10px"
              borderRadius="20px"
              placeholder="Search for founders"
              color="#A0AEC0"
              focusBorderColor="#EBB435"
              fontWeight="700"
              border="none"
              autocomplete="off"
              onChange={searchFor}
            />
          </InputGroup>
        </Flex>

        <Flex
          flexDir={["column", "column", "row", "row", "row"]}
          width="99%"
          mt="20px"
          justifyContent="space-between"
        >
          <Flex
            width="250px"
            display={["none", "none", "flex", "flex", "flex"]}
            flexDir="column"
          >
            {/* <Button bg="deepYellow" color="white" mb='10px' onClick={updateFilters}>SEARCH</Button> */}

            <Text
              color="lightGray"
              fontWeight="700"
              textTransform="uppercase"
              fontSize="16px"
            >
              REGION
            </Text>

            <CheckboxGroup onChange={setRegionFilter} value={regionFilter}>
              <Checkbox
                colorScheme="yellow"
                defaultIsChecked
                value="Singapore"
                fontWeight="700"
                br="10px"
                ml="2px"
                mb="2px"
                color="darkGray"
              >
                Singapore
              </Checkbox>

              <Checkbox
                colorScheme="yellow"
                defaultIsChecked
                value="SEA"
                fontWeight="700"
                br="10px"
                ml="2px"
                mb="2px"
                color="darkGray"
              >
                SEA
              </Checkbox>

              <Checkbox
                colorScheme="yellow"
                defaultIsChecked
                value="India"
                fontWeight="700"
                br="10px"
                ml="2px"
                mb="2px"
                color="darkGray"
              >
                India
              </Checkbox>

              <Checkbox
                colorScheme="yellow"
                defaultIsChecked
                value="Nigeria"
                fontWeight="700"
                br="10px"
                ml="2px"
                mb="2px"
                color="darkGray"
              >
                Nigeria
              </Checkbox>

              <Checkbox
                colorScheme="yellow"
                defaultIsChecked
                value="Phillipines"
                fontWeight="700"
                br="10px"
                ml="2px"
                mb="2px"
                color="darkGray"
              >
                Phillipines
              </Checkbox>

              <Checkbox
                colorScheme="yellow"
                defaultIsChecked
                value="Myanmar"
                fontWeight="700"
                br="10px"
                ml="2px"
                mb="2px"
                color="darkGray"
              >
                Myanmar
              </Checkbox>

              <Checkbox
                colorScheme="yellow"
                defaultIsChecked
                value="Indonesia"
                fontWeight="700"
                br="10px"
                ml="2px"
                mb="2px"
                color="darkGray"
              >
                Indonesia
              </Checkbox>

              <Checkbox
                colorScheme="yellow"
                defaultIsChecked
                value="USA"
                fontWeight="700"
                br="10px"
                ml="2px"
                mb="2px"
                color="darkGray"
              >
                USA
              </Checkbox>

              <Checkbox
                colorScheme="yellow"
                defaultIsChecked
                value="Vietnam"
                fontWeight="700"
                br="10px"
                ml="2px"
                mb="2px"
                color="darkGray"
              >
                Vietnam
              </Checkbox>

              <Checkbox
                colorScheme="yellow"
                defaultIsChecked
                value="Turkey"
                fontWeight="700"
                br="10px"
                ml="2px"
                mb="2px"
                color="darkGray"
              >
                Turkey
              </Checkbox>

              <Checkbox
                colorScheme="yellow"
                defaultIsChecked
                value="Japan"
                fontWeight="700"
                br="10px"
                ml="2px"
                mb="2px"
                color="darkGray"
              >
                Japan
              </Checkbox>

              <Checkbox
                colorScheme="yellow"
                defaultIsChecked
                value="Africa"
                fontWeight="700"
                br="10px"
                ml="2px"
                mb="2px"
                color="darkGray"
              >
                Africa
              </Checkbox>

              <Checkbox
                colorScheme="yellow"
                defaultIsChecked
                value="Bangladesh"
                fontWeight="700"
                br="10px"
                ml="2px"
                mb="2px"
                color="darkGray"
              >
                Bangladesh
              </Checkbox>
            </CheckboxGroup>

            <Text
              color="lightGray"
              fontWeight="700"
              textTransform="uppercase"
              fontSize="16px"
              mt="20px"
            >
              INDUSTRY
            </Text>

            <CheckboxGroup onChange={setIndustryFilter} value={industryFilter}>
              <Checkbox
                colorScheme="yellow"
                defaultIsChecked
                value="Agritech"
                fontWeight="700"
                br="10px"
                ml="2px"
                mb="2px"
                color="darkGray"
              >
                Agritech
              </Checkbox>

              <Checkbox
                colorScheme="yellow"
                defaultIsChecked
                value="Fintech"
                fontWeight="700"
                br="10px"
                ml="2px"
                mb="2px"
                color="darkGray"
              >
                Fintech
              </Checkbox>

              <Checkbox
                colorScheme="yellow"
                defaultIsChecked
                value="Marketplace"
                fontWeight="700"
                br="10px"
                ml="2px"
                mb="2px"
                color="darkGray"
              >
                Marketplace
              </Checkbox>

              <Checkbox
                colorScheme="yellow"
                defaultIsChecked
                value="HR Tech"
                fontWeight="700"
                br="10px"
                ml="2px"
                mb="2px"
                color="darkGray"
              >
                HR Tech
              </Checkbox>

              <Checkbox
                colorScheme="yellow"
                defaultIsChecked
                value="Analytics"
                fontWeight="700"
                br="10px"
                ml="2px"
                mb="2px"
                color="darkGray"
              >
                Analytics
              </Checkbox>

              <Checkbox
                colorScheme="yellow"
                defaultIsChecked
                value="Healthtech"
                fontWeight="700"
                br="10px"
                ml="2px"
                mb="2px"
                color="darkGray"
              >
                Healthtech
              </Checkbox>

              <Checkbox
                colorScheme="yellow"
                defaultIsChecked
                value="Lifestyle"
                fontWeight="700"
                br="10px"
                ml="2px"
                mb="2px"
                color="darkGray"
              >
                Lifestyle
              </Checkbox>

              <Checkbox
                colorScheme="yellow"
                defaultIsChecked
                value="Enterprise SaaS"
                fontWeight="700"
                br="10px"
                ml="2px"
                mb="2px"
                color="darkGray"
              >
                Enterprise SaaS
              </Checkbox>

              <Checkbox
                colorScheme="yellow"
                defaultIsChecked
                value="IoT"
                fontWeight="700"
                br="10px"
                ml="2px"
                mb="2px"
                color="darkGray"
              >
                IoT
              </Checkbox>

              <Checkbox
                colorScheme="yellow"
                defaultIsChecked
                value="Logistics"
                fontWeight="700"
                br="10px"
                ml="2px"
                mb="2px"
                color="darkGray"
              >
                Logistics
              </Checkbox>
            </CheckboxGroup>
          </Flex>

          <Button
            onClick={onOpen}
            display={["flex", "flex", "none", "none", "none"]}
            width="100px"
            color="#EBB435"
            bg="#FFF2D2"
          >
            FILTERS
          </Button>

          <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>FILTERS</ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                <Text
                  color="lightGray"
                  fontWeight="700"
                  textTransform="uppercase"
                  fontSize="16px"
                >
                  REGION
                </Text>

                <CheckboxGroup onChange={setRegionFilter} value={regionFilter}>
                  <Checkbox
                    colorScheme="yellow"
                    defaultIsChecked
                    value="Singapore"
                    fontWeight="700"
                    br="10px"
                    ml="2px"
                    mb="2px"
                    color="darkGray"
                  >
                    Singapore
                  </Checkbox>

                  <Checkbox
                    colorScheme="yellow"
                    defaultIsChecked
                    value="SEA"
                    fontWeight="700"
                    br="10px"
                    ml="2px"
                    mb="2px"
                    color="darkGray"
                  >
                    SEA
                  </Checkbox>

                  <Checkbox
                    colorScheme="yellow"
                    defaultIsChecked
                    value="India"
                    fontWeight="700"
                    br="10px"
                    ml="2px"
                    mb="2px"
                    color="darkGray"
                  >
                    India
                  </Checkbox>

                  <Checkbox
                    colorScheme="yellow"
                    defaultIsChecked
                    value="Nigeria"
                    fontWeight="700"
                    br="10px"
                    ml="2px"
                    mb="2px"
                    color="darkGray"
                  >
                    Nigeria
                  </Checkbox>

                  <Checkbox
                    colorScheme="yellow"
                    defaultIsChecked
                    value="Phillipines"
                    fontWeight="700"
                    br="10px"
                    ml="2px"
                    mb="2px"
                    color="darkGray"
                  >
                    Phillipines
                  </Checkbox>

                  <Checkbox
                    colorScheme="yellow"
                    defaultIsChecked
                    value="Myanmar"
                    fontWeight="700"
                    br="10px"
                    ml="2px"
                    mb="2px"
                    color="darkGray"
                  >
                    Myanmar
                  </Checkbox>

                  <Checkbox
                    colorScheme="yellow"
                    defaultIsChecked
                    value="Indonesia"
                    fontWeight="700"
                    br="10px"
                    ml="2px"
                    mb="2px"
                    color="darkGray"
                  >
                    Indonesia
                  </Checkbox>

                  <Checkbox
                    colorScheme="yellow"
                    defaultIsChecked
                    value="USA"
                    fontWeight="700"
                    br="10px"
                    ml="2px"
                    mb="2px"
                    color="darkGray"
                  >
                    USA
                  </Checkbox>

                  <Checkbox
                    colorScheme="yellow"
                    defaultIsChecked
                    value="Vietnam"
                    fontWeight="700"
                    br="10px"
                    ml="2px"
                    mb="2px"
                    color="darkGray"
                  >
                    Vietnam
                  </Checkbox>

                  <Checkbox
                    colorScheme="yellow"
                    defaultIsChecked
                    value="Turkey"
                    fontWeight="700"
                    br="10px"
                    ml="2px"
                    mb="2px"
                    color="darkGray"
                  >
                    Turkey
                  </Checkbox>

                  <Checkbox
                    colorScheme="yellow"
                    defaultIsChecked
                    value="Japan"
                    fontWeight="700"
                    br="10px"
                    ml="2px"
                    mb="2px"
                    color="darkGray"
                  >
                    Japan
                  </Checkbox>

                  <Checkbox
                    colorScheme="yellow"
                    defaultIsChecked
                    value="Africa"
                    fontWeight="700"
                    br="10px"
                    ml="2px"
                    mb="2px"
                    color="darkGray"
                  >
                    Africa
                  </Checkbox>

                  <Checkbox
                    colorScheme="yellow"
                    defaultIsChecked
                    value="Bangladesh"
                    fontWeight="700"
                    br="10px"
                    ml="2px"
                    mb="2px"
                    color="darkGray"
                  >
                    Bangladesh
                  </Checkbox>
                </CheckboxGroup>

                <Text
                  color="lightGray"
                  fontWeight="700"
                  textTransform="uppercase"
                  fontSize="16px"
                  mt="20px"
                >
                  INDUSTRY
                </Text>

                <CheckboxGroup
                  onChange={setIndustryFilter}
                  value={industryFilter}
                >
                  <Checkbox
                    colorScheme="yellow"
                    defaultIsChecked
                    value="Agritech"
                    fontWeight="700"
                    br="10px"
                    ml="2px"
                    mb="2px"
                    color="darkGray"
                  >
                    Agritech
                  </Checkbox>

                  <Checkbox
                    colorScheme="yellow"
                    defaultIsChecked
                    value="Fintech"
                    fontWeight="700"
                    br="10px"
                    ml="2px"
                    mb="2px"
                    color="darkGray"
                  >
                    Fintech
                  </Checkbox>

                  <Checkbox
                    colorScheme="yellow"
                    defaultIsChecked
                    value="Marketplace"
                    fontWeight="700"
                    br="10px"
                    ml="2px"
                    mb="2px"
                    color="darkGray"
                  >
                    Marketplace
                  </Checkbox>

                  <Checkbox
                    colorScheme="yellow"
                    defaultIsChecked
                    value="HR Tech"
                    fontWeight="700"
                    br="10px"
                    ml="2px"
                    mb="2px"
                    color="darkGray"
                  >
                    HR Tech
                  </Checkbox>

                  <Checkbox
                    colorScheme="yellow"
                    defaultIsChecked
                    value="Analytics"
                    fontWeight="700"
                    br="10px"
                    ml="2px"
                    mb="2px"
                    color="darkGray"
                  >
                    Analytics
                  </Checkbox>

                  <Checkbox
                    colorScheme="yellow"
                    defaultIsChecked
                    value="Healthtech"
                    fontWeight="700"
                    br="10px"
                    ml="2px"
                    mb="2px"
                    color="darkGray"
                  >
                    Healthtech
                  </Checkbox>

                  <Checkbox
                    colorScheme="yellow"
                    defaultIsChecked
                    value="Lifestyle"
                    fontWeight="700"
                    br="10px"
                    ml="2px"
                    mb="2px"
                    color="darkGray"
                  >
                    Lifestyle
                  </Checkbox>

                  <Checkbox
                    colorScheme="yellow"
                    defaultIsChecked
                    value="Enterprise SaaS"
                    fontWeight="700"
                    br="10px"
                    ml="2px"
                    mb="2px"
                    color="darkGray"
                  >
                    Enterprise SaaS
                  </Checkbox>

                  <Checkbox
                    colorScheme="yellow"
                    defaultIsChecked
                    value="IoT"
                    fontWeight="700"
                    br="10px"
                    ml="2px"
                    mb="2px"
                    color="darkGray"
                  >
                    IoT
                  </Checkbox>

                  <Checkbox
                    colorScheme="yellow"
                    defaultIsChecked
                    value="Logistics"
                    fontWeight="700"
                    br="10px"
                    ml="2px"
                    mb="2px"
                    color="darkGray"
                  >
                    Logistics
                  </Checkbox>
                </CheckboxGroup>
              </ModalBody>

              <ModalFooter>
                {/* <Button
                                color="#EBB435"
                                bg="#FFF2D2"
                                mr={3} 
                                onClick={closeModal}
                            >
                            SEARCH
                            </Button> */}
              </ModalFooter>
            </ModalContent>
          </Modal>

          <Flex
            width="100%"
            height="fit-content"
            ml={["0px", "0px", "20px", "20px", "20px"]}
            flexDir="row"
            flexWrap="wrap"
          >
            {founders !== null ? (
              <>
                {founders.map((fnd, index) => (
                  <FounderCard
                    id={index}
                    founderId={fnd.founder_id}
                    imgSrc={fnd.avatar}
                    name={fnd.name}
                    designation={fnd.title}
                    company={fnd.firm.name}
                  />
                ))}
              </>
            ) : (
              <Spinner margin="auto" />
            )}
          </Flex>
        </Flex>
      </Flex>

      {/* END OF SCROLLABLE INTERNAL SECTION */}
    </Box>
  );
};

export default Founders;
