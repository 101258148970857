// react imports
import React, { useContext } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { Flex } from "@chakra-ui/react";

// screen imports
import Dashboard from "./screens/Dashboard";
import BEENEXTHQ from "./screens/BEENEXTHQ";
import Newsfeed from "./screens/Newsfeed";
import Portfolio from "./screens/Portfolio";
import Events from "./screens/Events";
import Founders from "./screens/Founders";
import AgencyList from "./screens/AgencyList";
import SubjectExperts from "./screens/SubjectExperts";
import Templates from "./screens/Templates";
import KnowledgeHub from "./screens/KnowledgeHub";
import Partnerships from "./screens/Partnerships";
import EventDetail from "./screens/EventDetail";
import Login from "./screens/Onboarding/Login";
import Error from "./screens/Error";
import ForgotPassword from "./screens/Onboarding/ForgotPassword";
import Register from "./screens/Onboarding/Register";
import FormA from "./screens/Onboarding/FormA";
import FormB from "./screens/Onboarding/FormB";
import FormC from "./screens/Onboarding/FormC";
import GlobalSearch from "./screens/GlobalSearch";

// resource imports
import "./index.css";
import AuthContext from "./store/auth";
import PrivacyPolicy from "./screens/Onboarding/PrivacyPolicy";
import Founder from "./screens/Founder";
import ForgotConfirm from "./screens/Onboarding/ForgotConfirm";
import ChangePassword from "./screens/Onboarding/ChangePassword";

const App = () => {
  const authCtx = useContext(AuthContext);
  const loginStatus = authCtx.isLoggedIn;

  return (
    <div>
      <Flex>
        <Routes>
          {/* DEFAULT INDEX ROUTE */}
          <Route index element={loginStatus ? <Dashboard /> : <Login />} />

          {/* FOUNDER'S HUB ROUTES */}
          <Route
            path="dashboard"
            element={loginStatus ? <Dashboard /> : <Navigate to="/" />}
          />
          <Route
            path="beenext-hq"
            element={loginStatus ? <BEENEXTHQ /> : <Navigate to="/" />}
          />
          <Route
            path="knowledge-hub"
            element={loginStatus ? <KnowledgeHub /> : <Navigate to="/" />}
          />
          <Route
            path="newsfeed"
            element={loginStatus ? <Newsfeed /> : <Navigate to="/" />}
          />
          <Route
            path="portfolio"
            element={loginStatus ? <Portfolio /> : <Navigate to="/" />}
          />

          <Route
            path="events/:eventSlug"
            element={loginStatus ? <EventDetail /> : <Navigate to="/" />}
          />

          <Route
            exact
            path="events"
            element={loginStatus ? <Events /> : <Navigate to="/" />}
          />

          <Route
            path="founders"
            element={loginStatus ? <Founders /> : <Navigate to="/" />}
          />
          <Route
            path="founder/:founderSlug"
            element={loginStatus ? <Founder /> : <Navigate to="/" />}
          />
          <Route
            path="agency-list"
            element={loginStatus ? <AgencyList /> : <Navigate to="/" />}
          />
          <Route
            path="subject-experts"
            element={loginStatus ? <SubjectExperts /> : <Navigate to="/" />}
          />
          <Route
            path="templates"
            element={loginStatus ? <Templates /> : <Navigate to="/" />}
          />
          <Route
            path="partnerships"
            element={loginStatus ? <Partnerships /> : <Navigate to="/" />}
          />

          <Route
            path="search"
            element={loginStatus ? <GlobalSearch /> : <Navigate to="/" />}
          />

          {/* LOGIN / AUTH ROUTES */}
          <Route path="register" element={<Register />} />
          <Route path="onboardinga" element={<FormA />} />
          <Route path="onboardingb" element={<FormB />} />
          <Route path="onboardingc" element={<FormC />} />
          <Route path="forgot-password" element={<ForgotPassword />} />
          <Route path="privacy-policy" element={<PrivacyPolicy />} />
          <Route
            path="forgot-confirm/:uid/:token"
            element={<ForgotConfirm />}
          />
          <Route
            path="change-password/"
            element={loginStatus ? <ChangePassword /> : <Navigate to="/" />}
          />

          {/* 404 ROUTE */}
          <Route path="*" element={<Error />} />
        </Routes>
      </Flex>
    </div>
  );
};

export default App;
