import {
  Flex,
  Heading,
  Image,
  FormControl,
  Input,
  Text,
  Textarea,
  Button,
  Checkbox,
  Box,
  Icon,
  InputRightElement,
  InputGroup,
} from "@chakra-ui/react";
import { useDropzone } from "react-dropzone";
import { useContext, useState, useEffect } from "react";
import S3FileUpload from "react-s3";
import { AiOutlineTwitter, AiFillLinkedin } from "react-icons/ai";
import swal from "sweetalert";

import onboardingImage from "../../resources/imgs/onboarding.png";
import line from "../../resources/imgs/line.png";
import uploadFile2 from "../../resources/imgs/uploadFile.png";

import AuthContext from "../../store/auth";

const FormB = (props) => {
  // AUTH CONTEXT
  const authCtx = useContext(AuthContext);

  // DEFAULT INFO STATES
  const [imageURL, setImageURL] = useState(null);
  const [name, setName] = useState("");
  const [title, setTitle] = useState("");
  const [bio, setBio] = useState("");
  const [whatsapp, setWhatsapp] = useState("");
  const [personalEmail, setPersonalEmail] = useState("");
  const [pTwitter, setPTwitter] = useState("");
  const [pLinkedin, setPLinkedin] = useState("");
  const [checkedWA, setCheckedWA] = useState(false);

  // DROPZONE LOGIC
  const { acceptedFiles, getRootProps, getInputProps } = useDropzone();

  const onFileChange = (file) => {
    const config = {
      bucketName: "beenext-assets",
      dirName: "media/public/uploads",
      region: "ap-south-1",
      accessKeyId: "AKIA2BUOWC5BCTQYGWWZ",
      secretAccessKey: "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",
    };
    S3FileUpload.uploadFile(file, config)
      .then((data) => {
        setImageURL(data.location);
        return data.location;
      })
      .catch((err) => {
        alert(err);
        return null;
      });
  };

  // onImageChange HANDLER FUNCTION PAIR
  const [initialRender, setInitialRender] = useState(true);
  useEffect(() => {
    if (initialRender) setInitialRender(false);
    else onFileChange(acceptedFiles[0]);
    // eslint-disable-next-line
  }, [acceptedFiles[0]]);

  // INITIAL USER DATA — GET REQUEST
  const getOnboardingDetails = () => {
    fetch("https://beenext.orangeyak.xyz/api/users/onboarding_page_2/", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Token " + authCtx.token,
      },
    }).then((response) =>
      response
        .json()
        .then((data) => ({
          data: data,
          status: response.status,
        }))
        .then((res) => {
          if (res.data.status) {
            setName(res.data.user.name);
            setTitle(res.data.user.title);
            setBio(res.data.user.bio);
            setWhatsapp(res.data.user.phone);
            setPersonalEmail(res.data.user.personal_email);
            setImageURL(decodeURI(res.data.user.avatar));
            setPLinkedin(res.data.user.linkedin);
            setPTwitter(res.data.user.twitter);
            setCheckedWA(res.data.user.share_my_contact_details);
          } else {
            swal("Something went wrong.", res.data.detail, "error");
          }
        })
    );
  };

  // USE EFFECT TO LOAD DATA ON FIRST PAGE LOAD
  useEffect(() => {
    getOnboardingDetails();
    // eslint-disable-next-line
  }, []);

  const setOnboardingDetails = () => {
    fetch("https://beenext.orangeyak.xyz/api/users/onboarding_page_2/", {
      method: "POST",
      body: JSON.stringify({
        avatar: encodeURI(imageURL),
        name: name,
        title: title,
        bio: bio,
        phone: whatsapp,
        personal_email: personalEmail,
        twitter: pTwitter,
        linkedin: pLinkedin,
        share_my_contact_details: checkedWA,
      }),
      headers: {
        "Content-Type": "application/json",
        Authorization: "Token " + authCtx.token,
      },
    }).then((response) =>
      response
        .json()
        .then((data) => ({
          data: data,
          status: response.status,
        }))
        .then((res) => {
          if (res.data.status) {
            window.location = "/onboardingc";
          } else {
            swal("Something went wrong.", res.data.detail, "error");
          }
        })
    );
  };

  return (
    <div>
      <Flex
        flexDir={[
          "column-reverse",
          "column-reverse",
          "column-reverse",
          "row",
          "row",
        ]}
        width="100vw"
        height={["100%", "100%", "100%", "100vh", "100vh"]}
        overflow="hidden"
        alignItems="center"
      >
        <Flex
          flexDir="column"
          width={["95vw", "95vw", "95vw", "60vw", "60vw"]}
          alignItems="center"
          justifyContent="center"
          height="100%"
        >
          <Flex
            flexDir="column"
            width={["90%", "90%", "90%", "85%", "85%"]}
            mt={["50px", "50px", "50px", "0px", "0px"]}
            mb={["50px", "50px", "50px", "0px", "0px"]}
          >
            <Heading
              fontSize="28px"
              color="#505058"
              fontFamily="fonts.primary"
              mt="40px"
            >
              Update your personal information.
            </Heading>

            <Text
              fontSize="18px"
              color="#A0AEC0"
              fontFamily="fonts.primary"
              mt="0px"
              fontWeight="700"
              lineHeight="1.2"
            >
              Please help us validate and fill the necessary information.
            </Text>

            <Flex
              flexDir="row"
              width="100%"
              justifyContent="space-between"
              marginTop="10px"
            >
              <Box
                width="32%"
                height="10px"
                background="#EBB435"
                borderRadius="50px"
              ></Box>

              <Box
                width="32%"
                height="10px"
                background="#EBB435"
                borderRadius="50px"
              ></Box>

              <Box
                width="32%"
                height="10px"
                background="#F9F9F9"
                borderRadius="50px"
              ></Box>
            </Flex>

            <Flex>
              <Box
                mt="40px"
                cursor="pointer"
                {...getRootProps({ className: "dropzone" })}
              >
                <Input {...getInputProps()} />
                <Flex flexDir="row" alignItems="center">
                  {imageURL ? (
                    <Image
                      src={imageURL}
                      objectFit="cover"
                      boxSize="70px"
                      borderRadius="100%"
                    />
                  ) : (
                    <Image src={uploadFile2} boxSize="70px" />
                  )}
                  <Flex flexDir="column" ml="20px">
                    <Text fontSize="20px" fontWeight="700" color="#838383">
                      Upload your profile picture.
                    </Text>
                    <Text
                      fontSize="16px"
                      fontWeight="700"
                      mt="0px"
                      lineHeight="1.2"
                      color="#A0AEC0"
                    >
                      Please upload a square, high resolution profile picture.
                    </Text>
                  </Flex>
                </Flex>
              </Box>
            </Flex>

            <Image
              mt="40px"
              src={line}
              objectFit="cover"
              width="100%"
              height="1px"
              opacity="30%"
              mb="20px"
            />

            <FormControl mt="0px">
              <Flex flexDir="row" mt="10px" justifyContent="space-between">
                <InputGroup width="49%" bg="#F9F9F9" borderRadius="10px">
                  <Input
                    id="email"
                    color="#838383"
                    type="text"
                    fontWeight="700"
                    background="#F9F9F9"
                    border="none"
                    placeholder="Founder Name"
                    focusBorderColor="#EBB435"
                    autocomplete="off"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                </InputGroup>

                <InputGroup width="49%" bg="#F9F9F9" borderRadius="10px">
                  <Input
                    id="email"
                    color="#838383"
                    type="email"
                    fontWeight="700"
                    background="#F9F9F9"
                    border="none"
                    placeholder="Title"
                    focusBorderColor="#EBB435"
                    autocomplete="off"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                  />
                </InputGroup>
              </Flex>

              <InputGroup bg="#F9F9F9" mt="15px" borderRadius="10px">
                <Input
                  id="phone"
                  color="#838383"
                  type="phone"
                  fontWeight="700"
                  border="none"
                  placeholder="WhatsApp Mobile Number — +(Country Code) (Mobile Number)"
                  focusBorderColor="#EBB435"
                  autocomplete="off"
                  value={whatsapp}
                  onChange={(e) => setWhatsapp(e.target.value)}
                />
              </InputGroup>
              <Checkbox
                size="md"
                colorScheme="yellow"
                fontWeight="600"
                color="#838383"
                borderRadius="10px"
                ml="15px"
                mt="5px"
                mb="5px"
                lineHeight="1.1"
                isChecked={checkedWA}
                onChange={(e) => setCheckedWA(e.target.checked)}
              >
                Allow other founders to contact me directly on WhatsApp
              </Checkbox>

              <InputGroup bg="#F9F9F9" mt="15px" borderRadius="10px">
                <Input
                  id="email"
                  color="#838383"
                  type="email"
                  fontWeight="700"
                  border="none"
                  placeholder="Personal Email Address"
                  focusBorderColor="#EBB435"
                  autocomplete="off"
                  value={personalEmail}
                  onChange={(e) => setPersonalEmail(e.target.value)}
                />
              </InputGroup>

              <InputGroup bg="#F9F9F9" borderRadius="10px" mt="15px">
                <Textarea
                  placeholder="Add your bio in less than 50 words."
                  id="email"
                  color="#838383"
                  type="email"
                  focusBorderColor="#EBB435"
                  fontWeight="700"
                  border="none"
                  autocomplete="off"
                  value={bio}
                  onChange={(e) => setBio(e.target.value)}
                />
              </InputGroup>
            </FormControl>

            <Flex
              flexDir="row"
              mt="15px"
              justifyContent="space-between"
              mb={["0px", "0px", "0px", "30px", "30px"]}
            >
              <InputGroup width="49%" bg="#F9F9F9" borderRadius="10px">
                <Input
                  id="email"
                  color="#838383"
                  type="text"
                  fontWeight="700"
                  background="#F9F9F9"
                  border="none"
                  placeholder="Twitter URL"
                  autocomplete="off"
                  value={pTwitter}
                  onChange={(e) => setPTwitter(e.target.value)}
                  focusBorderColor="#EBB435"
                />

                <InputRightElement
                  pointerEvents="none"
                  children={<Icon as={AiOutlineTwitter} color="gray.300" />}
                />
              </InputGroup>

              <InputGroup width="49%" bg="#F9F9F9" borderRadius="10px">
                <Input
                  id="email"
                  color="#838383"
                  type="email"
                  fontWeight="700"
                  background="#F9F9F9"
                  border="none"
                  placeholder="LinkedIn URL"
                  value={pLinkedin}
                  onChange={(e) => setPLinkedin(e.target.value)}
                  focusBorderColor="#EBB435"
                  autocomplete="off"
                />

                <InputRightElement
                  pointerEvents="none"
                  children={<Icon as={AiFillLinkedin} color="gray.300" />}
                />
              </InputGroup>
            </Flex>

            <Flex
              flexDir="row"
              justifyContent="space-between"
              mt={["40px", "40px", "40px", "0px", "0px"]}
            >
              <a href="/onboardinga">
                <Button
                  bg="#EDF2F7"
                  width="150px"
                  mr="10px"
                  p="2px"
                  mt={["0px", "0px", "0px", "60px", "60px"]}
                  mb={["20px", "20px", "20px", "0px", "0px"]}
                  color="#8E8E8E"
                  fontSize="16px"
                  _hover={{
                    background: "#E4E4E4",
                  }}
                >
                  Go Back
                </Button>
              </a>

              <Button
                bg="#EBB435"
                width={["180px", "180px", "180px", "200px", "200px"]}
                p="2px"
                mt={["0px", "0px", "0px", "60px", "60px"]}
                mb={["20px", "20px", "20px", "0px", "0px"]}
                color="white"
                fontSize="16px"
                _hover={{
                  background: "#F2DBA6",
                }}
                onClick={() => {
                  setOnboardingDetails();
                }}
                isDisabled={!name || !title || !bio || !whatsapp}
              >
                Save and Continue
              </Button>
            </Flex>
          </Flex>
        </Flex>

        <Flex
          flexDir="column"
          width={["100vw", "100vw", "100vw", "45vw", "45vw"]}
          height="100%"
        >
          <Image
            src={onboardingImage}
            objectFit="cover"
            height={["200px", "200px", "200px", "100%", "100%"]}
            width="100%"
          />
        </Flex>
      </Flex>
    </div>
  );
};

export default FormB;
