export const convertFiltersToRequest = (type, selectionArray) => {
  var req_string = "";
  if (selectionArray) {
    selectionArray.map((item, id) => (req_string = req_string + item + ","));
    req_string = type + "=" + req_string;
    return req_string;
  } else return null;
};

export const shareOnWhatsapp = (text) => {
  var baseURL = "https://api.whatsapp.com/send/?text=";
  return baseURL + text;
};
