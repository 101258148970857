import {
  Flex,
  Heading,
  Image,
  FormControl,
  Text,
  Textarea,
  Input,
  Button,
  Box,
  InputGroup,
  FormHelperText,
} from "@chakra-ui/react";
import { useContext, useState, useEffect } from "react";
import swal from "sweetalert";

import onboardingImage from "../../resources/imgs/onboarding.png";
import AuthContext from "../../store/auth";

const FormC = (props) => {
  const authCtx = useContext(AuthContext);

  const [focus, setFocus] = useState("");
  const [interests, setInterests] = useState("");
  const [experience, setExperience] = useState("");
  const [help1, setHelp1] = useState("");
  const [help2, setHelp2] = useState("");

  // INITIAL USER DATA — GET REQUEST
  const getOnboardingDetails = () => {
    fetch("https://beenext.orangeyak.xyz/api/users/onboarding_page_3/", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Token " + authCtx.token,
      },
    }).then((response) =>
      response
        .json()
        .then((data) => ({
          data: data,
          status: response.status,
        }))
        .then((res) => {
          if (res.data.status) {
            setFocus(res.data.user.expertise);
            setInterests(res.data.user.interest_areas);
            setExperience(res.data.user.experience);
            setHelp1(res.data.user.i_can_help);
            setHelp2(res.data.user.others_can_help);
          } else {
            swal("Something went wrong.", res.data.detail, "error");
          }
        })
    );
  };

  // USE EFFECT TO LOAD DATA ON FIRST PAGE LOAD
  useEffect(() => {
    getOnboardingDetails();
    // eslint-disable-next-line
  }, []);

  const setOnboardingDetails = () => {
    fetch("https://beenext.orangeyak.xyz/api/users/onboarding_page_3/", {
      method: "POST",
      body: JSON.stringify({
        expertise: focus,
        interest_areas: interests,
        experience: experience,
        i_can_help: help1,
        others_can_help: help2,
      }),
      headers: {
        "Content-Type": "application/json",
        Authorization: "Token " + authCtx.token,
      },
    }).then((response) =>
      response
        .json()
        .then((data) => ({
          data: data,
          status: response.status,
        }))
        .then((res) => {
          if (res.data) {
            window.location = "/beenext-hq";
          } else {
            swal("Something went wrong.", res.data.detail, "error");
          }
        })
    );
  };

  return (
    <div>
      <Flex
        flexDir={[
          "column-reverse",
          "column-reverse",
          "column-reverse",
          "row",
          "row",
        ]}
        width="100vw"
        height={["100%", "100%", "100%", "100vh", "100vh"]}
        overflow="hidden"
        alignItems="center"
      >
        <Flex
          flexDir="column"
          width={["95vw", "95vw", "95vw", "60vw", "60vw"]}
          alignItems="center"
          justifyContent="center"
          height="100%"
        >
          <Flex
            flexDir="column"
            width={["90%", "90%", "90%", "85%", "85%"]}
            mt={["30px", "30px", "30px", "0px", "0px"]}
            mb={["50px", "50px", "50px", "0px", "0px"]}
          >
            <Heading
              fontSize="28px"
              color="#505058"
              fontFamily="fonts.primary"
              mt="40px"
            >
              A few additional details.
            </Heading>

            <Text
              fontSize="18px"
              color="#A0AEC0"
              fontFamily="fonts.primary"
              mt="0px"
              fontWeight="700"
              lineHeight="1.2"
            >
              Please help us with a few more details about yourself.
            </Text>

            <Flex
              flexDir="row"
              width="100%"
              justifyContent="space-between"
              marginTop="10px"
            >
              <Box
                width="32%"
                height="10px"
                background="#EBB435"
                borderRadius="50px"
              ></Box>

              <Box
                width="32%"
                height="10px"
                background="#EBB435"
                borderRadius="50px"
              ></Box>

              <Box
                width="32%"
                height="10px"
                background="#EBB435"
                borderRadius="50px"
              ></Box>
            </Flex>

            <Flex
              flexDir={["column", "column", "column", "row", "row"]}
              mt="20px"
              justifyContent="space-between"
            >
              <FormControl mr={["0px", "0px", "0px", "10px", "10px"]} mt="15px">
                <Text
                  fontSize="14px"
                  lineHeight="1.4"
                  fontWeight="700"
                  color="#505058"
                >
                  Add your areas of expertise.
                </Text>
                <InputGroup bg="#F9F9F9" borderRadius="10px" mt="5px">
                  <Input
                    placeholder="GTM, Supply chains, Fundraising"
                    id="text"
                    color="#838383"
                    type="text"
                    focusBorderColor="#EBB435"
                    fontWeight="700"
                    border="none"
                    autocomplete="off"
                    value={focus}
                    onChange={(e) => setFocus(e.target.value)}
                  />
                </InputGroup>
                <FormHelperText>
                  Please add these as comma-separated values.
                </FormHelperText>
              </FormControl>

              <FormControl ml={["0px", "0px", "0px", "10px", "10px"]} mt="15px">
                <Text
                  fontSize="14px"
                  lineHeight="1.4"
                  fontWeight="700"
                  color="#505058"
                >
                  Please share some of your interest areas & hobbies.
                </Text>
                <InputGroup bg="#F9F9F9" borderRadius="10px" mt="5px">
                  <Input
                    placeholder="UI/UX Design, Crypto, Chess"
                    id="email"
                    color="#838383"
                    type="email"
                    focusBorderColor="#EBB435"
                    fontWeight="700"
                    border="none"
                    autocomplete="off"
                    value={interests}
                    onChange={(e) => setInterests(e.target.value)}
                  />
                </InputGroup>
                <FormHelperText>
                  Please add these as comma-separated values.
                </FormHelperText>
              </FormControl>
            </Flex>

            <FormControl mt="30px">
              <Text fontSize="14px" fontWeight="700" color="#505058">
                Please share some of your professional experiences.*
              </Text>

              <InputGroup bg="#F9F9F9" borderRadius="10px" mt="5px">
                <Textarea
                  placeholder="eg. spent time in HR at Pepsico"
                  id="email"
                  color="#838383"
                  type="email"
                  focusBorderColor="#EBB435"
                  fontWeight="700"
                  border="none"
                  autocomplete="off"
                  value={experience}
                  onChange={(e) => setExperience(e.target.value)}
                />
              </InputGroup>
              <FormHelperText>
                You may not have expertise but some activities where other
                founders can bounce of ideas with you. Please also include the
                organization where you gained that experience.
              </FormHelperText>
            </FormControl>

            <FormControl mt="20px">
              <Text fontSize="14px" fontWeight="700" color="#505058">
                What are some of the ways fellow founders in the BEE community
                can help you?*
              </Text>

              <InputGroup bg="#F9F9F9" borderRadius="10px" mt="5px">
                <Textarea
                  placeholder="Enter your answer here"
                  id="email"
                  color="#838383"
                  type="email"
                  focusBorderColor="#EBB435"
                  fontWeight="700"
                  border="none"
                  autocomplete="off"
                  value={help2}
                  onChange={(e) => setHelp2(e.target.value)}
                />
              </InputGroup>
            </FormControl>

            <FormControl mt="20px">
              <Text fontSize="14px" fontWeight="700" color="#505058">
                In a couple of sentences, how can you help fellow BEE founders
                in the community?*
              </Text>
              <InputGroup bg="#F9F9F9" borderRadius="10px" mt="5px">
                <Textarea
                  placeholder="Enter your answer here"
                  id="email"
                  color="#838383"
                  type="email"
                  focusBorderColor="#EBB435"
                  fontWeight="700"
                  border="none"
                  autocomplete="off"
                  value={help1}
                  onChange={(e) => setHelp1(e.target.value)}
                />
              </InputGroup>
            </FormControl>

            <Flex flexDir="row" justifyContent="space-between" mt="30px">
              <a href="/onboardingb">
                <Button
                  bg="#EDF2F7"
                  width="150px"
                  p="2px"
                  mr="10px"
                  mb={["20px", "20px", "20px", "0px", "0px"]}
                  color="#8E8E8E"
                  fontSize="16px"
                  _hover={{
                    background: "#E4E4E4",
                  }}
                >
                  Go Back
                </Button>
              </a>

              <Button
                bg="#EBB435"
                width={["180px", "180px", "180px", "200px", "200px"]}
                p="2px"
                mb={["20px", "20px", "20px", "0px", "0px"]}
                color="white"
                fontSize="16px"
                _hover={{
                  background: "#F2DBA6",
                }}
                isDisabled={!experience || !help1 || !help2}
                onClick={setOnboardingDetails}
              >
                Finish
              </Button>
            </Flex>
          </Flex>
        </Flex>

        <Flex
          flexDir="column"
          width={["100vw", "100vw", "100vw", "45vw", "45vw"]}
          height="100%"
        >
          <Image
            src={onboardingImage}
            objectFit="cover"
            height={["200px", "200px", "200px", "100%", "100%"]}
            width="100%"
          />
        </Flex>
      </Flex>
    </div>
  );
};

export default FormC;
