import { useState, useEffect, useContext } from "react";
import { Box, Flex, Image, Text, Spinner } from "@chakra-ui/react";
import swal from "sweetalert";

import NewsItem from "../components/NewsItem";
import EventItem from "../components/EventItem";
import Announcement from "../components/Announcement";
import Sidebar from "../components/Sidebar";

import AuthContext from "../store/auth";

const Dashboard = () => {
  const authCtx = useContext(AuthContext);
  const [news, setNews] = useState(null);
  const [events, setEvents] = useState(null);

  const getNewsFeed = () => {
    fetch("https://beenext.orangeyak.xyz/api/resources/news?limit=4", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Token " + authCtx.token,
      },
    }).then((response) =>
      response
        .json()
        .then((data) => ({
          data: data,
          status: response.status,
        }))
        .then((res) => {
          if (res.status < 300) {
            setNews(res.data.results);
          } else {
            swal("Oops, something went wrong!", res.data.detail, "error");
          }
        })
    );
  };

  const getEvents = () => {
    fetch("https://beenext.orangeyak.xyz/api/core/events/", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Token " + authCtx.token,
      },
    }).then((response) =>
      response
        .json()
        .then((data) => ({
          data: data,
          status: response.status,
        }))
        .then((res) => {
          if (res.status < 300) {
            setEvents(res.data.results);
          } else {
            swal("Oops, something went wrong!", res.data.detail, "error");
          }
        })
    );
  };

  const updateUserData = () => {
    fetch("https://beenext.orangeyak.xyz/api/resources/dashboard/", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Token " + authCtx.token,
      },
    }).then((response) =>
      response
        .json()
        .then((data) => ({
          data: data,
          status: response.status,
        }))
        .then((res) => {
          if (res.status < 300) {
            authCtx.updateUserInfo(
              res.data.user.name,
              res.data.user.avatar,
              res.data.user.firm.name,
              res.data.user.firm.firm_logo,
              res.data.user.firm.description,
              res.data.user.founder_id
            );
          } else {
            swal("Oops, something went wrong!", res.data.detail, "error");
          }
        })
    );
  };

  useEffect(() => {
    getNewsFeed();
    updateUserData();
    getEvents();
    // eslint-disable-next-line
  }, []);

  return (
    <Box
      bg="#EFEFEF"
      height="100vh"
      width="100vw"
      display="flex"
      flexDir="row"
      overflow="hidden"
    >
      {/* SIDEBAR SECTION */}
      <Sidebar />
      {/* END OF SIDEBAR */}

      {/* MASTER FEED WRAPPER SECTION */}
      <Flex
        width={["99%", "99%", "100%", "100%", "100%"]}
        flexDir="column"
        alignItems="center"
        height="95%"
        ml={["10px", "10px", "320px", "320px", "320px"]}
        mr="10px"
        mt={["60px", "60px", "20px", "20px", "20px"]}
        overflowX="hidden"
        overflowY="scroll"
      >
        {/* TOPBAR SECTION */}
        <Flex
          bg="white"
          width="99%"
          borderRadius="20px"
          ml="-10px"
          pt="20px"
          pb="20px"
          flexDir="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Flex maxWidth="600px">
            <Image
              src={authCtx.companyPp}
              height="70px"
              width="70px"
              ml="20px"
            />

            <Flex flexDir="column" ml="10px">
              <Text fontSize="22px" fontWeight="700" color="#505058">
                {authCtx.companyName}
              </Text>
              <Text
                fontSize="16px"
                lineHeight="120%"
                fontWeight="500"
                color="#A0AEC0"
              >
                {authCtx.companyD}
              </Text>
            </Flex>
          </Flex>

          {/* <GlobalSearchBar /> */}
        </Flex>
        {/* END OF TOPBAR SECTION */}

        <Flex
          flexDir={["column", "column", "column", "row", "row"]}
          mt="20px"
          width="99%"
          justifyContent="space-between"
        >
          <Flex width={["100%", "100%", "100%", "55%", "55%"]} flexDir="column">
            {/* start newfeed */}
            <Flex
              bg="white"
              p="20px"
              borderRadius="20px"
              flexDir="column"
              height="fit-content"
              ml="-10px"
              pt="20px"
              pb="20px"
              pl="30px"
            >
              <Text
                fontWeight="700"
                color="#505058"
                fontSize="18px"
                mb="5px"
                lineHeight="1.1"
              >
                Latest Updates from the BEENEXT Global Community
              </Text>

              {news !== null ? (
                <>
                  {news.map((newz, index) => (
                    <NewsItem
                      thb={newz.thumbnail}
                      tagText={newz.company_name}
                      title={newz.title}
                      agency={newz.publication}
                      date={newz.publication_date}
                    />
                  ))}
                </>
              ) : (
                <Spinner />
              )}
            </Flex>
            {/* end newfeed */}

            {/* upcoming events */}
            <Flex
              height="fit-content"
              flexDir="column"
              bg="white"
              p="20px"
              mt="20px"
              mb="20px"
              borderRadius="20px"
            >
              <Text fontWeight="700" color="#505058" fontSize="18px">
                UPCOMING EVENTS
              </Text>
              {events
                ? events.map((evItem, id) => (
                    <>
                      {evItem.is_past ? null : (
                        <EventItem
                          id={id}
                          date={evItem.start.substr(8, 2)}
                          title={evItem.title}
                          dateTime={evItem.date}
                        />
                      )}
                    </>
                  ))
                : null}
            </Flex>
            {/* end upcoming events */}
          </Flex>

          <Flex
            width={["100%", "100%", "100%", "44%", "44%"]}
            flexDir="column"
            ml="-10px"
            mb="20px"
          >
            <Flex
              height="fit-content"
              flexDir="column"
              bg="white"
              p="20px"
              mb="20px"
              borderRadius="20px"
            >
              <Text fontWeight="700" color="#505058" fontSize="18px">
                Important Announcements from BEENEXT
              </Text>

              <Announcement
                title="Get onboarded on the BEENEXT Founder Hub."
                desc="We’re thrilled to have you in our BEENEXT family!"
              />
              <Announcement
                title="Join all the BEENEXT Communities"
                desc="You'll find these links under BEENEXT HQ!"
              />
              <Announcement
                title="Onboard all co-founders from your startup"
                desc="Ask them to check their email!"
              />
            </Flex>

            <Flex
              height="fit-content"
              flexDir="column"
              bg="white"
              p="20px"
              borderRadius="20px"
              target="_blank"
            >
              <Text fontWeight="700" color="#505058" fontSize="18px">
                BEENEXT GALLERY
              </Text>

              <Text
                fontWeight="600"
                color="#A0AEC0"
                fontSize="12px"
                ml="2px"
                mt="10px"
                mb="5px"
              >
                BEENEXT Global Camp 2019
              </Text>

              <a
                href="https://photos.google.com/share/AF1QipOpNX93DQR3MgzziXFVBrvNTIf4kYKJOe0hF9xDK8RkOtgBNPod6Jx6VtXLdCDIHA?key=X1IyYnRqS2VUQTRkeWoxQk5LX055ODQ3YXRxNlFR"
                target="_blank"
                rel="noreferrer"
              >
                <Image
                  width="100%"
                  mt="0px"
                  src="https://i.imgur.com/p6hua0e.jpg"
                  height="200px"
                  objectFit="cover"
                  borderRadius="5px"
                />
              </a>

              <Flex w="100%" flexDir="row" mt="5px">
                <Flex
                  as="a"
                  href="https://photos.google.com/share/AF1QipMsV9qDNljLlemmMnWe8LmaSpklbdzvNr49m9lpaEhWFpnd74EQlfgU7JCdMClBVw?key=VTNVLWFDQ0lXMEZqQWU3cFRSTHpHQzRkanBCV1F3"
                  target="_blank"
                  rel="noreferrer"
                  width="49%"
                  flexDir="column"
                >
                  <Text
                    fontWeight="600"
                    color="#A0AEC0"
                    fontSize="12px"
                    mt="10px"
                    mb="5px"
                    lineHeight="1.1"
                    ml="2px"
                  >
                    BEENEXT Founder Get-Together, <br></br>Bangalore 2022
                  </Text>
                  <Image
                    src="https://i.imgur.com/31UMtUX.jpg"
                    height="200px"
                    objectFit="cover"
                    borderRadius="5px"
                    mr="5px"
                  />
                </Flex>

                <Flex
                  as="a"
                  href="https://photos.google.com/share/AF1QipNJSKz0F__giG4FV6_5kMPaQqWfnBU52EyRrRwTO4uQ18FvT4lQFoshqA3Tqi7nSg?key=cEVtaFlvalY1X0ZqVmtNZlpMX09rWHMyb2FTMTJR"
                  target="_blank"
                  rel="noreferrer"
                  width="49%"
                  flexDir="column"
                >
                  <Text
                    fontWeight="600"
                    color="#A0AEC0"
                    fontSize="12px"
                    mt="10px"
                    ml="8px"
                    mb="5px"
                    lineHeight="1.1"
                  >
                    BEENEXT Founder Get-Together, <br></br>Delhi 2022
                  </Text>
                  <Image
                    src="https://i.imgur.com/1vPGx1J.jpg"
                    height="200px"
                    objectFit="cover"
                    borderRadius="5px"
                    ml="5px"
                  />
                </Flex>
              </Flex>
            </Flex>
          </Flex>
        </Flex>

        {/* END OF SCROLLABLE INTERNAL SECTION */}
      </Flex>
      {/* END OF SIDEBAR + SCROLLABLE SECTION */}
    </Box>
  );
};

export default Dashboard;
