import { Flex, Icon, Text } from "@chakra-ui/react";
import { NavLink } from "react-router-dom";

const SidebarLink = (props) => {
  return (
    <NavLink to={props.linkTo}>
      <Flex
        flexDir="row"
        alignItems="center"
        bg={props.linkIsActive ? "#FFF2D2" : "#FFFFFF"}
        paddingLeft="20px"
        paddingTop="7px"
        paddingBottom="7px"
      >
        <Icon
          as={props.linkIcon}
          color={props.linkIsActive ? "#EBB435" : "#4A5568"}
        />

        <Text
          ml="15px"
          lineHeight="1"
          letterSpacing="-0.5px"
          color={props.linkIsActive ? "#EBB435" : "#4A5568"}
          fontWeight="700"
          fontSize="18px"
        >
          {props.linkText}
        </Text>
      </Flex>
    </NavLink>
  );
};

export default SidebarLink;
