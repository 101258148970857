import {
  Box,
  Flex,
  Text,
  Image,
  Button,
  Icon,
  Spinner,
} from "@chakra-ui/react";
import { useParams } from "react-router-dom";
import { BsLinkedin, BsTwitter } from "react-icons/bs";
import { useState, useContext, useEffect } from "react";
import swal from "sweetalert";

import { shareOnWhatsapp } from "../store/functions";
import { BsWhatsapp } from "react-icons/bs";

import fallbackProfile from "../resources/imgs/profile.png";

import Sidebar from "../components/Sidebar";

import AuthContext from "../store/auth";
import Tag from "../components/Tag";

const Founder = (props) => {
  const authCtx = useContext(AuthContext);

  const [founder, setFounder] = useState();

  let { founderSlug } = useParams();

  console.log(founderSlug);

  const getFounder = () => {
    fetch(
      "https://beenext.orangeyak.xyz/api/resources/founders/" +
        founderSlug +
        "/",
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Token " + authCtx.token,
        },
      }
    ).then((response) =>
      response
        .json()
        .then((data) => ({
          data: data,
          status: response.status,
        }))
        .then((res) => {
          if (res.status < 300) {
            setFounder(res.data);
          } else {
            swal("Oops, something went wrong!", res.data.detail, "error");
          }
        })
    );
  };

  // request connection
  const requestConnectionPost = () => {
    fetch("https://beenext.orangeyak.xyz/api/resources/request_connection/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Token " + authCtx.token,
      },
      body: JSON.stringify({
        content_type: "Founder",
        object_name: founder.name,
      }),
    }).then((res) => {
      if (res.ok)
        swal(
          "Connection request sent successfully!",
          "The BEENEXT Team will reach out to you shortly!",
          "success"
        );
      else
        swal("Oops, something went wrong!", "Please try again later!", "error");
    });
  };

  useEffect(() => {
    getFounder();
    // eslint-disable-next-line
  }, []);

  return (
    <Box
      bg="#EFEFEF"
      height="100vh"
      width="100vw"
      display="flex"
      flexDir="row"
      overflow="hidden"
    >
      {/* SIDEBAR SECTION */}
      <Sidebar />
      {/* END OF SIDEBAR */}

      {/* MASTER FEED WRAPPER SECTION */}
      <Flex
        width={["99%", "99%", "100%", "100%", "100%"]}
        flexDir="column"
        alignItems="center"
        height="95%"
        ml={["10px", "10px", "320px", "320px", "320px"]}
        mr="10px"
        mt={["60px", "60px", "20px", "20px", "20px"]}
        overflowX="hidden"
        overflowY="scroll"
      >
        {founder ? (
          <>
            {/* TOPBAR SECTION */}
            <Flex
              bg="white"
              width="99%"
              borderRadius="20px"
              ml="-10px"
              pt="20px"
              pb="20px"
              flexDir="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <Flex maxWidth="1000px" flexDir="row" alignItems="center">
                <Image
                  src={founder.avatar}
                  width="80px"
                  minWidth="80px"
                  height="80px"
                  minHeight="80px"
                  borderRadius="100%"
                  objectFit="cover"
                  ml="30px"
                  mr="30px"
                  fallbackSrc={fallbackProfile}
                />

                <Flex flexDir="column">
                  <Text fontSize="28px" fontWeight="700" color="#505058">
                    {founder.name}
                  </Text>
                  <Text
                    fontSize="16px"
                    lineHeight="120%"
                    fontWeight="600"
                    color="#A0AEC0"
                    mt="0"
                    mr="20px"
                  >
                    {founder.bio}
                  </Text>
                </Flex>
              </Flex>

              {/* <GlobalSearchBar /> */}
            </Flex>
            {/* END OF TOPBAR SECTION */}

            <Flex
              flexDir={["column", "column", "column", "row", "row"]}
              mt="20px"
              width="99%"
              justifyContent="space-between"
            >
              <Flex
                width={["100%", "100%", "100%", "65%", "65%"]}
                flexDir="column"
              >
                <Text
                  ml="20px"
                  mb="5px"
                  fontSize="18px"
                  fontWeight="700"
                  color="#505058"
                >
                  ABOUT ME
                </Text>
                <Flex
                  height="fit-content"
                  flexDir="column"
                  bg="white"
                  p="20px"
                  borderRadius="20px"
                >
                  <Flex flexDir="row" justifyContent="space-between">
                    <Flex flexDir="column" width="45%">
                      <Text
                        fontSize="14px"
                        lineHeight="100%"
                        fontWeight="900"
                        color="#A0AEC0"
                        mr="20px"
                        mb="0px"
                        textTransform="uppercase"
                      >
                        FOUNDER DESIGNATION
                      </Text>
                      <Text
                        fontSize="18px"
                        lineHeight="120%"
                        mt="5px"
                        fontWeight="500"
                        color="#505058"
                      >
                        {founder.title}
                      </Text>
                    </Flex>
                    <Flex flexDir="column" width="45%">
                      <Text
                        fontSize="14px"
                        lineHeight="100%"
                        fontWeight="900"
                        color="#A0AEC0"
                        mr="20px"
                        mb="0px"
                        textTransform="uppercase"
                      >
                        COMPANY NAME
                      </Text>
                      <Text
                        fontSize="18px"
                        lineHeight="120%"
                        mt="5px"
                        fontWeight="500"
                        color="#505058"
                      >
                        {founder.firm.name}
                      </Text>
                    </Flex>
                  </Flex>

                  <Flex flexDir="column" width="100%">
                    <Text
                      fontSize="14px"
                      lineHeight="100%"
                      fontWeight="900"
                      color="#A0AEC0"
                      mt="25px"
                      mr="20px"
                      mb="0px"
                      textTransform="uppercase"
                    >
                      AREAS OF EXPERTISE
                    </Text>
                    <Flex flexDir="row" flexWrap="wrap">
                      {founder.expertise
                        ? founder.expertise
                            .split(", ")
                            .map((area) => <Tag text={area} />)
                        : null}
                    </Flex>
                  </Flex>

                  <Flex flexDir="column" width="100%">
                    <Text
                      fontSize="14px"
                      lineHeight="100%"
                      fontWeight="900"
                      color="#A0AEC0"
                      mt="25px"
                      mr="20px"
                      mb="0px"
                      textTransform="uppercase"
                    >
                      INTEREST AREAS AND HOBBIES
                    </Text>
                    <Flex flexDir="row" flexWrap="wrap">
                      {founder.interest_areas
                        ? founder.interest_areas
                            .split(", ")
                            .map((area) => <Tag text={area} />)
                        : null}
                    </Flex>
                  </Flex>

                  <Flex flexDir="column" width="100%">
                    <Text
                      fontSize="14px"
                      lineHeight="100%"
                      fontWeight="900"
                      color="#A0AEC0"
                      mt="25px"
                      mr="20px"
                      mb="0px"
                      textTransform="uppercase"
                    >
                      PROFESSIONAL EXPERIENCES
                    </Text>
                    {/* <Text>{founder.professional_experiences}</Text> */}
                    <Text
                      fontSize="18px"
                      lineHeight="120%"
                      mt="5px"
                      fontWeight="500"
                      color="#505058"
                    >
                      {founder.experience}
                    </Text>
                  </Flex>

                  <Flex flexDir="column" width="100%">
                    <Text
                      fontSize="14px"
                      lineHeight="100%"
                      fontWeight="900"
                      color="#A0AEC0"
                      mt="25px"
                      mr="20px"
                      mb="0px"
                      textTransform="uppercase"
                    >
                      WAYS THAT FELLOW BEE FOUNDERS CAN HELP
                    </Text>
                    {/* <Text>{founder.professional_experiences}</Text> */}
                    <Text
                      fontSize="18px"
                      lineHeight="120%"
                      mt="5px"
                      fontWeight="500"
                      color="#505058"
                    >
                      {founder.others_can_help}
                    </Text>
                  </Flex>

                  <Flex flexDir="column" width="100%">
                    <Text
                      fontSize="14px"
                      lineHeight="100%"
                      fontWeight="900"
                      color="#A0AEC0"
                      mt="25px"
                      mr="20px"
                      mb="0px"
                      textTransform="uppercase"
                    >
                      WAYS I CAN HELP FELLOW BEE FOUNDERS
                    </Text>
                    {/* <Text>{founder.professional_experiences}</Text> */}
                    <Text
                      fontSize="18px"
                      lineHeight="120%"
                      mt="5px"
                      fontWeight="500"
                      color="#505058"
                    >
                      {founder.i_can_help}
                    </Text>
                  </Flex>

                  <Flex
                    flexDir={["column", "column", "row", "row", "row"]}
                    justifyContent="space-between"
                  >
                    <Flex flexDir="row" mt="25px">
                      {!founder.share_my_contact_details ? (
                        <Button
                          color="#EBB435"
                          bg="#FFF2D2"
                          onClick={requestConnectionPost}
                          cursor="pointer"
                        >
                          Request Connection
                        </Button>
                      ) : (
                        <Button
                          leftIcon={<BsWhatsapp />}
                          pl="20px"
                          pr="20px"
                          width="fit-content"
                          color="#EBB435"
                          background="#FFF2D2"
                          as="a"
                          href={
                            "https://api.whatsapp.com/send/?phone=" +
                            founder.phone +
                            "&text=Hey " +
                            founder.name +
                            " 👋🏻!"
                          }
                          target="_blank"
                          rel="noreferrer"
                        >
                          Contact on WhatsApp
                        </Button>
                      )}

                      <Button
                        leftIcon={<BsWhatsapp />}
                        pl="20px"
                        pr="20px"
                        ml="10px"
                        width="fit-content"
                        color="#EBB435"
                        background="#FFF2D2"
                        as="a"
                        href={shareOnWhatsapp(
                          "Check out this founder on the BEENEXT Founder Hub — *" +
                            founder.name +
                            "*! " +
                            window.location.href
                        )}
                        target="_blank"
                        rel="noreferrer"
                      >
                        Share
                      </Button>
                    </Flex>
                    <Flex flexDir="row" mt="25px">
                      <Box
                        minWidth="40px"
                        minHeight="40px"
                        display="flex"
                        bg="#FFF2D2"
                        borderRadius="8px"
                        alignItems="center"
                        justifyContent="center"
                        mr="8px"
                        as="a"
                        href={founder.linkedin}
                        target="_blank"
                        rel="noreferrer"
                        cursor="pointer"
                      >
                        <Icon as={BsLinkedin} boxSize="25px" color="#EBB435" />
                      </Box>

                      <Box
                        minWidth="40px"
                        minHeight="40px"
                        display="flex"
                        bg="#FFF2D2"
                        borderRadius="8px"
                        alignItems="center"
                        justifyContent="center"
                        as="a"
                        href={founder.twitter}
                        target="_blank"
                        rel="noreferrer"
                        cursor="pointer"
                      >
                        <Icon as={BsTwitter} boxSize="25px" color="#EBB435" />
                      </Box>
                    </Flex>
                  </Flex>
                </Flex>
              </Flex>

              <Flex
                width={["100%", "100%", "100%", "34%", "34%"]}
                flexDir="column"
                mb="20px"
              >
                <Text
                  ml="20px"
                  mb="5px"
                  fontSize="18px"
                  fontWeight="700"
                  color="#505058"
                  textTransform="uppercase"
                >
                  ABOUT {founder.firm.name}
                </Text>
                <Flex
                  height="fit-content"
                  flexDir="column"
                  bg="white"
                  p="20px"
                  mb="20px"
                  borderRadius="20px"
                >
                  <Flex flexDir="row" alignItems="center">
                    <Image
                      src={founder.firm.firm_logo}
                      fallbackSrc={fallbackProfile}
                      objectFit="contain"
                      boxSize="50px"
                      borderRadius="100%"
                      mr="20px"
                    />
                    <Text
                      fontSize="20px"
                      lineHeight="120%"
                      mt="5px"
                      fontWeight="700"
                      color="#505058"
                      textTransform="uppercase"
                    >
                      {founder.firm.name}
                    </Text>
                  </Flex>

                  <Flex flexDir="column" width="100%">
                    <Text
                      fontSize="14px"
                      lineHeight="100%"
                      fontWeight="900"
                      color="#A0AEC0"
                      mt="25px"
                      mr="20px"
                      mb="0px"
                      textTransform="uppercase"
                    >
                      COMPANY DESCRIPTION
                    </Text>
                    <Text
                      fontSize="18px"
                      lineHeight="120%"
                      mt="5px"
                      fontWeight="500"
                      color="#505058"
                    >
                      {founder.firm.description}
                    </Text>
                  </Flex>

                  <Flex flexDir="column" width="100%">
                    <Text
                      fontSize="14px"
                      lineHeight="100%"
                      fontWeight="900"
                      color="#A0AEC0"
                      mt="25px"
                      mr="20px"
                      mb="0px"
                      textTransform="uppercase"
                    >
                      COUNTRIES OF OPERATION
                    </Text>
                    <Flex flexDir="row" flexWrap="wrap">
                      {(
                        founder.firm.region.name +
                        ", " +
                        founder.firm.other_regions
                      )
                        .split(", ")
                        .map((area) => (
                          <Tag text={area} />
                        ))}
                    </Flex>
                  </Flex>

                  <Flex flexDir="column" width="100%">
                    <Text
                      fontSize="14px"
                      lineHeight="100%"
                      fontWeight="900"
                      color="#A0AEC0"
                      mt="25px"
                      mr="20px"
                      mb="0px"
                      textTransform="uppercase"
                    >
                      COMPANY CATEGORY
                    </Text>
                    <Tag text={founder.firm.business_categories} />
                  </Flex>

                  <Flex flexDir="column" width="100%">
                    <Text
                      fontSize="14px"
                      lineHeight="100%"
                      fontWeight="900"
                      color="#A0AEC0"
                      mt="25px"
                      mr="20px"
                      mb="0px"
                      textTransform="uppercase"
                    >
                      COMPANY WEBSITE
                    </Text>
                    <a href={founder.firm.website}>
                      <Text
                        fontSize="18px"
                        lineHeight="120%"
                        mt="5px"
                        fontWeight="500"
                        color="#71AAFF"
                      >
                        {founder.firm.website}
                      </Text>
                    </a>
                  </Flex>

                  <Flex flexDir="row" mt="15px">
                    <Box
                      minWidth="40px"
                      minHeight="40px"
                      display="flex"
                      bg="#FFF2D2"
                      borderRadius="8px"
                      alignItems="center"
                      justifyContent="center"
                      mr="8px"
                      as="a"
                      href={founder.firm.linkedin}
                      target="_blank"
                      rel="noreferrer"
                      cursor="pointer"
                    >
                      <Icon as={BsLinkedin} boxSize="25px" color="#EBB435" />
                    </Box>

                    <Box
                      minWidth="40px"
                      minHeight="40px"
                      display="flex"
                      bg="#FFF2D2"
                      borderRadius="8px"
                      alignItems="center"
                      justifyContent="center"
                      as="a"
                      href={founder.firm.twitter}
                      target="_blank"
                      rel="noreferrer"
                      cursor="pointer"
                    >
                      <Icon as={BsTwitter} boxSize="25px" color="#EBB435" />
                    </Box>
                  </Flex>
                </Flex>
              </Flex>
            </Flex>
          </>
        ) : (
          <Flex width="100%" height="100%" margin="auto">
            <Spinner color="darkGray" />
          </Flex>
        )}

        {/* END OF SCROLLABLE INTERNAL SECTION */}
      </Flex>

      {/* END OF SCROLLABLE INTERNAL SECTION */}
    </Box>
  );
};

export default Founder;
