import {
  Flex,
  Heading,
  Image,
  FormControl,
  FormLabel,
  Input,
  Text,
  FormHelperText,
  Button,
  Checkbox,
  InputRightElement,
  InputGroup,
} from "@chakra-ui/react";
import { useRef, useContext, useState } from "react";
import swal from "sweetalert";

import onboardingImage from "../../resources/imgs/onboarding.png";
import beenextLogo from "../../resources/imgs/beenextLogo.png";

import AuthContext from "../../store/auth";

const Register = () => {
  const authCtx = useContext(AuthContext);
  const emailInputRef = useRef();
  const inviteInputRef = useRef();
  const passwordInputRef = useRef();
  const cpasswordInputRef = useRef();

  const [show, setShow] = useState(false);
  const [show2, setShow2] = useState(false);
  const [terms, setTerms] = useState(false);

  function loginHandler() {
    const enteredEmail = emailInputRef.current.value;
    const enteredInvite = inviteInputRef.current.value;
    const enteredPassword = passwordInputRef.current.value;
    const enteredCPassword = cpasswordInputRef.current.value;

    if (enteredEmail && enteredPassword && enteredInvite && enteredCPassword) {
      fetch("https://beenext.orangeyak.xyz/api/users/register/", {
        method: "POST",
        body: JSON.stringify({
          email: enteredEmail,
          invite_code: enteredInvite,
          password: enteredPassword,
          password2: enteredCPassword,
        }),
        headers: {
          "Content-Type": "application/json",
        },
      }).then((response) =>
        response
          .json()
          .then((data) => ({
            data: data,
            status: response.status,
          }))
          .then((res) => {
            if (res.data.status) {
              authCtx.login(res.data.key);
              console.log(res.data.key);
              window.location = "/onboardinga";
            } else {
              swal("Registration failed.", res.data.detail, "error");
            }
          })
      );
    }
  }

  return (
    <div>
      {/* <Navbar /> */}
      <Flex
        flexDir={[
          "column-reverse",
          "column-reverse",
          "column-reverse",
          "row",
          "row",
        ]}
        width="100vw"
        height={["100%", "100%", "100%", "100vh", "100vh"]}
        overflow="hidden"
        alignItems="center"
      >
        <Flex
          flexDir="column"
          width={["95vw", "95vw", "95vw", "60vw", "60vw"]}
          alignItems="center"
          justifyContent="center"
          height="100%"
        >
          <Flex
            flexDir="column"
            width={["90%", "90%", "90%", "600px", "600px"]}
            mt={["50px", "50px", "50px", "0px", "0px"]}
            mb={["50px", "50px", "50px", "0px", "0px"]}
          >
            <Image src={beenextLogo} height="12px" width="120px" />

            <Heading fontSize="28px" color="#EBB435" fontFamily="fonts.primary">
              Founder's Hub
            </Heading>

            <Heading
              fontSize="28px"
              color="#505058"
              fontFamily="fonts.primary"
              mt="40px"
            >
              Register
            </Heading>

            <Text
              fontSize="18px"
              color="#A0AEC0"
              fontFamily="fonts.primary"
              mt="5px"
              lineHeight="1.2"
              width="80%"
              fontWeight="700"
            >
              We're super excited to onboard you as part of the BEENEXT Founder
              Community!
            </Text>

            <FormControl mt="20px">
              <FormLabel color="#505058" fontWeight="700" htmlFor="email">
                Email address*
              </FormLabel>
              <Input
                id="email"
                bg="#F9F9F9"
                color="#838383"
                type="email"
                fontWeight="700"
                focusBorderColor="#EBB435"
                border="none"
                placeholder="founder@beenext.com"
                autocomplete="off"
                ref={emailInputRef}
              />
            </FormControl>

            <FormControl mt="20px">
              <FormLabel color="#505058" fontWeight="700" htmlFor="email">
                Invite Code*
              </FormLabel>
              <Input
                id="invite"
                bg="#F9F9F9"
                color="#838383"
                type="text"
                fontWeight="700"
                focusBorderColor="#EBB435"
                border="none"
                placeholder="ABCD1234"
                autocomplete="off"
                ref={inviteInputRef}
              />
            </FormControl>

            <FormControl mt="20px">
              <FormLabel color="#505058" fontWeight="700" htmlFor="email">
                Password*
              </FormLabel>

              <InputGroup>
                <Input
                  id="password"
                  bg="#F9F9F9"
                  color="#838383"
                  focusBorderColor="#EBB435"
                  type={show ? "text" : "password"}
                  fontWeight="700"
                  border="none"
                  placeholder="Enter your password"
                  autocomplete="off"
                  ref={passwordInputRef}
                />
                <InputRightElement width="4.5rem">
                  <Button h="1.75rem" size="sm" onClick={() => setShow(!show)}>
                    {show ? "Hide" : "Show"}
                  </Button>
                </InputRightElement>
              </InputGroup>

              <FormHelperText>
                Password must be minimum 6 characters.
              </FormHelperText>
            </FormControl>

            <FormControl mt="20px">
              <FormLabel color="#505058" fontWeight="700" htmlFor="email">
                Confirm Password*
              </FormLabel>
              <InputGroup>
                <Input
                  id="password"
                  bg="#F9F9F9"
                  color="#838383"
                  focusBorderColor="#EBB435"
                  type={show2 ? "text" : "password"}
                  fontWeight="700"
                  border="none"
                  placeholder="Confirm your password"
                  autocomplete="off"
                  ref={cpasswordInputRef}
                />
                <InputRightElement width="4.5rem">
                  <Button
                    h="1.75rem"
                    size="sm"
                    onClick={() => setShow2(!show2)}
                  >
                    {show2 ? "Hide" : "Show"}
                  </Button>
                </InputRightElement>
              </InputGroup>
            </FormControl>

            <Flex flexDir="row" mt="40px">
              <Checkbox
                colorScheme="yellow"
                isChecked={terms}
                onChange={(e) => setTerms(!terms)}
              ></Checkbox>
              <Text
                color="#838383"
                fontWeight="500"
                ml="10px"
                as="a"
                href="/privacy-policy"
                target="_blank"
                rel="noreferrer"
              >
                I agree to the <u>Privacy Policy</u> terms and conditions.
              </Text>
            </Flex>

            <Button
              bg="#EBB435"
              width="300px"
              p="2px"
              mt={["20px", "20px", "20px", "40px", "40px"]}
              mb={["20px", "20px", "20px", "0px", "0px"]}
              color="white"
              isDisabled={!terms}
              fontSize="16px"
              onClick={loginHandler}
              _hover={{
                background: "#F2DBA6",
              }}
            >
              Register for the Founder Dashboard
            </Button>
          </Flex>
        </Flex>

        <Flex
          flexDir="column"
          width={["100vw", "100vw", "100vw", "45vw", "45vw"]}
          height="100%"
        >
          <Image
            src={onboardingImage}
            objectFit="cover"
            height={["200px", "200px", "200px", "100%", "100%"]}
            width="100%"
          />
        </Flex>
      </Flex>
    </div>
  );
};

export default Register;
