import React, { useState } from "react";

const AuthContext = React.createContext({
  token: "",
  isLoggedIn: false,
  login: (token) => {},
  logout: () => {},
  name: "",
  companyName: "",
  userPp: "",
  companyPp: "",
  companyD: "",
  id: "",
});

export const AuthContextProvider = (props) => {
  // user data
  const initialName = localStorage.getItem("name");
  const [name, setName] = useState(initialName);

  const initialId = localStorage.getItem("id");
  const [id, setId] = useState(initialId);

  const initialUserPp = localStorage.getItem("userPp");
  const [userPp, setUserPp] = useState(initialUserPp);

  const initialCompany = localStorage.getItem("companyName");
  const [companyName, setCompanyName] = useState(initialCompany);

  const initialCompanyPp = localStorage.getItem("companyPp");
  const [companyPp, setCompanyPp] = useState(initialCompanyPp);

  const initialCompanyD = localStorage.getItem("companyD");
  const [companyD, setCompanyD] = useState(initialCompanyD);

  // login token
  const initialToken = localStorage.getItem("token");
  const [token, setToken] = useState(initialToken);

  const userIsLoggedIn = !!token;

  const loginHandler = (token) => {
    setToken(token);
    localStorage.setItem("token", token);
  };

  const updateUserInfo = (
    name,
    userPp,
    companyName,
    companyPp,
    companyDesc,
    id
  ) => {
    setUserPp(userPp);
    localStorage.setItem("userPp", userPp);

    setCompanyD(companyDesc);
    localStorage.setItem("companyD", companyDesc);

    setName(name);
    localStorage.setItem("name", name);

    setCompanyName(companyName);
    localStorage.setItem("companyName", companyName);

    setUserPp(userPp);
    localStorage.setItem("userPp", userPp);

    setCompanyPp(companyPp);
    localStorage.setItem("companyPp", companyPp);

    setId(id);
    localStorage.setItem("id", id);
  };

  const logoutHandler = () => {
    setToken(null);
    localStorage.clear();
  };

  const contextValue = {
    token: token,
    isLoggedIn: userIsLoggedIn,
    name: name,
    companyName: companyName,
    userPp: userPp,
    companyPp: companyPp,
    companyD: companyD,
    id: id,
    login: loginHandler,
    logout: logoutHandler,
    updateUserInfo: updateUserInfo,
  };

  return (
    <AuthContext.Provider value={contextValue}>
      {props.children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
