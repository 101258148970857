import {
  Box,
  Flex,
  Input,
  Text,
  InputGroup,
  InputLeftElement,
  Checkbox,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
} from "@chakra-ui/react";
import { Search2Icon } from "@chakra-ui/icons";

import FounderCard from "../components/FounderCard";
import Sidebar from "../components/Sidebar";

const SubjectExperts = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <Box
      bg="#EFEFEF"
      height="100vh"
      width="100vw"
      display="flex"
      flexDir="row"
      overflow="hidden"
    >
      {/* SIDEBAR SECTION */}
      <Sidebar />
      {/* END OF SIDEBAR */}

      {/* MASTER FEED WRAPPER SECTION */}
      <Flex
        width={["99%", "99%", "100%", "100%", "100%"]}
        flexDir="column"
        alignItems="center"
        height="95%"
        ml={["10px", "10px", "320px", "320px", "320px"]}
        mr="10px"
        mt={["60px", "60px", "20px", "20px", "20px"]}
        overflowX="hidden"
        overflowY="scroll"
      >
        {/* TOPBAR SECTION */}
        <Flex
          bg="white"
          width="99%"
          borderRadius="20px"
          ml="-10px"
          pt="20px"
          pb="20px"
          flexDir="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Flex maxWidth="600px">
            <Flex flexDir="column" ml="30px">
              <Text fontSize="22px" fontWeight="700" color="#505058">
                SUBJECT MATTER EXPERTS
              </Text>
              <Text
                fontSize="16px"
                lineHeight="120%"
                fontWeight="500"
                color="#A0AEC0"
              >
                We’re thrilled to have you in our BEENEXT family! Feel free to
                read about our vision, goals, find quick links, or connect with
                our team members!
              </Text>
            </Flex>
          </Flex>

          {/* <GlobalSearchBar /> */}
        </Flex>
        {/* END OF TOPBAR SECTION */}

        <Flex bg="white" width="99%" borderRadius="200px" mt="10px">
          <InputGroup width="100%">
            <InputLeftElement
              pt="5px"
              children={<Search2Icon color="gray.300" />}
            />
            <Input
              pr="10px"
              borderRadius="20px"
              placeholder="Search for resources, events, people"
              color="#A0AEC0"
              focusBorderColor="#EBB435"
              fontWeight="700"
              border="none"
              autocomplete="off"
            />
          </InputGroup>
        </Flex>

        <Flex
          flexDir={["column", "column", "row", "row", "row"]}
          width="99%"
          mt="20px"
          justifyContent="space-between"
        >
          <Flex
            width="250px"
            display={["none", "none", "flex", "flex", "flex"]}
            flexDir="column"
          >
            <Text
              color="lightGray"
              fontWeight="700"
              textTransform="uppercase"
              fontSize="16px"
            >
              REGION
            </Text>
            <Checkbox
              colorScheme="yellow"
              defaultIsChecked
              value="India"
              fontWeight="700"
              br="10px"
              ml="2px"
              mb="2px"
              color="darkGray"
            >
              India
            </Checkbox>

            <Checkbox
              colorScheme="yellow"
              defaultIsChecked
              value="Indonesia"
              fontWeight="700"
              ml="2px"
              mb="2px"
              color="darkGray"
            >
              Indonesia
            </Checkbox>

            <Checkbox
              colorScheme="yellow"
              value="Singapore"
              fontWeight="700"
              ml="2px"
              mb="2px"
              color="darkGray"
            >
              Singapore
            </Checkbox>

            <Checkbox
              colorScheme="yellow"
              value="Vietnam"
              fontWeight="700"
              ml="2px"
              mb="2px"
              color="darkGray"
            >
              Vietnam
            </Checkbox>

            <Text
              color="lightGray"
              fontWeight="700"
              textTransform="uppercase"
              fontSize="16px"
              mt="20px"
            >
              INDUSTRY
            </Text>

            <Checkbox
              colorScheme="yellow"
              defaultIsChecked
              value="India"
              fontWeight="700"
              br="10px"
              ml="2px"
              mb="2px"
              color="darkGray"
            >
              Fintech
            </Checkbox>

            <Checkbox
              colorScheme="yellow"
              defaultIsChecked
              value="Indonesia"
              fontWeight="700"
              ml="2px"
              mb="2px"
              color="darkGray"
            >
              Ecommerce
            </Checkbox>

            <Checkbox
              colorScheme="yellow"
              value="Singapore"
              fontWeight="700"
              ml="2px"
              mb="2px"
              color="darkGray"
            >
              HealthTech
            </Checkbox>

            <Checkbox
              colorScheme="yellow"
              value="Vietnam"
              fontWeight="700"
              ml="2px"
              mb="2px"
              color="darkGray"
            >
              EdTech
            </Checkbox>

            <Checkbox
              colorScheme="yellow"
              value="Vietnam"
              fontWeight="700"
              ml="2px"
              mb="2px"
              color="darkGray"
            >
              Gaming
            </Checkbox>

            <Checkbox
              colorScheme="yellow"
              value="Vietnam"
              fontWeight="700"
              ml="2px"
              mb="2px"
              color="darkGray"
            >
              Hospitality, Travel
            </Checkbox>

            <Checkbox
              colorScheme="yellow"
              value="Vietnam"
              fontWeight="700"
              ml="2px"
              mb="2px"
              color="darkGray"
            >
              Media
            </Checkbox>

            <Checkbox
              colorScheme="yellow"
              value="Vietnam"
              fontWeight="700"
              ml="2px"
              mb="2px"
              color="darkGray"
            >
              Logistics
            </Checkbox>
          </Flex>

          <Button
            onClick={onOpen}
            display={["flex", "flex", "none", "none", "none"]}
            width="100px"
            color="#EBB435"
            bg="#FFF2D2"
          >
            FILTERS
          </Button>

          <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>FILTERS</ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                <Text
                  color="lightGray"
                  fontWeight="700"
                  textTransform="uppercase"
                  fontSize="16px"
                >
                  REGION
                </Text>
                <Checkbox
                  colorScheme="yellow"
                  defaultIsChecked
                  value="India"
                  fontWeight="700"
                  br="10px"
                  ml="2px"
                  mb="2px"
                  color="darkGray"
                  mr="15px"
                >
                  India
                </Checkbox>

                <Checkbox
                  colorScheme="yellow"
                  defaultIsChecked
                  value="Indonesia"
                  fontWeight="700"
                  ml="2px"
                  mb="2px"
                  color="darkGray"
                  mr="15px"
                >
                  Indonesia
                </Checkbox>

                <Checkbox
                  colorScheme="yellow"
                  value="Singapore"
                  fontWeight="700"
                  ml="2px"
                  mb="2px"
                  mr="15px"
                  color="darkGray"
                >
                  Singapore
                </Checkbox>

                <Checkbox
                  colorScheme="yellow"
                  value="Vietnam"
                  fontWeight="700"
                  ml="2px"
                  mb="2px"
                  color="darkGray"
                  mr="15px"
                >
                  Vietnam
                </Checkbox>

                <Text
                  color="lightGray"
                  fontWeight="700"
                  textTransform="uppercase"
                  fontSize="16px"
                  mt="20px"
                >
                  INDUSTRY
                </Text>

                <Checkbox
                  colorScheme="yellow"
                  defaultIsChecked
                  value="India"
                  fontWeight="700"
                  br="10px"
                  ml="2px"
                  mb="2px"
                  color="darkGray"
                  mr="15px"
                >
                  Fintech
                </Checkbox>

                <Checkbox
                  colorScheme="yellow"
                  defaultIsChecked
                  value="Indonesia"
                  fontWeight="700"
                  ml="2px"
                  mb="2px"
                  color="darkGray"
                  mr="15px"
                >
                  Ecommerce
                </Checkbox>

                <Checkbox
                  colorScheme="yellow"
                  value="Singapore"
                  fontWeight="700"
                  ml="2px"
                  mb="2px"
                  color="darkGray"
                  mr="15px"
                >
                  HealthTech
                </Checkbox>

                <Checkbox
                  colorScheme="yellow"
                  value="Vietnam"
                  fontWeight="700"
                  ml="2px"
                  mb="2px"
                  color="darkGray"
                  mr="15px"
                >
                  EdTech
                </Checkbox>

                <Checkbox
                  colorScheme="yellow"
                  value="Vietnam"
                  fontWeight="700"
                  ml="2px"
                  mb="2px"
                  color="darkGray"
                  mr="15px"
                >
                  Gaming
                </Checkbox>

                <Checkbox
                  colorScheme="yellow"
                  value="Vietnam"
                  fontWeight="700"
                  ml="2px"
                  mb="2px"
                  color="darkGray"
                  mr="15px"
                >
                  Hospitality, Travel
                </Checkbox>

                <Checkbox
                  colorScheme="yellow"
                  value="Vietnam"
                  fontWeight="700"
                  ml="2px"
                  mb="2px"
                  color="darkGray"
                  mr="15px"
                >
                  Media
                </Checkbox>

                <Checkbox
                  colorScheme="yellow"
                  value="Vietnam"
                  fontWeight="700"
                  ml="2px"
                  mb="2px"
                  color="darkGray"
                  mr="15px"
                >
                  Logistics
                </Checkbox>
              </ModalBody>

              <ModalFooter>
                <Button color="#EBB435" bg="#FFF2D2" mr={3} onClick={onClose}>
                  Apply
                </Button>
              </ModalFooter>
            </ModalContent>
          </Modal>

          <Flex
            width="100%"
            height="fit-content"
            ml={["0px", "0px", "20px", "20px", "20px"]}
            flexDir="row"
            flexWrap="wrap"
          >
            <FounderCard
              imgSrc="https://avatars.githubusercontent.com/u/45586386?v=4"
              name="Parth Sharma"
              designation="Orange Yak LLP, Co-founder"
              brandName="Orange Yak"
              aboutMe="Co-founder at Orange Yak, sophomore at BITS Pilani. 6 years of experience as a freelance UI designer and frontend developer."
              location="New Delhi, Delhi, India"
              aboutCompany="A one-stop solution for any organisation’s online needs."
              industry="Information Technology, Big Data"
            />

            <FounderCard
              imgSrc="https://avatars.githubusercontent.com/u/45586386?v=4"
              name="Parth Sharma"
              designation="Orange Yak LLP, Co-founder"
              brandName="Orange Yak"
              aboutMe="Co-founder at Orange Yak, sophomore at BITS Pilani. 6 years of experience as a freelance UI designer and frontend developer."
              location="New Delhi, Delhi, India"
              aboutCompany="A one-stop solution for any organisation’s online needs."
              industry="Information Technology, Big Data"
            />

            <FounderCard
              imgSrc="https://avatars.githubusercontent.com/u/45586386?v=4"
              name="Parth Sharma"
              designation="Orange Yak LLP, Co-founder"
              brandName="Orange Yak"
              aboutMe="Co-founder at Orange Yak, sophomore at BITS Pilani. 6 years of experience as a freelance UI designer and frontend developer."
              location="New Delhi, Delhi, India"
              aboutCompany="A one-stop solution for any organisation’s online needs."
              industry="Information Technology, Big Data"
            />

            <FounderCard
              imgSrc="https://avatars.githubusercontent.com/u/45586386?v=4"
              name="Parth Sharma"
              designation="Orange Yak LLP, Co-founder"
              brandName="Orange Yak"
              aboutMe="Co-founder at Orange Yak, sophomore at BITS Pilani. 6 years of experience as a freelance UI designer and frontend developer."
              location="New Delhi, Delhi, India"
              aboutCompany="A one-stop solution for any organisation’s online needs."
              industry="Information Technology, Big Data"
            />
            <FounderCard
              imgSrc="https://avatars.githubusercontent.com/u/45586386?v=4"
              name="Parth Sharma"
              designation="Orange Yak LLP, Co-founder"
              brandName="Orange Yak"
              aboutMe="Co-founder at Orange Yak, sophomore at BITS Pilani. 6 years of experience as a freelance UI designer and frontend developer."
              location="New Delhi, Delhi, India"
              aboutCompany="A one-stop solution for any organisation’s online needs."
              industry="Information Technology, Big Data"
            />

            <FounderCard
              imgSrc="https://avatars.githubusercontent.com/u/45586386?v=4"
              name="Parth Sharma"
              designation="Orange Yak LLP, Co-founder"
              brandName="Orange Yak"
              aboutMe="Co-founder at Orange Yak, sophomore at BITS Pilani. 6 years of experience as a freelance UI designer and frontend developer."
              location="New Delhi, Delhi, India"
              aboutCompany="A one-stop solution for any organisation’s online needs."
              industry="Information Technology, Big Data"
            />

            <FounderCard
              imgSrc="https://avatars.githubusercontent.com/u/45586386?v=4"
              name="Parth Sharma"
              designation="Orange Yak LLP, Co-founder"
              brandName="Orange Yak"
              aboutMe="Co-founder at Orange Yak, sophomore at BITS Pilani. 6 years of experience as a freelance UI designer and frontend developer."
              location="New Delhi, Delhi, India"
              aboutCompany="A one-stop solution for any organisation’s online needs."
              industry="Information Technology, Big Data"
            />

            <FounderCard
              imgSrc="https://avatars.githubusercontent.com/u/45586386?v=4"
              name="Parth Sharma"
              designation="Orange Yak LLP, Co-founder"
              brandName="Orange Yak"
              aboutMe="Co-founder at Orange Yak, sophomore at BITS Pilani. 6 years of experience as a freelance UI designer and frontend developer."
              location="New Delhi, Delhi, India"
              aboutCompany="A one-stop solution for any organisation’s online needs."
              industry="Information Technology, Big Data"
            />
            <FounderCard
              imgSrc="https://avatars.githubusercontent.com/u/45586386?v=4"
              name="Parth Sharma"
              designation="Orange Yak LLP, Co-founder"
              brandName="Orange Yak"
              aboutMe="Co-founder at Orange Yak, sophomore at BITS Pilani. 6 years of experience as a freelance UI designer and frontend developer."
              location="New Delhi, Delhi, India"
              aboutCompany="A one-stop solution for any organisation’s online needs."
              industry="Information Technology, Big Data"
            />

            <FounderCard
              imgSrc="https://avatars.githubusercontent.com/u/45586386?v=4"
              name="Parth Sharma"
              designation="Orange Yak LLP, Co-founder"
              brandName="Orange Yak"
              aboutMe="Co-founder at Orange Yak, sophomore at BITS Pilani. 6 years of experience as a freelance UI designer and frontend developer."
              location="New Delhi, Delhi, India"
              aboutCompany="A one-stop solution for any organisation’s online needs."
              industry="Information Technology, Big Data"
            />

            <FounderCard
              imgSrc="https://avatars.githubusercontent.com/u/45586386?v=4"
              name="Parth Sharma"
              designation="Orange Yak LLP, Co-founder"
              brandName="Orange Yak"
              aboutMe="Co-founder at Orange Yak, sophomore at BITS Pilani. 6 years of experience as a freelance UI designer and frontend developer."
              location="New Delhi, Delhi, India"
              aboutCompany="A one-stop solution for any organisation’s online needs."
              industry="Information Technology, Big Data"
            />

            <FounderCard
              imgSrc="https://avatars.githubusercontent.com/u/45586386?v=4"
              name="Parth Sharma"
              designation="Orange Yak LLP, Co-founder"
              brandName="Orange Yak"
              aboutMe="Co-founder at Orange Yak, sophomore at BITS Pilani. 6 years of experience as a freelance UI designer and frontend developer."
              location="New Delhi, Delhi, India"
              aboutCompany="A one-stop solution for any organisation’s online needs."
              industry="Information Technology, Big Data"
            />
          </Flex>
        </Flex>
      </Flex>

      {/* END OF SCROLLABLE INTERNAL SECTION */}
    </Box>
  );
};

export default SubjectExperts;
