import {
  Flex,
  Button,
  Image,
  Text,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
} from "@chakra-ui/react";

const PartnerCard = (props) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <Flex
      bg="white"
      width={["100%", "100%", "100%", "49%", "49%"]}
      p="10px"
      pr="20px"
      borderRadius="20px"
      flexDir="column"
      mt="20px"
    >
      <Flex flexDir="row" width="100%" margin="10px" alignItems="center">
        <Image src={props.logo} objectFit="contain" boxSize="80px" />
        <Flex flexDir="column" ml="20px">
          <Text
            fontSize="24px"
            fontWeight="700"
            color="darkGray"
            lineHeight="1.2"
            mt="5px"
          >
            {props.name}
          </Text>
        </Flex>
      </Flex>

      <Flex flexDir="column" width="100%" margin="10px" mt="-10px">
        <Text fontSize="16px" color="darkGray" lineHeight="1.4" mt="5px">
          {props.desc}
        </Text>
        <Flex flexDir="row" mt="15px">
          {props.applyLink ? (
            <a href={props.applyLink} target="_blank" rel="noreferrer">
              <Button
                mr="15px"
                size="sm"
                bg="lightYellow"
                color="deepYellow"
                as="a"
                target="_blank"
              >
                LEARN MORE
              </Button>
            </a>
          ) : (
            <Button
              mr="15px"
              size="sm"
              bg="lightYellow"
              color="deepYellow"
              as="a"
              target="_blank"
              onClick={onOpen}
            >
              LEARN MORE
            </Button>
          )}
        </Flex>
      </Flex>

      <Modal
        onClose={onClose}
        size="lg"
        isOpen={isOpen}
        scrollBehavior="inside"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{props.name}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <div dangerouslySetInnerHTML={{ __html: props.content }} />
          </ModalBody>
          <ModalFooter>
            <Button onClick={onClose}>Close</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Flex>
  );
};

export default PartnerCard;
