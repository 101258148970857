import { Flex, Image, Text, Box, Button } from "@chakra-ui/react";
import moment from "moment";

import Tag from "./Tag";

import { shareOnWhatsapp } from "../store/functions";
import { BsWhatsapp } from "react-icons/bs";

const NewsCard = (props) => {
  return (
    <Flex
      width={["100%", "100%", "100%", "49%", "49%"]}
      flexDir="column"
      ml="-10px"
      mb="20px"
      mr="10px"
      bg="white"
      pb="20px"
      pl="10px"
      pr="10px"
      pt="10px"
      borderRadius="20px"
    >
      <Flex
        flexDir="column"
        m="0"
        p="0"
        as="a"
        href={props.link}
        target="_blank"
      >
        <Image
          src={props.imgSrc}
          width="94%"
          height="auto"
          mt="10px"
          borderRadius="5px"
          alignSelf="center"
        />
        <Box ml="12px" mt="10px">
          <Tag text={props.tagText} />
        </Box>
        <Text
          ml="15px"
          mt="5px"
          color="#EBB435"
          lineHeight="1.2"
          fontSize="18px"
          fontWeight="700"
        >
          {props.title}
        </Text>
        <Text
          ml="15px"
          mt="5px"
          color="#718096"
          fontSize="16px"
          lineHeight="1.2"
          fontWeight="700"
        >
          {props.agency} | {moment(props.date).format("MMMM Do 'YY")}
        </Text>
        <Text
          ml="15px"
          mt="5px"
          lineHeight="1.2"
          color="#718096"
          fontSize="16px"
        >
          {props.desc}
        </Text>
      </Flex>
      <Button
        leftIcon={<BsWhatsapp />}
        pl="20px"
        mt="10px"
        pr="20px"
        ml="10px"
        height="30px"
        width="fit-content"
        color="#EBB435"
        background="#FFF2D2"
        textTransform="uppercase"
        as="a"
        href={shareOnWhatsapp(
          "Check out this news update from the BEENEXT Founder Hub — *" +
            props.title +
            "*! " +
            props.link
        )}
        target="_blank"
        rel="noreferrer"
      >
        SHARE
      </Button>
    </Flex>
  );
};

export default NewsCard;
